import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import ReactTable from 'react-table';
import styled from 'styled-components';
import { Post, uriName } from '../../../../utilities/apiUtitlity';
import { Button } from './Button';
import { Checkbox, CrossIcon, displayShipment, Input, Select, WSNCard } from './common';
import BarcodeIcon from '../../../Workflow/WorkflowStep/PrimaryInput/icon-barcode.svg';
import Tick from './download.png';
import closeIcon from '../../../../icons/icon-close.svg';
import PrimaryInput from '../../../Workflow/WorkflowStep/PrimaryInput/PrimaryInput';

export const ScanReturnPartsModal = ({ technames, data, filtername, open, setClose, updateData }) => {

    const [filter, setFilter] = useState(filtername)
    const [filteredData, setFilteredData] = useState([])
    const [selectedData, setselectedData] = useState([])
    const [value, setValue] = useState(0);
    const [error, setError] = useState(false);
    const [errorCount, seterrorCount] = useState();
    const [isopen, setisOpen] = useState(false);
    const [submitError, setsubmitError] = useState(false);
    const [successOpen, setSuccessOpen] = useState(false);
    const [successError, setsuccessError] = useState(false);
    const Options = [{
        key: 'AT_CUST', value: 'Left at customer house'
    },
    {
        key: 'MRK_INS', value: 'Marked as installed'
    },
    {
        key: 'WITH_TECH', value: 'In tech Inventory'
    },
    {
        key: 'ABC_NOT_FOUND', value: 'ABC not found'
    }];

    useEffect(() => {
        getfilteredData(filtername)
        setFilter(filtername)
    }, [filtername, open])

    const submitData = async () => {
        if (selectedData.length < filteredData.length) {
            seterrorCount(filteredData.length - selectedData.length)
            setsubmitError(true)
        } else {
            const data = await Post(uriName, '/v1/expectedpartreturns/returnstaging',
                {
                    "program": "MA",
                    returnedParts: selectedData
                }
            ).then(rs => {
                setSuccessOpen(true);
                setsuccessError(false);
                updateData(selectedData);
                setselectedData([]);
            }).catch(error => {
                setsuccessError(true);
            })
        }
    }

    const column = [
        // {
        //     Header: '',
        //     accessor: 'test',
        //     width: 200,
        //     style: {
        //         textAlign: 'center'
        //     },
        //     Cell: props => <>
        //         <Checkbox type='checkbox' onClick={() => { return false }} checked={selectedData?.find(a => a.WSN === props?.original?.WSN) || false} />
        //     </>
        // },
        {
            Header: 'WSN',
            accessor: 'WSN',
            width: 150,
            Cell: props => props.value !== null ? <WSNCard> {displayShipment(props.value)}</WSNCard> : "",
            // Filter: cellInfo => (
            //     <><Input onChange={(e) => cellInfo?.onChange(e.target.value)} placeholder={"All"} /></>
            // )
        },
        {
            Header: 'ABC',
            accessor: 'ABC',
            minWidth: 200,
            Cell: props => <>{props?.value}{" "}{selectedData?.find(a => a.serial === props?.original?.ABC && a.scanReasonCode === 'RTN_FSL') ? <img src={Tick} height='20px' width={"20px"} /> : null}</>
            // Filter: cellInfo => (
            //     <><Input onChange={(e) => cellInfo?.onChange(e.target.value)} placeholder={"All"} /></>
            // )
        },
        {
            Header: 'Reason Code',
            accessor: '',
            minWidth: 350,
            Cell: props => <>
                <Select onChange={(e) => {
                    setsubmitError(false)
                    let temp;
                    let index = selectedData.findIndex(a => a.serial === props?.original?.ABC)
                    if (index > -1) {
                        let tempArray = Object.assign(selectedData)
                        tempArray[index].scanReasonCode = e.target.value
                        tempArray[index].scanReasonDescription = Options.find(a => a.key === e.target.value).value
                        setselectedData(tempArray)
                        setValue(value => value + 1);
                    }
                    else {
                        temp = data.find(a => a.ABC === props?.original?.ABC)
                        setselectedData(selectedData.concat([{ serial: temp.ABC, scanStatus: "NOT_RETURNED", scanReasonCode: e.target.value, scanReasonDescription: Options.find(a => a.key === e.target.value).value }]))
                    }
                }} disabled={selectedData?.findIndex(a => a.serial === props?.original?.ABC && a.scanReasonCode === 'RTN_FSL') > -1} value={selectedData?.findIndex(a => a.serial === props?.original?.ABC && a.scanReasonCode === 'RTN_FSL') > -1 ? 'RTN_FSL' : (selectedData?.find(a => a.serial === props?.original?.ABC)?.scanReasonCode || "")}>
                    <option key={""} value={""} disabled>Reason</option>
                    {Options.map(a =>
                        <option key={a.key} value={a.key}>{a.value}</option>
                    )}
                    <option value={"RTN_FSL"} disabled>Return scanned at FSL</option>
                </Select>
            </>
        },
    ]


    const getfilteredData = (filterData) => {
        setFilteredData(data?.filter(a => a.AGENT_NAME.indexOf(filterData) > -1).filter(a => a.PART_STATUS !== "INS" && (a.ABC !== null)));
    }

    const closeConfirm = () => {
        if (selectedData.length > 0) {
            setisOpen(true);
        } else {
            setClose();
        }
    }

    const onChangeSelectTechnician = (e) => {
      setFilter(e.target.value); 
      getfilteredData(e.target.value); 
      setselectedData([]);
      setsubmitError(false);
    }

    return <>
        <ConfirmModal isOpen={isopen} setClose={() => setisOpen(false)} callback={() => { setClose(); setisOpen(false) }} />
        <SuccessModel isOpen={successOpen} setClose={() => { setSuccessOpen(false); setClose(); }} />
        <Modal
            isOpen={open}
            onRequestClose={() => closeConfirm()}
            onAfterClose={() => { setselectedData([]); setsubmitError(false) }}
            style={
                {
                    content: {
                        textAlign: 'center',
                        width: 'max-content',
                        height: 'max-content',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)'
                    }
                }
            }
        >
        <CloseIcon
          onClick={() => closeConfirm()}
          src={closeIcon}
          alt="Close icon"
        />
        <Header>
            Expected Returns
        </Header>
        <div style={{ marginBottom: '20px' }}>
        <SubText>Please Select Technician Name and Scan ABCs</SubText>
            <Select onChange={(e) => onChangeSelectTechnician(e) } value={filter}>
                <option key={""} value={""}>Select Technician</option>
                {technames?.map(i =>
                    <option key={i} value={i}>{i}</option>)}
            </Select>
        </div>
            <ScanWrapper>
                <Input placeholder='Scan ABC' autoFocus onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        setsubmitError(false)
                        if (data.findIndex(a => a.ABC === e.target.value) < 0) {
                            setError(true);
                        } else {
                            setError(false);
                        }
                        const temp = data.find(a => a.ABC === e.target.value)
                        setselectedData(selectedData.concat([{ serial: temp.ABC, scanStatus: "RETURNED", scanReasonCode: "RTN_FSL", scanReasonDescription: "Return scanned at FSL" }]));
                        e.target.value = '';
                    }
                }} />

                {error === true && <ErrorText>Please scan part for selected Technician</ErrorText>}
            </ScanWrapper>
            <ReactTable data={filter === "" ? [] : filteredData} columns={column} defaultPageSize={5} sortable={false} noDataText="" showPaginationBottom={filteredData && filteredData.length > 5 ? true : false} showPageSizeOptions={false} style={{ width: 'max-content', margin: 'auto' }} headerStyle={{ margin: 'auto' }} />
            <div>
                <Button title={"Submit Returns"} onClick={() => { submitData() }} style={{ margin: '40px' }} />
            </div>
            {submitError === true && <ErrorText>You have not scanned {errorCount} parts. Either scan them or update the reason for not returning.</ErrorText>}
            {successError === true && <ErrorText>Something went wrong please check the data and try again</ErrorText>}
        </Modal>
    </>
}


const ConfirmModal = ({ isOpen, setClose, callback }) => {
    return <Modal isOpen={isOpen} style={{
        overlay: { zIndex: 1 },
        content: {
            background: '#FFFFFF',
            borderRadius: '2px',
            width: '650px',
            height: 'max-content',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    }}>
        <ConfirmTitle>Are you sure you want to close without submitting scanned parts?</ConfirmTitle>
        <ButtonsGrid className="confirmModalButtons">
            <div style={{ margin: 'auto' }}>
                <Button style={{ margin: '0 14px' }} onClick={() => setClose()}><span style={{ margin: '0 14px' }} > No, go back </span></Button>
                <Button style={{ margin: '0 14px' }} onClick={() => callback()}><span style={{ margin: '0 14px' }}> Yes</span></Button>
            </div>
        </ButtonsGrid>
    </Modal>
}

const SuccessModel = ({ isOpen, setClose, callback }) => {
    return <Modal isOpen={isOpen} style={{
        overlay: { zIndex: 1 },
        content: {
            background: '#FFFFFF',
            borderRadius: '2px',
            width: '650px',
            height: 'max-content',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
    }}>
        <ConfirmTitle>Scanned parts successfully updated</ConfirmTitle>
        <ButtonsGrid className="confirmModalButtons">
            <div style={{ margin: 'auto' }}>
                <Button style={{ margin: '0 14px' }} onClick={() => setClose()}><span style={{ margin: '0 14px' }} > Okay </span></Button>
            </div>
        </ButtonsGrid>
    </Modal>
}

const CloseIcon = styled.img`
float: right;
cursor: pointer;
`;

const SubText = styled.div`
  &:before {
    content: "* ";
  }
  &:after {
    content: " *";
  }
  margin: 0px 0px 10px;
  font-size: 14px;
  font-weight: 700;
`;

const ScanWrapper = styled.div`
    width: 100%;
    margin: 15px auto 20px;
`;


const Header = styled.div`
    font-size: 36px;
    text-align: center;
    margin: 0px 0 20px;
`;

const Title = styled.span`
    font-size: 16px;
    margin: 0 20px;
`;

const ErrorText = styled.div`
    color: #ff5252;
    font-weight: bold;
    font-size: 14px;
`;


export const ConfirmTitle = styled.div`
    font-weight: 400;
    font-size: 32px;
    line-height: 150%;
    /* or 48px */
    text-align: center;
    margin: auto;
    width: 439px;
    color: #000000;
`;


const ButtonsGrid = styled.div`
    display: flex;
`;