import {
  CLEAR_PROCESSING_KIT,
  COLLATERAL_STEP_COMPLETE,
  PRINT_STEP_COMPLETE,
  PRINT_WSN_STEP_COMPLETE,
  RESTAGING_SCAN_WSN_STEP_COMPLETE,
  RESCHEDULE_SCAN_WSN_STEP_COMPLETE,
  RESTAGING_AFFIX_AND_SCAN_WSN_STEP_COMPLETE,
  RESTAGING_CLOSE_BOX_STEP_COMPLETE,
  RESUME_KIT_SUCCESSFUL,
  RESUME_ORDER,
  SAVE_ESN_NUMBER,
  SAVE_FIRSTNET_SIM_NUMBER,
  SAVE_RMA_NUMBER,
  SAVE_SKIP_STEP,
  SAVE_TRACKING_NUMBER,
  SAVE_FLIER_GUIDE,
  SCAN_AFFIX_COMPLETE,
  START_NEXT_ORDER,
  UPDATE_RESCHEDULE_ROW_DATA,
  UPDATE_RESHIP_STATUS,
  ORDER_CANCELED_FLAG,
  RETURN_ABC_COMPLETE,
  SET_MULTI_WORKFLOW_LIST,
  SET_MULTI_WORKFLOW_OBJ
} from '../actions/orderActions';

const initialState = {
  collateralStepComplete: false,
  esnNumber: '',
  firstNetSimNumber: '',
  flierGuide: '',
  partStatus: '',
  returnABCStatus: '',
  printStepComplete: false,
  printWsnStepComplete: false,
  restagingScanWsnStepComplete: false,
  rescheduleScanWsnStepComplete: false,
  restagingAffixAndScanStepComplete: false,
  restagingCloseBoxComplete: false,
  returnAbcComplete: false,
  processingServiceKit: {},
  processingStarted: false,
  reshipStepComplete: false,
  resumeOrderData: {},
  rmaNumber: '',
  scanAndAffixComplete: false,
  stepSkipped: '',
  uspsTrackingNumber: '',
  order_canceled_flag: false,
  serviceKitMultiWorkflow: {},
  selectedMultiWorkflow: [],
  rescheduleRowData:null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_PROCESSING_KIT:
      return { ...initialState };
    case COLLATERAL_STEP_COMPLETE:
      return { ...state, collateralStepComplete: true };
    case PRINT_STEP_COMPLETE:
      return { ...state, printStepComplete: true };
    case PRINT_WSN_STEP_COMPLETE:
      return { ...state, printWsnStepComplete: true };
    case RESTAGING_SCAN_WSN_STEP_COMPLETE:
      return { ...state, restagingScanWsnStepComplete: true };
    case RESCHEDULE_SCAN_WSN_STEP_COMPLETE:
      return { ...state, rescheduleScanWsnStepComplete: true };
    case RESTAGING_AFFIX_AND_SCAN_WSN_STEP_COMPLETE:
      return { ...state, restagingAffixAndScanStepComplete: true };
    case RESTAGING_CLOSE_BOX_STEP_COMPLETE:
      return { ...state, restagingCloseBoxComplete: true };
    case RESUME_KIT_SUCCESSFUL:
      return { ...state, partStatus: action.partStatus };
    case RETURN_ABC_COMPLETE:
      return { ...state, returnABCStatus: action.returnABCStatus };
    case RESUME_ORDER:
      return { ...state, resumeOrderData: action.rowInfo };
    case SAVE_ESN_NUMBER:
      return { ...state, esnNumber: action.payload };
    case SAVE_FIRSTNET_SIM_NUMBER:
      return { ...state, firstNetSimNumber: action.payload };
    case SAVE_RMA_NUMBER:
      return { ...state, rmaNumber: action.payload };
    case SAVE_SKIP_STEP:
      return { ...state, stepSkipped: action.payload };
    case SAVE_TRACKING_NUMBER:
      return { ...state, uspsTrackingNumber: action.payload };
    case SAVE_FLIER_GUIDE:
      return { ...state, flierGuide: action.payload };
    case SCAN_AFFIX_COMPLETE:
      return { ...state, scanAndAffixComplete: true };
    case START_NEXT_ORDER:
      return {
        ...state,
        processingServiceKit: action.payload,
        esnNumber: '',
        processingStarted: true,
        rmaNumber: '',
        uspsTrackingNumber: '',
        order_canceled_flag: false
      };
    case UPDATE_RESCHEDULE_ROW_DATA:
      return{
        ...state,
        rescheduleRowData: action.payload,
      }  
    case UPDATE_RESHIP_STATUS:
      return { ...state, reshipStepComplete: true };
    case ORDER_CANCELED_FLAG:
      return { ...state, order_canceled_flag: true };
    case SET_MULTI_WORKFLOW_LIST:
      return { ...state, selectedMultiWorkflow: action.workflowList }
    case SET_MULTI_WORKFLOW_OBJ:
      return { ...state, serviceKitMultiWorkflow: action.workflowObj }
    default:
      return state;
  }
};
