import React, { PureComponent } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import './Button.scss';

class Button extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = e => {
    e.preventDefault();
    this.props.onClick();
  };

  render() {
    const { text, type, className, count, src, disableFlag } = this.props;
    const classes = classNames(
      { 'dash-alert-button': type === 'dash-alert' },
      { 'icon-btn': type === 'img-btn' },
      { 'primary-btn--ghost': type === 'ghost' },
      { 'primary-btn--hyperlink': type === 'link' },
      { 'primary-btn': type !== 'dash-alert' && type !== 'link' && type !== 'img-btn' },
      { 'primary-compact-btn': type === 'compact' },
      className
    );

    const imgContent = (
      <>
        <img src={src} alt="text" />
        {count > 0 && <div className="icon-btn-badge">{count}</div>}
      </>
    );

    return (
      <button onClick={this.handleClick} className={classes} disabled={disableFlag}>
        {type !== 'img-btn' ? text : imgContent}
      </button>
    );
  }
}

Button.defaultProps = {
  text: '',
  type: '',
  disableFlag: false
};

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string,
  disableFlag: PropTypes.bool
};

export default Button;
