import React, { memo } from 'react';
import { generateCorrelationID, isEmpty } from '../../../utilities/genericUtility';
import { FlowTypes, WorkflowNames } from '../../../utilities/enums';
import WsnScanConfirmation from '../Steps/WsnScanConfirmation/WsnScanConfirmation';
import RestagingConfirmation from '../Steps/Restaging/restagingConfirmation';
import ScanWsn from '../Steps/ScanWsn/ScanWsn';
import ReturnABC from '../Steps/ABC/ReturnABC';
import ReturnOrRestockABC from '../Steps/ABC/ReturnOrRestockABC';
import CompleteReturnABC from '../Steps/ABC/CompleteReturnABC';
import RestagingScanWsn from '../Steps/Restaging/RestagingScanWsn';
import RestagingAffixAndScan from '../Steps/Restaging/RestagingAffixAndScan';
import ScanParts from '../Steps/Restaging/ScanParts';
import CloseBox from '../Steps/Restaging/CloseBox';
import ContactFSL from '../Steps/ShipmentNotFound/ContactFSL';
import PlaceShipment from '../Steps/ShipmentNotFound/PlaceShipment';
import Confirmation from '../Steps/ShipmentNotFound/Confirmation';
import Reschedule from '../Steps/Reschedule/RescheduleComplete';
import { ConnectedReturnToParts } from '../Steps/ReturnToParts';
import { AffixShippingLabel } from '../Steps/AffixShippingLabel';
import { ConnectedPlaceboxAndReturn } from '../Steps/PlaceboxAndReturn';
import { ReturnScrap } from '../Steps/ReturnScrap';
import RescheduleComplete from '../Steps/Reschedule/RescheduleComplete';
import RescheduleScanWsn from '../Steps/Reschedule/RescheduleScanWsn';
import DamagedExtReturnABC from '../Steps/ABC/DamagedExtReturnABC';

const StepRouter = props => {
  const mainWorkFlow = props => {
    const flowValue = props?.bucketType === 'OrdersToPack' ? FlowTypes.ORDER_TO_KIT : FlowTypes.UNFINISHED;
    generateCorrelationID(props?.kitInfo?.kitReferenceNumber, flowValue);
    const localWorkflowOrder =
      props.workflowOrder && props.workflowOrder.length >= 1
        ? props.workflowOrder
        : ['no-workflow'];
    const workflowStep = !isEmpty(localWorkflowOrder[props.step - 1])
      ? localWorkflowOrder[props.step - 1]
      : WorkflowNames.done;
    switch (workflowStep) {
      case WorkflowNames.scanwsn:
        return <ScanWsn {...props} />;
      case WorkflowNames.done:
        return <WsnScanConfirmation {...props} />;
      default:
        return <></>;
    }
  };

  const returnWorkFlow = props => {
    switch (props.step) {
      case 1:
        // return <ReturnSKU {...props} />;
        return <ReturnABC {...props} />;
      case 2:
        // return <CompleteReturn {...props} />;
        return <ReturnOrRestockABC {...props} />;
      case 3:
        // complete return
        return <CompleteReturnABC {...props} />;
      default:
        return <></>;
    }
  };

  const restagingWorkflow = props => {
    const workflowStep = !isEmpty(props.workflowOrder[props.step - 1]) ? props.workflowOrder[props.step - 1] : WorkflowNames.done;
    switch (workflowStep) {
      case WorkflowNames.prepareshipmentscanwsn:
        return <RestagingScanWsn {...props} />;
      case WorkflowNames.scanparts:
        return <ScanParts {...props} />;
      case WorkflowNames.closebox:
        return <CloseBox {...props} />;
      case WorkflowNames.prepareshipmentaffixandscanwsn:
        return <RestagingAffixAndScan {...props} />;
      case WorkflowNames.done:
        // complete restage
        return <RestagingConfirmation {...props} />;
      default:
        return <></>;
    }
  }

  const unableToLocateShipment = props => {

    switch (props.unableToLocateStep) {
      case 1:
        return <ContactFSL {...props} />;
      case 2:
        return <PlaceShipment {...props} />;
      case 3:
        return <Confirmation {...props} />;
      default:
        return <></>;
    }
  };

  const rmaWorkflow = props => {
    const workflowStep =
      !isEmpty(props.workflowOrder[props.step - 1]) ?
        props.workflowOrder[props.step - 1] :
        WorkflowNames.done;

    switch (workflowStep) {
      // PDRETURN
      case WorkflowNames.scanningreturns:
        return <ReturnABC {...props} />;
      case WorkflowNames.returntopdprint:
        return <><ConnectedReturnToParts {...props} /></>;
      case WorkflowNames.returntopdaffix:
        return <><AffixShippingLabel {...props} /></>;
      case WorkflowNames.returntopdshelf:
        return <><ConnectedPlaceboxAndReturn {...props} /></>;
      // RESTAGE
      // RETURN
      case WorkflowNames.returnorrestock:
        return <ReturnOrRestockABC {...props} />;
      // SCRAP
      case WorkflowNames.returnscrap:
        return <ReturnScrap {...props} />;
      //PDRETURN_EXT  
      case WorkflowNames.returntopdext:
        return <DamagedExtReturnABC {...props} />; 
      // DONE (common for all above workflows)
      case WorkflowNames.done:
        // complete return
        return <CompleteReturnABC {...props} />;
      default:
        return <></>;
    }
  }

const rescheduleWorkflow = props => {
  const workflowStep =
    !isEmpty(props.workflowOrder[props.step - 1]) ?
      props.workflowOrder[props.step - 1] :
      WorkflowNames.done;

  switch (workflowStep) {
    case WorkflowNames.reschedulescan:
      return <RescheduleScanWsn {...props} />;
    case WorkflowNames.done:
      return <RescheduleComplete {...props} />;
    default:
      return <></>;
}

};
if (props.rmaWorkflow) return rmaWorkflow(props);
if (props.isRescheduleWorkflow) return rescheduleWorkflow(props);  
if (props.isUnableToLocateShipment) return unableToLocateShipment(props);
  else if(!props.returnWorkflow && !props.restagingWorkflow) return mainWorkFlow(props);
  else if (props.restagingWorkflow) return restagingWorkflow(props);
  else return returnWorkFlow(props);
};

export default memo(StepRouter);
