import React from 'react'
import WorkflowStep from '../../WorkflowStep/WorkflowStep'
import styled from 'styled-components';
import { BodyText, List, BottomBox, AfterCardText } from '../commonStyledComponent';
import Image from './TrashIcon.png'


export const ReturnScrap = ({ nextStep, step, goToStep }) => {
    const goToNextStep = () => {
        nextStep(step)
    }
    return <WorkflowStep
        title="Scrap part & complete return"
        step={step}
        footer={
            <BottomBox onClick={()=>goToStep(1)}>
                <span>
                    SCAN ANOTHER PART
                </span>
            </BottomBox>
        }
        after={
            <AfterCardText onClick={() => goToNextStep()}>
                DONE SCANNING
            </AfterCardText>
        }
    >
        <div>
            <List>
                <BodyText>Part is damaged or defective</BodyText>
                <BodyText>Discard the part</BodyText>
            </List>
        </div>
        <RightSideImage src={Image} alt="frame" />
    </WorkflowStep>
}


const RightSideImage = styled.img`
    margin-left: 325px;
    margin-top: 75px;
    width: 225px;
    height: 175px;
`;