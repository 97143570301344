import { authUriName, Get, userInfo } from './apiUtitlity';
import { hasError } from '../actions/errorActions';
import { isEmpty, parseJwt } from './genericUtility';

export const updateSessionId = () => (userInfo.sessionId = sessionStorage.getItem('sessionId'));

let refreshRate = 20000;

let waitTime = 5 * 30 * 1000;

export const updateCurrentSession = callback => {
  if (!isEmpty(userInfo.token)) {
    const jwt = parseJwt(userInfo.token);
    setSessionLogout(jwt.exp, callback);
    const userDetails = jwt.username.split('|');
    userInfo.companyId = userDetails[0];
    userInfo.userName = userDetails[1];
  }
};

export const setSessionLogout = (ts, callback) => {
  const sessionExpiry = new Date(ts * 1000);
  const difference = sessionExpiry - Date.now();
  if (difference < 0) waitTime = 0;
  const refreshToDoAt = difference - waitTime;
  setTimeout(function () {
    refreshCurrentSession(callback);
  }, refreshToDoAt);
};

const refreshCurrentSession = async callback => {
  try {
    const res = await Get(authUriName, '/refreshtoken', {
      'refresh-token': userInfo.refreshToken,
      'x-api-key': userInfo.apiKey,
      Accept: 'application/json',
      Authorization: `Bearer ${userInfo.token}`
    });
    if (hasError(res)) {
      setTimeout(() => {
        callback();
      }, waitTime - 1000);
    } else {
      userInfo.token = res.access_token;
      userInfo.refreshToken = res.refresh_token;
      updateCurrentSession(callback);
    }
  } catch (error) {
    setTimeout(() => {
      callback();
    }, waitTime);
  }
};

export const updateRefreshRate = currentRate => {
  if (refreshRate !== currentRate) refreshRate = currentRate;
};

export const updateRefreshRateOnError = () => {
  refreshRate += 10000;
};

export const getRefreshRate = () => refreshRate;
