import React, { PureComponent } from 'react';
import Barcode from 'react-barcode';
import PropTypes from 'prop-types';
import Row from '../Common/Row';
import Col from '../Common/Column';
import { splitWsnInfo, removeLeadingZeros } from '../../utilities/genericUtility';
import './ReceivingWsnPrintLabel.scss';

class ReceivingWsnPrintLabel extends PureComponent {
  render() {
    const { serviceKitNumber } = this.props.wsnForReceivedPart;
    const { wsnMonth, wsnSerialNum, wsnSerialAlpha, wsnSjNum } = splitWsnInfo(serviceKitNumber);

    return (
      <div className={this.props.class}>
        <Row>
          <Col oneofone>
            <>
              <div>
                <Barcode
                  displayValue={false}
                  height={100}
                  width={2}
                  marginTop={-5}
                  marginBottom={-40}
                  text={serviceKitNumber}
                  value={serviceKitNumber}
                />
              </div>
              <span className="receiving-barcodeText">
                {serviceKitNumber ? serviceKitNumber : ''}
              </span>
              <span className="receiving-barcodeSerial">
                {wsnMonth}-{removeLeadingZeros(wsnSerialNum)}{wsnSerialAlpha}
              </span>
            </>
          </Col>
        </Row>
      </div>
    );
  }
}

ReceivingWsnPrintLabel.defaultProps = {
  wsnForReceivedPart: {}
};

ReceivingWsnPrintLabel.propTypes = {
  wsnForReceivedPart: PropTypes.object
};

export default ReceivingWsnPrintLabel;
