import React from 'react';
import CheckMark from '../../../../icons/checkmark.svg';
import IconClose from '../../../../icons/icon-close.svg';
import './ReceivingComplete.scss';

const ReceivingComplete = (props) => {
    return (
        <div className="receiving-complete-container">
            <div>
                <img className="receiving-checkmark" src={CheckMark} alt="Checkmark icon" />
            </div>
            <div>
                <p className="receiving-complete-text">Print complete. Do you want to receive the next shipment?</p>
                <button
                    className="receiving-complete-next-btn"
                    onClick={props.nextShipment}
                >
                    Yes, next shipment
                </button>
                <p
                    className="receiving-complete-stay-btn"
                    onClick={props.closeModal}
                >
                    No, stay on page
                </p>
            </div>
            <div>
                <img
                    className="receiving-close"
                    onClick={props.closeModal}
                    src={IconClose}
                    alt="Close icon"
                />
            </div>

        </div>
    );
};

export default ReceivingComplete;
