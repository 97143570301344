import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import './OrderId.scss';

const OrderId = ({ inverted, kitSequenceNumber, selected }) => {
  const classes = classNames('date-lbl', { 'date-lbl--inverted': inverted });
  return (
    <div className={classes}>
      <div className="order-id">
        <div className={classNames({ 'order-id__selected': selected })}>
          {kitSequenceNumber}
        </div>
      </div>
    </div>
  );
};

OrderId.defaultProps = {
  inverted: false,
  selected: false
};

OrderId.propTypes = {
  inverted: PropTypes.bool,
  kitSequenceNumber: PropTypes.string.isRequired,
  selected: PropTypes.bool
};

export default memo(OrderId);
