import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import Col from '../../../Common/Column';
import Row from '../../../Common/Row';
import {scanWSNUnableToLocate} from '../../../../actions/orderActions';
import PropTypes from 'prop-types';
import MissingShipments from '../../../../icons/missing-shipment.png';
import './PlaceShipment.scss';

const PlaceShipment = (props) => {
    const { unableToLocate,unableToLocateStep } = props;
    const buttonLabel = 'CONTINUE';
    
    const gotoConfirmation = ()=>{
      unableToLocate();
      props.scanWSNUnableToLocate();
      }
    return (
     <WorkflowStep
        {...props}
        step={unableToLocateStep}
        title="Place scanned shipments on Ready To Pickup Rack."
        footer={
          <PrimaryButton
            text={buttonLabel}
            onClick={gotoConfirmation}
          />
        }
      >
       <Row gutter>
       <Col oneofone>
                <ul>
                <li>Place scanned shipments on Ready To Pickup Rack.</li>
                <li>Move on to the next order.</li>
                <li>The unscanned shipment will be in the "Unfinished" bucket.</li>
                </ul>
            </Col>
            <Col oneoftwo>
            <img src={MissingShipments} className="missing-shipment-img" alt="" />
            </Col>
            </Row>          
      </WorkflowStep>
    )
}

PlaceShipment.propTypes={
  scanWSNUnableToLocate: PropTypes.func
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      scanWSNUnableToLocate
    },
    dispatch
  );

export default connect(null,mapDispatchToProps)(PlaceShipment);
