import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { MenuContentType } from '../../../../utilities/enums';
import broadcastIcon from '../../../../icons/broadcast-icon.svg';
import expertsIcon from '../../../../icons/experts-icon.svg';
import hamburgerIcon from '../../../../icons/hamburger-icon.svg';
import helpIcon from '../../../../icons/help-icon.svg';
import packageIcon from '../../../../icons/package-icon.svg';
import ExclamationIcon from '../../../../icons/exclamation-icon.svg';

import './Menu.scss';
import { userInfo } from '../../../../utilities/apiUtitlity';

const MenuItem = ({ name, icon, count, onClick }) => (
  <div className="menu-item" onClick={onClick}>
    <div className="menu-icon">
      <img src={icon} alt={name} />
    </div>
    <div className="menu-name">{name}</div>
    {count > 0 && <div className="menu-badge">{count}</div>}
  </div>
);

class MainMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setMenuRef = this.setMenuRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setMenuRef(node) {
    this.menuRef = node;
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      this.menuRef &&
      !this.menuRef.contains(event.target)
    ) {
      this.props.outerClick();
    }
  }

  render() {
    return (
      <>
        <img src={hamburgerIcon} alt="Menu" onClick={this.props.menuAction} ref={this.setMenuRef} />
        {this.props.show && (
          <div className="menu-main" ref={this.setWrapperRef}>
            {this.props.children}
          </div>
        )}
      </>
    );
  }
}

const Menu = ({ goBack, openMenu, show, showContent, hotNewsCount }) => (
  <main className="menu-container">
    <MainMenu outerClick={() => openMenu(false)} menuAction={() => openMenu(!show)} show={show}>
      <MenuItem icon={packageIcon} name="Order Packing" onClick={goBack} />
      <MenuItem
        icon={expertsIcon}
        name="List Of Experts"
        onClick={() => showContent(MenuContentType.ExpertList)}
      />
      <MenuItem
        icon={broadcastIcon}
        name="Hot News"
        count={hotNewsCount}
        onClick={() => showContent(MenuContentType.HotNews)}
      />
      <MenuItem
        icon={helpIcon}
        name="Need Help?"
        onClick={() => showContent(MenuContentType.NeedHelp)}
      />
      {(window.location.href.includes('qa') ||
        userInfo.defaultUserLocationId === '1176447051' ||
        process.env.NODE_ENV === 'development') && (
        <MenuItem
          icon={ExclamationIcon}
          name="Parts Dashboard"
          onClick={() => showContent(MenuContentType.SJDetails)}
        />
      )}
    </MainMenu>
  </main>
);

const mapStateToProps = ({ configuration }) => ({
  hotNewsCount: configuration.hotNewsCount,
  latestHotnews: configuration.updatedHotNews
});

export default connect(mapStateToProps, null)(Menu);
