import Config from '../config';
import { isEmpty } from './genericUtility';

const getClientId = name => {
  const retData = Config.ClientInfo.filter(
    client => client.clientName.toLowerCase() === name.toLowerCase()
  );
  return !isEmpty(retData) ? retData[0].externalClientId : null;
};

const getClientName = id => {
  const retData = Config.ClientInfo.filter(client => client.externalClientId === id);
  return !isEmpty(retData) ? retData[0].clientName : '';
};

const getClients = () => Config.ClientInfo;

export { getClientId, getClientName, getClients };
