import React, { PureComponent } from 'react';
import Barcode from 'react-barcode';
import PropTypes from 'prop-types';
import Row from '../Common/Row';
import Col from '../Common/Column';
import './AbcPrintLabel.scss';

class AbcPrintLabel extends PureComponent {
  render() {
    const abcNumber = this.props.selectedAbcNumber;

    return (
      <div className={this.props.class}>
        <Row>
            <Col oneofone>
                <div>
                    <Barcode
                        displayValue={false}
                        height={150}
                        width={2}
                        marginTop={20}
                        marginBottom={-40}
                        text={abcNumber}
                        value={abcNumber}
                    />
                </div>
                <span className="abcBarcodeText">
                    {abcNumber ? abcNumber : ''}
                </span>
            </Col>
        </Row>
      </div>
    );
  }
}

AbcPrintLabel.defaultProps = {
    selectedAbcNumber: ''
};

AbcPrintLabel.propTypes = {
    selectedAbcNumber: PropTypes.string
};

export default AbcPrintLabel;
