import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import ReactTable from "react-table"
import styled from 'styled-components'
import moment from 'moment'
import { ScanReturnPartsModal } from './ScanReturnModal'
import { Button } from './Button'
import { displayShipment, WSNCard } from './common'
import { Post, uriName, userInfo } from '../../../../utilities/apiUtitlity'



export const SJDetailsDashboard = () => {

  const [data, setData] = useState([])
  const [technames, settechnames] = useState([])
  const [jobstatus, setjobstatus] = useState([])
  const [partstatus, setpartstatus] = useState([])
  const [filtername, setFiltername] = useState("")
  const [open, setOpen] = useState(false)
  useEffect(() => {
    async function fetchData() {
      const data = await Post(uriName, '/v1/expectedpartreturns/dashboard',
        {
          "serviceProgramType": "MA",
          "locationId": userInfo.defaultUserLocationId
        }
      )
      //const data = Test;
      if (data?.length > 0) {
        setData(data)
        const array = [...new Set(data.map(item => item.AGENT_NAME))];
        settechnames(array)
        setjobstatus([...new Set(data.map(item => item.JOB_STATUS))]);
        setpartstatus([...new Set(data.map(item => item.PART_STATUS))])
      }
    }
    fetchData();
  }, [])

  const updateData = (updatedData) => {
    const newData = data.map(d => {
        let index = updatedData.find(a => a.serial === d.ABC)
        if (index) {
            d.scanStatus = index.scanStatus
        }
        return d
    })
    setData(newData)
}

  const columns = [
    {
      Header: 'Tech Name',
      accessor: 'AGENT_NAME',
      width: 250,
      Filter: cellInfo => (
        <><Select onChange={(e) => { cellInfo?.onChange(e.target.value); setFiltername(e.target.value) }} value={cellInfo?.filter?.value}>
          <option key={""} value={""}>All</option>
          {technames?.map(i =>
            <option key={i} value={i}>{i}</option>)}
        </Select></>
      )

    },
    {
      Header: 'Job Number',
      accessor: 'JOB_NUMBER',
      width: 200,
      Filter: cellInfo => (
        <><Input onChange={(e) => cellInfo?.onChange(e.target.value)} placeholder={"All"} /></>
      )
    },
    {
      Header: 'Job Status',
      accessor: 'JOB_STATUS',
      minWidth: 100,
      Filter: cellInfo => (
        <>
          <Select onChange={(e) => cellInfo?.onChange(e.target.value)} value={cellInfo?.filter?.value}>
            <option key={""} value={""}>All</option>
            {jobstatus?.map(i =>
              <option key={i} value={i}>{i}</option>)}
          </Select></>
      )
      // Filter: cellInfo => (
      //     <><Input onChange={(e) => cellInfo?.onChange(e.target.value)} placeholder={cellInfo?.column?.Header} /></>
      // )

    },
    {
      Header: 'Res Code',
      accessor: 'RESOLUTION_CODE',
      width: 150,
      filterable: false,
      // Filter: cellInfo => (
      //     <><Input onChange={(e) => cellInfo?.onChange(e.target.value)} placeholder={cellInfo?.column?.Header} /></>
      // )
    },
    {
      Header: 'WSN',
      accessor: 'WSN',
      width: 150,
      Cell: props => props.value !== null ? <WSNCard> {displayShipment(props.value)}</WSNCard> : "",
      Filter: cellInfo => (
        <><Input onChange={(e) => cellInfo?.onChange(e.target.value)} placeholder={"All"} /></>
      )
    },
    {
      Header: 'Part Status',
      accessor: 'PART_STATUS',
      width: 100,
      Filter: cellInfo => (
        <><Select onChange={(e) => cellInfo?.onChange(e.target.value)} value={cellInfo?.filter?.value}>
          <option value={""}>All</option>
          {partstatus?.map(i =>
            <option value={i}>{i}</option>)}
        </Select></>
      )
      // Filter: cellInfo => (
      //     <><Input onChange={(e) => cellInfo?.onChange(e.target.value)} placeholder={cellInfo?.column?.Header} /></>
      // )

    },
    {
      Header: 'Part Number',
      accessor: 'PART_NUMBER',
      width: 200,
      Filter: cellInfo => (
        <><Input onChange={(e) => cellInfo?.onChange(e.target.value)} placeholder={"All"} /></>
      )

    },
    {
      Header: 'ABC',
      accessor: 'ABC',
      minWidth: 200,
      Filter: cellInfo => (
        <><Input onChange={(e) => cellInfo?.onChange(e.target.value)} placeholder={"All"} /></>
      )

    },
    {
      Header: 'Scheduled Date',
      accessor: 'SCHEDULED_SERVICE_DATE',
      width: 200,
      filterable: false,
      Cell: props => moment(props.value).format('DD-MMM-YYYY')
      // Filter: cellInfo => (
      //     <><Input onChange={(e) => cellInfo?.onChange(e.target.value)} placeholder={cellInfo?.column?.Header} /></>
      // )

        },
        {
            Header: 'Ship To',
            accessor: 'SHIP_TO',
            width: 150,
            filterable: false
            // Filter: cellInfo => (
            //     <><Input onChange={(e) => cellInfo?.onChange(e.target.value)} placeholder={cellInfo?.column?.Header} /></>
            // )
        },
        //{
        //    Header: 'Scan Status',
        //    accessor: 'scanStatus',
        //    width: 200,
        //    filterable: false
        //    // Filter: cellInfo => (
        //    //     <><Input onChange={(e) => cellInfo?.onChange(e.target.value)} placeholder={cellInfo?.column?.Header} /></>
        //    // )
        //},
        // {
        // {
        //     Header: 'Address',
        //     accessor: 'code'
        // },

  ]

    return <div style={{ margin: '40px' }}>
        <HeaderWrapper>
            <Header>
                <div>Parts Dashboard</div>
            </Header>
            <Button title={"SCAN PARTS TO RETURN"} onClick={() => setOpen(!open)} style={{ width: 'max-content', margin: '0 20px' }} />
        </HeaderWrapper>
        <ScanReturnPartsModal technames={technames} data={data} filtername={filtername} open={open} setClose={() => setOpen(false)} updateData={updateData} />

    <ReactTable
      columns={columns}
      data={data}
      noDataText={"No data found"}
      showPagination={true}
      pageSize={10}
      filterable={true}
    />
  </div>
}


const Input = styled.input`
    border-radius: 4px; 
    border: 1px solid;
    border-left: 0;
    width: 150px;
`;

const Select = styled.select`
    border-radius: 4px; 
    border: 1px solid;
    border-left: 0;
    width: 150px;
`;

const Header = styled.div`
    font-size: 42px;
    margin-left: 22px;
`;
const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 40px 0;
`;


const Test = [
  {
    "MARKET": "BLUESTREAK-FSL-DAYTONA",
    "AGENT_NAME": "LOUIS| AHLHEIM",
    "JOB_NUMBER": "SNWQ93102203-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000152BSNWQ93102203-1",
    "ABC": "A10126234175",
    "PART_NUMBER": "BN95-06369A",
    "PART_STATUS": "INS",
    "PART_PRICE": "288.68",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "31 CLUB HOUSE DR",
    "ADDRESS_CITY": "PALM COAST",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32137"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQ93122BA1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000083BSNWQ93122BA1-1",
    "ABC": "A10208170197",
    "PART_NUMBER": "BN95-07297A",
    "PART_STATUS": "RTS",
    "PART_PRICE": "824",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DWAYNE| HILTUNEN",
    "JOB_NUMBER": "SNWQ93127155-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000742CSNWQ93127155-1",
    "ABC": "A10213784742",
    "PART_NUMBER": "BN94-15731A",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "751 HOME GROVE DR",
    "ADDRESS_CITY": "WINTER GARDEN",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34787"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9313285C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000457ASNWQ9313285C-1",
    "ABC": "A10235691875",
    "PART_NUMBER": "BN95-07732A",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "ROY| DUITMAN",
    "JOB_NUMBER": "SNWQ9314F7FD-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000302ASNWQ9314F7FD-1",
    "ABC": "A10211695275",
    "PART_NUMBER": "DC31-00111A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "ROY| DUITMAN",
    "JOB_NUMBER": "SNWQ9314F7FD-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000302BSNWQ9314F7FD-1",
    "ABC": "A10211701297",
    "PART_NUMBER": "DC92-01803R",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "130.13",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQ93155D20-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN59-01333A",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3174 LOWINGWOOD CT",
    "ADDRESS_CITY": "JENISON",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49428"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQ93155D20-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000166DSNWQ93155D20-1",
    "ABC": "A10197544131",
    "PART_NUMBER": "BN59-01333A",
    "PART_STATUS": "REC",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3174 LOWINGWOOD CT",
    "ADDRESS_CITY": "JENISON",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49428"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "KEVIN| PHILLIPS",
    "JOB_NUMBER": "SNWQ931591E5-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA91-05436A",
    "PART_STATUS": "ORD",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JOSEPH| BRADISH",
    "JOB_NUMBER": "SNWQ9315D673-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000297BSNWQ9315D673-1",
    "ABC": "A10166325219",
    "PART_NUMBER": "DC93-00819C",
    "PART_STATUS": "INS",
    "PART_PRICE": "143.28",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JOSEPH| BRADISH",
    "JOB_NUMBER": "SNWQ9315D673-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000297ASNWQ9315D673-1",
    "ABC": "A10166301520",
    "PART_NUMBER": "DC97-22462F",
    "PART_STATUS": "INS",
    "PART_PRICE": "197.3",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "BLAKE| KOSTREBA",
    "JOB_NUMBER": "SNWQ9315ED67-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA92-01199B",
    "PART_STATUS": "SHP",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQ931603ED-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000319BSNWQ931603ED-1",
    "ABC": "A10166978219",
    "PART_NUMBER": "DG94-03473A",
    "PART_STATUS": "INS",
    "PART_PRICE": "87.2",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQ931603ED-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000319ESNWQ931603ED-1",
    "ABC": "A10172435675",
    "PART_NUMBER": "DG94-04349A",
    "PART_STATUS": "INS",
    "PART_PRICE": "233.04",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DWAYNE| HILTUNEN",
    "JOB_NUMBER": "SNWQA31652F9-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000352CSNWQA31652F9-1",
    "ABC": "A10213785320",
    "PART_NUMBER": "BN94-16105Q",
    "PART_STATUS": "INS",
    "PART_PRICE": "108",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "1151 POST LAKE PL APT 28-307",
    "ADDRESS_CITY": "APOPKA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32703"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DWAYNE| HILTUNEN",
    "JOB_NUMBER": "SNWQA316DF64-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000378ASNWQA316DF64-1",
    "ABC": "A10213782686",
    "PART_NUMBER": "DC97-18439A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "173.58",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2858 N HIAWASSEE RD",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32818"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JAMES| NELSON",
    "JOB_NUMBER": "SNWQA31761EB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000096ESNWQA31761EB-1",
    "ABC": "A10158813508",
    "PART_NUMBER": "WR97X10076",
    "PART_STATUS": "INS",
    "PART_PRICE": "14.9",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JAMES| NELSON",
    "JOB_NUMBER": "SNWQA31761EB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000096DSNWQA31761EB-1",
    "ABC": "A10158802120",
    "PART_NUMBER": "WR97X25268",
    "PART_STATUS": "INS",
    "PART_PRICE": "22.17",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "TIRSO| MENESES",
    "JOB_NUMBER": "SNWQA317C13D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DD63-00072A",
    "PART_STATUS": "INS",
    "PART_PRICE": "9.64",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2801 FLORIDA AVE APT 413",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33133"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA318CE8B-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000086ASNWQA318CE8B-1",
    "ABC": "A10211701452",
    "PART_NUMBER": "DG94-03730A",
    "PART_STATUS": "RTS",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "EMMANUEL| CARDONA",
    "JOB_NUMBER": "SNWQA3194FC0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000297ASNWQA3194FC0-1",
    "ABC": "A10187791220",
    "PART_NUMBER": "BN95-06383B",
    "PART_STATUS": "INS",
    "PART_PRICE": "307.91",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "HOFFMAN| PEREZ-BORROTO",
    "JOB_NUMBER": "SNWQA3196EC0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA97-19028B",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "HOFFMAN| PEREZ-BORROTO",
    "JOB_NUMBER": "SNWQA3196EC0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000324ASNWQA3196EC0-1",
    "ABC": "A10152506897",
    "PART_NUMBER": "DA97-19028B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "89.05",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "HOFFMAN| PEREZ-BORROTO",
    "JOB_NUMBER": "SNWQA3196EC0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA97-19965C",
    "PART_STATUS": "DEL",
    "PART_PRICE": "195.64",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JAMES| NELSON",
    "JOB_NUMBER": "SNWQA31987BA-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer No Show",
    "WSN": "OCT000285ASNWQA31987BA-1",
    "ABC": "A10158816819",
    "PART_NUMBER": "WB56X34974",
    "PART_STATUS": "PKD",
    "PART_PRICE": "95.1",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQA319A690-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000168ASNWQA319A690-1",
    "ABC": "A10167095842",
    "PART_NUMBER": "DC96-00882F",
    "PART_STATUS": "INS",
    "PART_PRICE": "70.22",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "10607 HOWERTON AVE",
    "ADDRESS_CITY": "FAIRFAX",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22030"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA319ADC1-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000307ASNWQA319ADC1-1",
    "ABC": "A10231739642",
    "PART_NUMBER": "BN94-17251R",
    "PART_STATUS": "INS",
    "PART_PRICE": "180",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "12903 SE HOBE HILLS DR",
    "ADDRESS_CITY": "HOBE SOUND",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33455"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA319ADC1-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000307BSNWQA319ADC1-1",
    "ABC": "A10242335364",
    "PART_NUMBER": "BN96-52803A",
    "PART_STATUS": "INS",
    "PART_PRICE": "9.7",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "RUBEN| LOPEZ",
    "JOB_NUMBER": "SNWQA319EFEB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": "OCT000465ASNWQA319EFEB-1",
    "ABC": "A10152517942",
    "PART_NUMBER": "DA82-02804D",
    "PART_STATUS": "PKD",
    "PART_PRICE": "242.96",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JOSEPH| BRADISH",
    "JOB_NUMBER": "SNWQA31A0C2A-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer No Show",
    "WSN": "OCT000039ASNWQA31A0C2A-1",
    "ABC": "A10166312342",
    "PART_NUMBER": "DD97-00489E",
    "PART_STATUS": "PKD",
    "PART_PRICE": "126.84",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA31A2BD0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000109BSNWQA31A2BD0-1",
    "ABC": "A10187789008",
    "PART_NUMBER": "BN95-07681B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "EDGAR| ESPINOSA",
    "JOB_NUMBER": "SNWQA31A4AB6-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000348BSNWQA31A4AB6-1",
    "ABC": "A10152517864",
    "PART_NUMBER": "BN95-07353F",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "STEVEN| VENNE",
    "JOB_NUMBER": "SNWQA31AF786-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000158BSNWQA31AF786-1",
    "ABC": "A10205889906",
    "PART_NUMBER": "DA97-12540G",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15291 HENRICI RD",
    "ADDRESS_CITY": "OREGON CITY",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97045"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| CURRENS",
    "JOB_NUMBER": "SNWQA31B22C3-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000169DSNWQA31B22C3-1",
    "ABC": "A10150765064",
    "PART_NUMBER": "DA94-05310R",
    "PART_STATUS": "INS",
    "PART_PRICE": "126.55",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "24604 NE 236TH AVE",
    "ADDRESS_CITY": "BATTLE GROUND",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98604"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQA31B24B2-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000151CSNWQA31B24B2-1",
    "ABC": "A10211727452",
    "PART_NUMBER": "DC93-00819C",
    "PART_STATUS": "INS",
    "PART_PRICE": "143.28",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "16725 SE COHIBA CT",
    "ADDRESS_CITY": "DAMASCUS",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97089"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "EMMANUEL| CARDONA",
    "JOB_NUMBER": "SNWQA31B6E4D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000196BSNWQA31B6E4D-1",
    "ABC": "A10187785120",
    "PART_NUMBER": "BN94-17171G",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "234",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQA31BB539-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000323ASNWQA31BB539-1",
    "ABC": "A10152506485",
    "PART_NUMBER": "BN96-39821F",
    "PART_STATUS": "INS",
    "PART_PRICE": "4.49",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQA31C375C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000253BSNWQA31C375C-1",
    "ABC": "A10211734386",
    "PART_NUMBER": "BN95-07175B",
    "PART_STATUS": "INS",
    "PART_PRICE": "496",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQA31C6EE9-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer No Show",
    "WSN": "OCT000445ASNWQA31C6EE9-1",
    "ABC": "A10153147418",
    "PART_NUMBER": "DD82-01376B",
    "PART_STATUS": "PKD",
    "PART_PRICE": "235.53",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQA31CBD0C-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-10-31T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer No Show",
    "WSN": "OCT000482ASNWQA31CBD0C-1",
    "ABC": "A10152521319",
    "PART_NUMBER": "BN96-52602B",
    "PART_STATUS": "PKD",
    "PART_PRICE": "171.89",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "TA'MIA| SMITH",
    "JOB_NUMBER": "SNWQA31CDE88-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer No Show",
    "WSN": "OCT000283ASNWQA31CDE88-1",
    "ABC": "A10158816653",
    "PART_NUMBER": "WD21X28718",
    "PART_STATUS": "PKD",
    "PART_PRICE": "41.54",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "BLAKE| KOSTREBA",
    "JOB_NUMBER": "SNWQA31D18A8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000240ASNWQA31D18A8-1",
    "ABC": "A10211727520",
    "PART_NUMBER": "DE92-03657A",
    "PART_STATUS": "INS",
    "PART_PRICE": "96.01",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3633 NE 17TH AVE",
    "ADDRESS_CITY": "PORTLAND",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97212"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "ROBERT| BOLDEN",
    "JOB_NUMBER": "SNWQA31D5556-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000071ASNWQA31D5556-1",
    "ABC": "A10191379320",
    "PART_NUMBER": "DD82-01588A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "72.74",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5030 KRAFT AVE SE STE D",
    "ADDRESS_CITY": "GRAND RAPIDS",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49512"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "WADLER| VOIGT",
    "JOB_NUMBER": "SNWQA31E0D26-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000497BSNWQA31E0D26-1",
    "ABC": "A10238595397",
    "PART_NUMBER": "DA97-19898A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "10",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JEFFREY| WIEGMANN",
    "JOB_NUMBER": "SNWQ72FC9D80-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "JUL000335BSNWQ72FC9D80-1",
    "ABC": "A10160160631",
    "PART_NUMBER": "MCR67147702",
    "PART_STATUS": "PKD",
    "PART_PRICE": "33.04",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "BLUESTREAK-FSL-DAYTONA",
    "AGENT_NAME": "LOUIS| AHLHEIM",
    "JOB_NUMBER": "SNWQ93102203-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000152ASNWQ93102203-1",
    "ABC": "A10194225819",
    "PART_NUMBER": "BN94-15765G",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "126",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "31 CLUB HOUSE DR",
    "ADDRESS_CITY": "PALM COAST",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32137"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "D'ANDRE| BAKER",
    "JOB_NUMBER": "SNWQ93103985-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000083BSNWQ93103985-1",
    "ABC": "A10191375931",
    "PART_NUMBER": "ADD36429878",
    "PART_STATUS": "INS",
    "PART_PRICE": "347.94",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5030 KRAFT AVE SE STE D",
    "ADDRESS_CITY": "GRAND RAPIDS",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49512"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQ93122BA1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000083ASNWQ93122BA1-1",
    "ABC": "A10208166564",
    "PART_NUMBER": "BN96-52796A",
    "PART_STATUS": "RTS",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JOSEPH| BRADISH",
    "JOB_NUMBER": "SNWQ93126AE8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "OTHER",
    "WSN": "SEP000254BSNWQ93126AE8-1",
    "ABC": "A10158007564",
    "PART_NUMBER": "DD29-00006A",
    "PART_STATUS": "REC",
    "PART_PRICE": "72.37",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "24915 PARADISE RD",
    "ADDRESS_CITY": "BONITA SPRINGS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34135"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JOSEPH| BRADISH",
    "JOB_NUMBER": "SNWQ93126AE8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "OTHER",
    "WSN": "SEP000254ASNWQ93126AE8-1",
    "ABC": "A10157094031",
    "PART_NUMBER": "DD82-01336A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "137.76",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DWAYNE| HILTUNEN",
    "JOB_NUMBER": "SNWQ93127155-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000742ASNWQ93127155-1",
    "ABC": "A10163757175",
    "PART_NUMBER": "BN96-39820H",
    "PART_STATUS": "PKD",
    "PART_PRICE": "5.65",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9313285C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000457ASNWQ9313285C-1",
    "ABC": "A10235691875",
    "PART_NUMBER": "BN95-07732A",
    "PART_STATUS": "INS",
    "PART_PRICE": "576",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JAMES| CIRAOLO",
    "JOB_NUMBER": "SNWQ93148E3A-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "OTHER",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA63-06541D",
    "PART_STATUS": "UNK",
    "PART_PRICE": "78.64",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ERIK| FLEITES",
    "JOB_NUMBER": "SNWQ93155539-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000626BSNWQ93155539-1",
    "ABC": "A10225270463",
    "PART_NUMBER": "BN94-17410J",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ERIK| FLEITES",
    "JOB_NUMBER": "SNWQ93155539-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN44-01176A",
    "PART_STATUS": "DEL",
    "PART_PRICE": "188.65",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "5765 SW 28TH ST",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33155"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQ93155D20-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000166CSNWQ93155D20-1",
    "ABC": "A10197544053",
    "PART_NUMBER": "BN59-01377A",
    "PART_STATUS": "PKD",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3174 LOWINGWOOD CT",
    "ADDRESS_CITY": "JENISON",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49428"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQ931588C4-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000741CSNWQ931588C4-1",
    "ABC": "A10152522308",
    "PART_NUMBER": "BN95-08571A",
    "PART_STATUS": "INS",
    "PART_PRICE": "1035.1",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQ9315B1D1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000661ASNWQ9315B1D1-1",
    "ABC": "A10152740108",
    "PART_NUMBER": "DC97-16984C",
    "PART_STATUS": "REC",
    "PART_PRICE": "172.74",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15325 LEBEAU LOOP",
    "ADDRESS_CITY": "WINTER GARDEN",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34787"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA3162309-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000001BSNWQA3162309-1",
    "ABC": "A10152400608",
    "PART_NUMBER": "BN96-53951T",
    "PART_STATUS": "PKD",
    "PART_PRICE": "130.03",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DWAYNE| HILTUNEN",
    "JOB_NUMBER": "SNWQA31652F9-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000352BSNWQA31652F9-1",
    "ABC": "A10152516875",
    "PART_NUMBER": "BN95-08137B",
    "PART_STATUS": "INS",
    "PART_PRICE": "706.75",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "LANCE| BROOKS",
    "JOB_NUMBER": "SNWQA31679E0-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN94-00053T",
    "PART_STATUS": "UNK",
    "PART_PRICE": "0",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "551 NW 42ND AVE APT B302",
    "ADDRESS_CITY": "PLANTATION",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33317"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JAMES| NELSON",
    "JOB_NUMBER": "SNWQA31761EB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000096DSNWQA31761EB-1",
    "ABC": "A10158802208",
    "PART_NUMBER": "WR97X10033",
    "PART_STATUS": "INS",
    "PART_PRICE": "16.2",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "GARRY| LEWIS",
    "JOB_NUMBER": "SNWQA317C056-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000080ASNWQA317C056-1",
    "ABC": "A10239950364",
    "PART_NUMBER": "DA32-10104N",
    "PART_STATUS": "INS",
    "PART_PRICE": "12.05",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "TIRSO| MENESES",
    "JOB_NUMBER": "SNWQA317C13D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000396CSNWQA317C13D-1",
    "ABC": "A10237818086",
    "PART_NUMBER": "DD61-00655A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "16.2",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2801 FLORIDA AVE APT 413",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33133"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "CONNOR| WALLACE",
    "JOB_NUMBER": "SNWQA317C32B-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000206ASNWQA317C32B-1",
    "ABC": "A10242324474",
    "PART_NUMBER": "3903-001296",
    "PART_STATUS": "INS",
    "PART_PRICE": "20.25",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "CONNOR| WALLACE",
    "JOB_NUMBER": "SNWQA317C32B-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000206BSNWQA317C32B-1",
    "ABC": "A10242334375",
    "PART_NUMBER": "BN95-07479A",
    "PART_STATUS": "INS",
    "PART_PRICE": "1544",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "KEVIN| PHILLIPS",
    "JOB_NUMBER": "SNWQA317F155-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA97-12683A",
    "PART_STATUS": "ORD",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "KEVIN| PHILLIPS",
    "JOB_NUMBER": "SNWQA318800E-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA97-14424A",
    "PART_STATUS": "SHP",
    "PART_PRICE": "217.26",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA318B947-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000073BSNWQA318B947-1",
    "ABC": "A10211700797",
    "PART_NUMBER": "DA32-10104N",
    "PART_STATUS": "NRQ",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA318B947-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000073ASNWQA318B947-1",
    "ABC": "A10211698819",
    "PART_NUMBER": "DA31-00334C",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "79.38",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA3190301-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN96-52806A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "5.5",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "85 SW 52ND AVE STE 500",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34474"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "HOFFMAN| PEREZ-BORROTO",
    "JOB_NUMBER": "SNWQA3196EC0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000324BSNWQA3196EC0-1",
    "ABC": "A10152519019",
    "PART_NUMBER": "DA97-19965C",
    "PART_STATUS": "INS",
    "PART_PRICE": "-195.64",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "HOFFMAN| PEREZ-BORROTO",
    "JOB_NUMBER": "SNWQA3196EC0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA97-19028B",
    "PART_STATUS": "UNK",
    "PART_PRICE": "-89.05",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "431 HILLTOP DR",
    "ADDRESS_CITY": "LAKE WALES",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33853"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "HOFFMAN| PEREZ-BORROTO",
    "JOB_NUMBER": "SNWQA3196EC0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA97-19965C",
    "PART_STATUS": "SHP",
    "PART_PRICE": "195.64",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "431 HILLTOP DR",
    "ADDRESS_CITY": "LAKE WALES",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33853"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "BLAKE| KOSTREBA",
    "JOB_NUMBER": "SNWQA319B995-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000109BSNWQA319B995-1",
    "ABC": "A10150709542",
    "PART_NUMBER": "DC97-22579B",
    "PART_STATUS": "INS",
    "PART_PRICE": "62.33",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "4821 SE 60TH AVE",
    "ADDRESS_CITY": "PORTLAND",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97206"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA31A155D-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000158ASNWQA31A155D-1",
    "ABC": "A10186566920",
    "PART_NUMBER": "DA81-05595A",
    "PART_STATUS": "INS",
    "PART_PRICE": "22",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "8869 SE 130TH LOOP",
    "ADDRESS_CITY": "SUMMERFIELD",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34491"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "EDGAR| ESPINOSA",
    "JOB_NUMBER": "SNWQA31A4AB6-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000348ASNWQA31A4AB6-1",
    "ABC": "A10152508953",
    "PART_NUMBER": "BN96-53207A",
    "PART_STATUS": "INS",
    "PART_PRICE": "5.07",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "STEVEN| VENNE",
    "JOB_NUMBER": "SNWQA31AC528-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000201BSNWQA31AC528-1",
    "ABC": "A10211725708",
    "PART_NUMBER": "DD97-00484D",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA31AD9A6-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-16T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN94-16115X",
    "PART_STATUS": "SHP",
    "PART_PRICE": "162",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQA31AF1BE-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA97-16028A",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "12355 SE YELLOWSTONE ST",
    "ADDRESS_CITY": "DAMASCUS",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97089"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA31AFF06-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000303BSNWQA31AFF06-1",
    "ABC": "A10235974108",
    "PART_NUMBER": "DC97-16350S",
    "PART_STATUS": "INS",
    "PART_PRICE": "156.44",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "311 5TH ST",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33458"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "EMMANUEL| CARDONA",
    "JOB_NUMBER": "SNWQA31B29B1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000216BSNWQA31B29B1-1",
    "ABC": "A10187791064",
    "PART_NUMBER": "BN95-05152A",
    "PART_STATUS": "INS",
    "PART_PRICE": "248",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "JASON| ALSTON",
    "JOB_NUMBER": "SNWQA31B6FE1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000197ASNWQA31B6FE1-1",
    "ABC": "A10172433519",
    "PART_NUMBER": "BN44-00932K",
    "PART_STATUS": "INS",
    "PART_PRICE": "97.26",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "MARC| DESOUVRE",
    "JOB_NUMBER": "SNWQA31B7344-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000094BSNWQA31B7344-1",
    "ABC": "A10166320429",
    "PART_NUMBER": "DA31-00146J",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "71.71",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "MARC| DESOUVRE",
    "JOB_NUMBER": "SNWQA31B7344-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000094BSNWQA31B7344-1",
    "ABC": "A10166320597",
    "PART_NUMBER": "DA82-01415A",
    "PART_STATUS": "INS",
    "PART_PRICE": "16.62",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "WALTER| ANDERSON",
    "JOB_NUMBER": "SNWQA31B7A11-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN44-01055A",
    "PART_STATUS": "SHP",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2000 HUNTINGTON AVE APT 1011",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22303"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ENDER| GARCIA MARIN",
    "JOB_NUMBER": "SNWQA31B7D53-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000343BSNWQA31B7D53-1",
    "ABC": "A10225295620",
    "PART_NUMBER": "DA97-07186B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "151.23",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "STEVEN| MANN",
    "JOB_NUMBER": "SNWQA31BD63B-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000194ASNWQA31BD63B-1",
    "ABC": "A10187782496",
    "PART_NUMBER": "DC47-00017A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "45.75",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-FSL-HOUSTON",
    "AGENT_NAME": "ALEJANDRO| MANZANO TROVAMALA",
    "JOB_NUMBER": "SNWQA31C2A00-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000225ASNWQA31C2A00-1",
    "ABC": "A10206800119",
    "PART_NUMBER": "8577274",
    "PART_STATUS": "INS",
    "PART_PRICE": "6",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "8712 WESTPARK DR",
    "ADDRESS_CITY": "HOUSTON",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "77063"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "KEVIN| PHILLIPS",
    "JOB_NUMBER": "SNWQA31C3125-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000158ASNWQA31C3125-1",
    "ABC": "A10166996119",
    "PART_NUMBER": "BN94-15226H",
    "PART_STATUS": "INS",
    "PART_PRICE": "71.9",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "BRANDON| KENDALL",
    "JOB_NUMBER": "SNWQA31CC09B-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000236ASNWQA31CC09B-1",
    "ABC": "A10187786864",
    "PART_NUMBER": "OM75P-21-ESGN",
    "PART_STATUS": "INS",
    "PART_PRICE": "93.74",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DAVID| PACHECO",
    "JOB_NUMBER": "SNWQA31CEC83-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000501BSNWQA31CEC83-1",
    "ABC": "A10152525375",
    "PART_NUMBER": "DC67-00133A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "31.88",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA31D18AD-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "OCT000241BSNWQA31D18AD-1",
    "ABC": "A10211729842",
    "PART_NUMBER": "DA97-19898A",
    "PART_STATUS": "PKD",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ENDER| GARCIA MARIN",
    "JOB_NUMBER": "SNWQA31E03FF-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000387ASNWQA31E03FF-1",
    "ABC": "A10225295708",
    "PART_NUMBER": "BN96-49483A",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "WADLER| VOIGT",
    "JOB_NUMBER": "SNWQA31E0D26-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000497BSNWQA31E0D26-1",
    "ABC": "A10238594975",
    "PART_NUMBER": "DA82-02367A",
    "PART_STATUS": "INS",
    "PART_PRICE": "7.23",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JEFFREY| WIEGMANN",
    "JOB_NUMBER": "SNWQ72FC9D80-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "JUL000335ASNWQ72FC9D80-1",
    "ABC": "A10159499753",
    "PART_NUMBER": "AJK73229702",
    "PART_STATUS": "PKD",
    "PART_PRICE": "95.32",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JEFFREY| WIEGMANN",
    "JOB_NUMBER": "SNWQ72FC9D80-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "AGM74269801",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-FSL-HOUSTON",
    "AGENT_NAME": "PATRICK| HUGHES",
    "JOB_NUMBER": "SNWQ930DC93B-2",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000247ASNWQ930DC93B-2",
    "ABC": "A10206801364",
    "PART_NUMBER": "WR60X10273",
    "PART_STATUS": "INS",
    "PART_PRICE": "139.52",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "8712 WESTPARK DR",
    "ADDRESS_CITY": "HOUSTON",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "77063"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "CHRISTOPHER| MURRAY",
    "JOB_NUMBER": "SNWQ930F57B5-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "RA-F36DB3QL/AA",
    "PART_STATUS": "INC",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "SCOTT| LEVENSON",
    "JOB_NUMBER": "SNWQ9311A997-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN95-06374A",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "SCOTT| LEVENSON",
    "JOB_NUMBER": "SNWQ9311A997-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000325DSNWQ9311A997-1",
    "ABC": "A10235727108",
    "PART_NUMBER": "BN96-39820H",
    "PART_STATUS": "INS",
    "PART_PRICE": "5.65",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "SCOTT| LEVENSON",
    "JOB_NUMBER": "SNWQ9311A997-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000325BSNWQ9311A997-1",
    "ABC": "A10235691953",
    "PART_NUMBER": "BN95-06374A",
    "PART_STATUS": "RTS",
    "PART_PRICE": "307.8",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "SCOTT| LEVENSON",
    "JOB_NUMBER": "SNWQ9311A997-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000325ASNWQ9311A997-1",
    "ABC": "A10235681897",
    "PART_NUMBER": "BN96-39820H",
    "PART_STATUS": "RTS",
    "PART_PRICE": "-5.65",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JOSEPH| BRADISH",
    "JOB_NUMBER": "SNWQ93126AE8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "OTHER",
    "WSN": "SEP000254ASNWQ93126AE8-1",
    "ABC": "A10157093953",
    "PART_NUMBER": "DD82-01337A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "151.53",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DWAYNE| HILTUNEN",
    "JOB_NUMBER": "SNWQ93127155-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000742BSNWQ93127155-1",
    "ABC": "A10163779452",
    "PART_NUMBER": "BN95-06343B",
    "PART_STATUS": "INS",
    "PART_PRICE": "1589.98",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9313285C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000457ESNWQ9313285C-1",
    "ABC": "A10236412831",
    "PART_NUMBER": "BN96-52604D",
    "PART_STATUS": "INS",
    "PART_PRICE": "222.76",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "1499 SW 2ND AVE",
    "ADDRESS_CITY": "BOCA RATON",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33432"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9313285C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000457CSNWQ9313285C-1",
    "ABC": "A10238586064",
    "PART_NUMBER": "BN95-07732A",
    "PART_STATUS": "RTS",
    "PART_PRICE": "576",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ERIK| FLEITES",
    "JOB_NUMBER": "SNWQ93155539-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000626CSNWQ93155539-1",
    "ABC": "A10225279542",
    "PART_NUMBER": "BN95-08391A",
    "PART_STATUS": "INS",
    "PART_PRICE": "1456",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQ93155D20-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000166ASNWQ93155D20-1",
    "ABC": "A10197544397",
    "PART_NUMBER": "BN94-16866D",
    "PART_STATUS": "PKD",
    "PART_PRICE": "85.95",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3174 LOWINGWOOD CT",
    "ADDRESS_CITY": "JENISON",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49428"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQ9315821A-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DC97-14486A",
    "PART_STATUS": "SHP",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5030 KRAFT AVE SE STE D",
    "ADDRESS_CITY": "GRAND RAPIDS",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49512"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "STEVEN| VENNE",
    "JOB_NUMBER": "SNWQ9315B0BA-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DC97-16984C",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": null,
    "ADDRESS_LINE_1": "1405 ROSEMONT RD",
    "ADDRESS_CITY": "West Linn",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97068"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQ931603ED-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000319CSNWQ931603ED-1",
    "ABC": "A10166979531",
    "PART_NUMBER": "DG94-03549A",
    "PART_STATUS": "INS",
    "PART_PRICE": "286.14",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA3162309-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN94-17292Z",
    "PART_STATUS": "SHP",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "LANCE| BROOKS",
    "JOB_NUMBER": "SNWQA31679E0-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN94-00053T",
    "PART_STATUS": "UNK",
    "PART_PRICE": "180",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "551 NW 42ND AVE APT B302",
    "ADDRESS_CITY": "PLANTATION",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33317"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "LANCE| BROOKS",
    "JOB_NUMBER": "SNWQA31679E0-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000263BSNWQA31679E0-1",
    "ABC": "A10238591331",
    "PART_NUMBER": "BN95-07681A",
    "PART_STATUS": "INS",
    "PART_PRICE": "624",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "LANCE| BROOKS",
    "JOB_NUMBER": "SNWQA31679E0-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000263ASNWQA31679E0-1",
    "ABC": "A10202833119",
    "PART_NUMBER": "BN94-00053T",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "551 NW 42ND AVE",
    "ADDRESS_CITY": "PLANTATION",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33317"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "JOHN| TORRES",
    "JOB_NUMBER": "SNWQA316A39D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000041CSNWQA316A39D-1",
    "ABC": "A10163795864",
    "PART_NUMBER": "BN95-07271A",
    "PART_STATUS": "INS",
    "PART_PRICE": "552",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "JOHN| TORRES",
    "JOB_NUMBER": "SNWQA316A39D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000041ASNWQA316A39D-1",
    "ABC": "A10163759075",
    "PART_NUMBER": "BN44-01111A",
    "PART_STATUS": "INS",
    "PART_PRICE": "110.67",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "JOHN| TORRES",
    "JOB_NUMBER": "SNWQA316A39D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000041ASNWQA316A39D-1",
    "ABC": "A10163759153",
    "PART_NUMBER": "BN94-16871Z",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "72.43",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "TIRSO| MENESES",
    "JOB_NUMBER": "SNWQA317C13D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000396ASNWQA317C13D-1",
    "ABC": "A10225297119",
    "PART_NUMBER": "DD62-00136B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "1.34",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "TIRSO| MENESES",
    "JOB_NUMBER": "SNWQA317C13D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000396BSNWQA317C13D-1",
    "ABC": "A10237818164",
    "PART_NUMBER": "DD63-00072A",
    "PART_STATUS": "INS",
    "PART_PRICE": "9.64",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2801 FLORIDA AVE APT 413",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33133"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "KEVIN| PHILLIPS",
    "JOB_NUMBER": "SNWQA317F155-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000038ASNWQA317F155-1",
    "ABC": "A10166971931",
    "PART_NUMBER": "DA82-01415A",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "MICHAEL| AGGE",
    "JOB_NUMBER": "SNWQA31875C8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "AGU73729908",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA318B947-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000073ASNWQA318B947-1",
    "ABC": "A10211698997",
    "PART_NUMBER": "DA92-00426G",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "110.1",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "MANUEL| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA318CB60-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000146DSNWQA318CB60-1",
    "ABC": "A10152741764",
    "PART_NUMBER": "DA97-21806E",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "67.23",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "1453 MUIR CIR",
    "ADDRESS_CITY": "CLERMONT",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34711"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "MANUEL| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA318CB60-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000146BSNWQA318CB60-1",
    "ABC": "A10152746897",
    "PART_NUMBER": "DA82-01415A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "16.62",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "1453 MUIR CIR",
    "ADDRESS_CITY": "CLERMONT",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34711"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA3190301-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN94-16871Z",
    "PART_STATUS": "INS",
    "PART_PRICE": "72.43",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "85 SW 52ND AVE STE 500",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34474"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA3190301-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000296ASNWQA3190301-1",
    "ABC": "A10187791142",
    "PART_NUMBER": "BN95-07297A",
    "PART_STATUS": "INS",
    "PART_PRICE": "824",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-FSL-HOUSTON",
    "AGENT_NAME": "NATHANIEL| SAMUELS",
    "JOB_NUMBER": "SNWQA319ED81-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000113CSNWQA319ED81-1",
    "ABC": "A10206799064",
    "PART_NUMBER": "WP2194719",
    "PART_STATUS": "INS",
    "PART_PRICE": "3.01",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "8712 WESTPARK DR",
    "ADDRESS_CITY": "HOUSTON",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "77063"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "RUBEN| LOPEZ",
    "JOB_NUMBER": "SNWQA319EE69-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000488ASNWQA319EE69-1",
    "ABC": "A10152522485",
    "PART_NUMBER": "DA82-02807B",
    "PART_STATUS": "INS",
    "PART_PRICE": "218.52",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "RUBEN| LOPEZ",
    "JOB_NUMBER": "SNWQA319F0D5-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000489ASNWQA319F0D5-1",
    "ABC": "A10152522719",
    "PART_NUMBER": "DA82-02804A",
    "PART_STATUS": "INS",
    "PART_PRICE": "247.68",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "RUBEN| LOPEZ",
    "JOB_NUMBER": "SNWQA319F0D5-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000489ASNWQA319F0D5-1",
    "ABC": "A10152522631",
    "PART_NUMBER": "DA82-02807B",
    "PART_STATUS": "INS",
    "PART_PRICE": "218.52",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "RUBEN| LOPEZ",
    "JOB_NUMBER": "SNWQA319F0D5-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000489ASNWQA319F0D5-1",
    "ABC": "A10207698220",
    "PART_NUMBER": "DA97-19112C",
    "PART_STATUS": "INS",
    "PART_PRICE": "142.47",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA31A155D-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000158ASNWQA31A155D-1",
    "ABC": "A10186567264",
    "PART_NUMBER": "DA97-14474C",
    "PART_STATUS": "INS",
    "PART_PRICE": "108.61",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "8869 SE 130TH LOOP",
    "ADDRESS_CITY": "SUMMERFIELD",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34491"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA31A155D-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA97-12675R",
    "PART_STATUS": "SHP",
    "PART_PRICE": "172.47",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DANIEL| VALLEJO",
    "JOB_NUMBER": "SNWQA31A20CB-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000287CSNWQA31A20CB-1",
    "ABC": "A10152506308",
    "PART_NUMBER": "DA92-01196H",
    "PART_STATUS": "INS",
    "PART_PRICE": "147.88",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA31A2BD0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000109BSNWQA31A2BD0-1",
    "ABC": "A10187789008",
    "PART_NUMBER": "BN95-07681B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "736",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "EDGAR| ESPINOSA",
    "JOB_NUMBER": "SNWQA31A4AB6-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000348BSNWQA31A4AB6-1",
    "ABC": "A10152517864",
    "PART_NUMBER": "BN95-07353F",
    "PART_STATUS": "INS",
    "PART_PRICE": "480",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "GERSON| ESPINOZA CHAVEZ",
    "JOB_NUMBER": "SNWQA31AB605-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000064BSNWQA31AB605-1",
    "ABC": "A10166323564",
    "PART_NUMBER": "BN95-06755D",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "ADDYSON| FABIAN POLANCO",
    "JOB_NUMBER": "SNWQA31ABF2D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000318ASNWQA31ABF2D-1",
    "ABC": "A10152505231",
    "PART_NUMBER": "BN96-39820K",
    "PART_STATUS": "INS",
    "PART_PRICE": "8.76",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "JASON| ALSTON",
    "JOB_NUMBER": "SNWQA31ABFB4-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000209ASNWQA31ABFB4-1",
    "ABC": "A10172434686",
    "PART_NUMBER": "137544800",
    "PART_STATUS": "INS",
    "PART_PRICE": "65.88",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "STEVEN| VENNE",
    "JOB_NUMBER": "SNWQA31AC528-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000201ASNWQA31AC528-1",
    "ABC": "A10205888264",
    "PART_NUMBER": "DD97-00498F",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "199.18",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2780 ARBOR DR",
    "ADDRESS_CITY": "WEST LINN",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97068"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "STEVEN| VENNE",
    "JOB_NUMBER": "SNWQA31AC528-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000201CSNWQA31AC528-1",
    "ABC": "A10211730164",
    "PART_NUMBER": "DD39-00013T",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "58.09",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA31AD9A6-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-16T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000274CSNWQA31AD9A6-1",
    "ABC": "A10152516619",
    "PART_NUMBER": "BN95-06739A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "232",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA31AFF06-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000303ASNWQA31AFF06-1",
    "ABC": "A10239011586",
    "PART_NUMBER": "DC97-18682D",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "75.59",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "311 5TH ST",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33458"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| CURRENS",
    "JOB_NUMBER": "SNWQA31B22C3-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000169CSNWQA31B22C3-1",
    "ABC": "A10149712697",
    "PART_NUMBER": "DA97-22974B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "89.28",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "24604 NE 236TH AVE",
    "ADDRESS_CITY": "BATTLE GROUND",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98604"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| CURRENS",
    "JOB_NUMBER": "SNWQA31B22C3-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000169BSNWQA31B22C3-1",
    "ABC": "A10149712775",
    "PART_NUMBER": "DA32-00074A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "24604 NE 236TH AVE",
    "ADDRESS_CITY": "BATTLE GROUND",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98604"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "EMMANUEL| CARDONA",
    "JOB_NUMBER": "SNWQA31B29B1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000216ASNWQA31B29B1-1",
    "ABC": "A10187785953",
    "PART_NUMBER": "BN96-39821E",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "3.91",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ENDER| GARCIA MARIN",
    "JOB_NUMBER": "SNWQA31B7D53-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000343ASNWQA31B7D53-1",
    "ABC": "A10225292320",
    "PART_NUMBER": "DA82-01415A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "16.62",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "GARRY| LEWIS",
    "JOB_NUMBER": "SNWQA31B8A32-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000363ASNWQA31B8A32-1",
    "ABC": "A10225293720",
    "PART_NUMBER": "DG96-00173A",
    "PART_STATUS": "INS",
    "PART_PRICE": "30.18",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "WALTER| ANDERSON",
    "JOB_NUMBER": "SNWQA31B9895-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "OCT000143ASNWQA31B9895-1",
    "ABC": "A10166992986",
    "PART_NUMBER": "DA97-14441A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "STEVEN| MANN",
    "JOB_NUMBER": "SNWQA31BD63B-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "WP33002789",
    "PART_STATUS": "SHP",
    "PART_PRICE": "25.79",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA31BED24-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer No Show",
    "WSN": "OCT000211ASNWQA31BED24-1",
    "ABC": "A10211722997",
    "PART_NUMBER": "DA82-02923A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "232.17",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "ROY| DUITMAN",
    "JOB_NUMBER": "SNWQA31C137A-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000248ASNWQA31C137A-1",
    "ABC": "A10211728853",
    "PART_NUMBER": "DC97-22147A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "177.69",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-HOUSTON",
    "AGENT_NAME": "ALEJANDRO| MANZANO TROVAMALA",
    "JOB_NUMBER": "SNWQA31C2A00-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000225ASNWQA31C2A00-1",
    "ABC": "A10206800031",
    "PART_NUMBER": "280148",
    "PART_STATUS": "INS",
    "PART_PRICE": "15.72",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "8712 WESTPARK DR",
    "ADDRESS_CITY": "HOUSTON",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "77063"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "GARRY| LEWIS",
    "JOB_NUMBER": "SNWQA31C2DF4-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000273ASNWQA31C2DF4-1",
    "ABC": "A10225284997",
    "PART_NUMBER": "DA96-00640B",
    "PART_STATUS": "INS",
    "PART_PRICE": "70.76",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "GARRY| LEWIS",
    "JOB_NUMBER": "SNWQA31C2DF4-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "nullASNWQA31C2DF4-1",
    "ABC": "A10225287964",
    "PART_NUMBER": "DA82-02260W",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "195.84",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "WADLER| VOIGT",
    "JOB_NUMBER": "SNWQA31C351A-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "OCT000393ASNWQA31C351A-1",
    "ABC": "A10238584997",
    "PART_NUMBER": "DC92-01849A",
    "PART_STATUS": "RTS",
    "PART_PRICE": "158.51",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "NAHUM| GABRIEL",
    "JOB_NUMBER": "SNWQA31C56CC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000330BSNWQA31C56CC-1",
    "ABC": "A10225293319",
    "PART_NUMBER": "DC96-00766A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "6.69",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQA31CFE59-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": "OCT000055ASNWQA31CFE59-1",
    "ABC": "A10191377753",
    "PART_NUMBER": "DC34-00025E",
    "PART_STATUS": "PKD",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5030 KRAFT AVE SE STE D",
    "ADDRESS_CITY": "GRAND RAPIDS",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49512"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "WADLER| VOIGT",
    "JOB_NUMBER": "SNWQA31D1F5A-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000432ASNWQA31D1F5A-1",
    "ABC": "A10238587620",
    "PART_NUMBER": "DD82-01247A",
    "PART_STATUS": "INS",
    "PART_PRICE": "121.81",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "ROBERT| BOLDEN",
    "JOB_NUMBER": "SNWQA31D5556-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DD82-01415A",
    "PART_STATUS": "SHP",
    "PART_PRICE": "21.93",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5030 KRAFT AVE SE STE D",
    "ADDRESS_CITY": "GRAND RAPIDS",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49512"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| CURRENS",
    "JOB_NUMBER": "SNWQA31E0397-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DG94-04085A",
    "PART_STATUS": "CAN",
    "PART_PRICE": "209.15",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "WADLER| VOIGT",
    "JOB_NUMBER": "SNWQA31E0D26-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000497ASNWQA31E0D26-1",
    "ABC": "A10238592731",
    "PART_NUMBER": "DA94-02679A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "51",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "WADLER| VOIGT",
    "JOB_NUMBER": "SNWQA31E0D26-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000497BSNWQA31E0D26-1",
    "ABC": "A10238595131",
    "PART_NUMBER": "DA97-14474C",
    "PART_STATUS": "INS",
    "PART_PRICE": "108.61",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JEFFREY| WIEGMANN",
    "JOB_NUMBER": "SNWQ72FC9D80-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "AGM74269801",
    "PART_STATUS": "DEL",
    "PART_PRICE": "110.28",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "1729 REDDING DR # 75002",
    "ADDRESS_CITY": "ALLEN",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75002"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "CHRISTOPHER| MURRAY",
    "JOB_NUMBER": "SNWQ930F57B5-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA82-03399E",
    "PART_STATUS": "INC",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "SCOTT| LEVENSON",
    "JOB_NUMBER": "SNWQ9311A997-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000325ESNWQ9311A997-1",
    "ABC": "A10238579620",
    "PART_NUMBER": "BN94-16104Z",
    "PART_STATUS": "RTS",
    "PART_PRICE": "0",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JOSEPH| BRADISH",
    "JOB_NUMBER": "SNWQ93126AE8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "OTHER",
    "WSN": "SEP000254CSNWQ93126AE8-1",
    "ABC": "A10166317474",
    "PART_NUMBER": "DD82-01337B",
    "PART_STATUS": "PKD",
    "PART_PRICE": "124.15",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JOSEPH| BRADISH",
    "JOB_NUMBER": "SNWQ93126AE8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "OTHER",
    "WSN": "SEP000254CSNWQ93126AE8-1",
    "ABC": "A10166317397",
    "PART_NUMBER": "DD82-01347A",
    "PART_STATUS": "REC",
    "PART_PRICE": "128.89",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9313285C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000457DSNWQ9313285C-1",
    "ABC": "A10238590342",
    "PART_NUMBER": "BN95-07732A",
    "PART_STATUS": "RTS",
    "PART_PRICE": "576",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "IVENER| MEREUS",
    "JOB_NUMBER": "SNWQ93144EFB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-01T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DC97-22947F",
    "PART_STATUS": "INP",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "420 W TROPICAL WAY",
    "ADDRESS_CITY": "PLANTATION",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33317"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9314C7DC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000807BSNWQ9314C7DC-1",
    "ABC": "A10238595474",
    "PART_NUMBER": "BN95-06815A",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9314C7DC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": "A10202834697",
    "PART_NUMBER": "BN94-15724B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "234",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "13453 SW 42ND ST",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33330"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9314C7DC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000807ASNWQ9314C7DC-1",
    "ABC": "A10202834286",
    "PART_NUMBER": "BN44-01084A",
    "PART_STATUS": "INS",
    "PART_PRICE": "338.9",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "13453 SW 42ND ST",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33330"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQ931588C4-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000741ASNWQ931588C4-1",
    "ABC": "A10163756919",
    "PART_NUMBER": "BN96-46132B",
    "PART_STATUS": "INS",
    "PART_PRICE": "4.92",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "STEVEN| VENNE",
    "JOB_NUMBER": "SNWQ9315B0BA-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000315ASNWQ9315B0BA-1",
    "ABC": "A10150852753",
    "PART_NUMBER": "DC31-20014C",
    "PART_STATUS": "NRQ",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "1405 ROSEMONT RD",
    "ADDRESS_CITY": "WEST LINN",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97068"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JOSEPH| BRADISH",
    "JOB_NUMBER": "SNWQ9315D673-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DC97-22462F",
    "PART_STATUS": "SHP",
    "PART_PRICE": "197.3",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JOSEPH| BRADISH",
    "JOB_NUMBER": "SNWQ9315D673-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000297BSNWQ9315D673-1",
    "ABC": null,
    "PART_NUMBER": "DC92-02388H",
    "PART_STATUS": "INS",
    "PART_PRICE": "219.28",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQ931603ED-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000319ASNWQ931603ED-1",
    "ABC": "A10166959575",
    "PART_NUMBER": "DG94-04349A",
    "PART_STATUS": "INS",
    "PART_PRICE": "233.04",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA3162309-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000001CSNWQA3162309-1",
    "ABC": "A10152508531",
    "PART_NUMBER": "BN95-07326M",
    "PART_STATUS": "PKD",
    "PART_PRICE": "872",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DWAYNE| HILTUNEN",
    "JOB_NUMBER": "SNWQA31652F9-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000352ASNWQA31652F9-1",
    "ABC": "A10152509520",
    "PART_NUMBER": "BN96-39820H",
    "PART_STATUS": "INS",
    "PART_PRICE": "5.65",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "LANCE| BROOKS",
    "JOB_NUMBER": "SNWQA31679E0-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN44-01056A",
    "PART_STATUS": "DEL",
    "PART_PRICE": "115.63",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "551 NW 42ND AVE APT B302",
    "ADDRESS_CITY": "PLANTATION",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33317"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "ROBERT| BOLDEN",
    "JOB_NUMBER": "SNWQA3174DA7-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000004BSNWQA3174DA7-1",
    "ABC": "A10191379653",
    "PART_NUMBER": "DG94-03584B",
    "PART_STATUS": "INS",
    "PART_PRICE": "320.49",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5030 KRAFT AVE SE STE D",
    "ADDRESS_CITY": "GRAND RAPIDS",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49512"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "KEVIN| PHILLIPS",
    "JOB_NUMBER": "SNWQA317F155-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000038BSNWQA317F155-1",
    "ABC": "A10166976720",
    "PART_NUMBER": "DA32-00006R",
    "PART_STATUS": "PKD",
    "PART_PRICE": "10.98",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "ROY| DUITMAN",
    "JOB_NUMBER": "SNWQA3182DB0-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000051BSNWQA3182DB0-1",
    "ABC": "A10211696008",
    "PART_NUMBER": "BN94-15309B",
    "PART_STATUS": "PKD",
    "PART_PRICE": "192.84",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "ROY| DUITMAN",
    "JOB_NUMBER": "SNWQA3182DB0-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000051ASNWQA3182DB0-1",
    "ABC": "A10211695019",
    "PART_NUMBER": "BN44-01066B",
    "PART_STATUS": "PKD",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "CONNOR| WALLACE",
    "JOB_NUMBER": "SNWQA318745D-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Parts not delivered",
    "WSN": "OCT000198ASNWQA318745D-1",
    "ABC": "A10242322075",
    "PART_NUMBER": "BN59-01312G",
    "PART_STATUS": "PKD",
    "PART_PRICE": "41.33",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "CONNOR| WALLACE",
    "JOB_NUMBER": "SNWQA318745D-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Parts not delivered",
    "WSN": "OCT000198BSNWQA318745D-1",
    "ABC": "A10242335853",
    "PART_NUMBER": "BN95-05907A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "128.1",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "MICHAEL| AGGE",
    "JOB_NUMBER": "SNWQA31875C8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": "OCT000164BSNWQA31875C8-1",
    "ABC": "A10168284597",
    "PART_NUMBER": "MCK66703705",
    "PART_STATUS": "PKD",
    "PART_PRICE": "144.39",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "313 VALEVIEW CT NW",
    "ADDRESS_CITY": "VIENNA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22180"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "MICHAEL| AGGE",
    "JOB_NUMBER": "SNWQA31875C8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": "OCT000164FSNWQA31875C8-1",
    "ABC": "A10172435186",
    "PART_NUMBER": "ACQ85638402",
    "PART_STATUS": "PKD",
    "PART_PRICE": "189.33",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA318B947-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000073BSNWQA318B947-1",
    "ABC": "A10211700875",
    "PART_NUMBER": "DA92-00768J",
    "PART_STATUS": "NRQ",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA318CE8B-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000086BSNWQA318CE8B-1",
    "ABC": "A10211729353",
    "PART_NUMBER": "DG94-03730A",
    "PART_STATUS": "INS",
    "PART_PRICE": "222.84",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA3196E87-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000464ASNWQA3196E87-1",
    "ABC": "A10152517608",
    "PART_NUMBER": "BN95-06326F",
    "PART_STATUS": "INS",
    "PART_PRICE": "992",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "HOFFMAN| PEREZ-BORROTO",
    "JOB_NUMBER": "SNWQA3196EC0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000324CSNWQA3196EC0-1",
    "ABC": "A10152519686",
    "PART_NUMBER": "DA92-01196B",
    "PART_STATUS": "INS",
    "PART_PRICE": "161.52",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQA319A690-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000168BSNWQA319A690-1",
    "ABC": "A10172432297",
    "PART_NUMBER": "DC97-14849P",
    "PART_STATUS": "PKD",
    "PART_PRICE": "209.64",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-FSL-HOUSTON",
    "AGENT_NAME": "NATHANIEL| SAMUELS",
    "JOB_NUMBER": "SNWQA319ED81-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000113ASNWQA319ED81-1",
    "ABC": "A10206793020",
    "PART_NUMBER": "WPW10224328",
    "PART_STATUS": "RTS",
    "PART_PRICE": "16.17",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "8712 WESTPARK DR",
    "ADDRESS_CITY": "HOUSTON",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "77063"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "RUBEN| LOPEZ",
    "JOB_NUMBER": "SNWQA319EE69-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000488ASNWQA319EE69-1",
    "ABC": null,
    "PART_NUMBER": "DA97-19112C",
    "PART_STATUS": "SHP",
    "PART_PRICE": "142.47",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "RUBEN| LOPEZ",
    "JOB_NUMBER": "SNWQA319F0DC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000490ASNWQA319F0DC-1",
    "ABC": "A10207698308",
    "PART_NUMBER": "DA97-19112C",
    "PART_STATUS": "INS",
    "PART_PRICE": "142.47",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA31AADB3-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000139ASNWQA31AADB3-1",
    "ABC": "A10187778119",
    "PART_NUMBER": "BN96-39820H",
    "PART_STATUS": "PKD",
    "PART_PRICE": "5.65",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "ADDYSON| FABIAN POLANCO",
    "JOB_NUMBER": "SNWQA31ABF2D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000318BSNWQA31ABF2D-1",
    "ABC": "A10152518108",
    "PART_NUMBER": "BN95-07327D",
    "PART_STATUS": "INS",
    "PART_PRICE": "1528",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA31AFF06-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000303BSNWQA31AFF06-1",
    "ABC": "A10235974020",
    "PART_NUMBER": "DC97-16350T",
    "PART_STATUS": "INS",
    "PART_PRICE": "156.14",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "311 5TH ST",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33458"
  },
  {
    "MARKET": "ASURION-FSL-HOUSTON",
    "AGENT_NAME": "ALLAN| HERRERA",
    "JOB_NUMBER": "SNWQA31B7A3C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000239ASNWQA31B7A3C-1",
    "ABC": "A10206800297",
    "PART_NUMBER": "WH16X26910",
    "PART_STATUS": "INS",
    "PART_PRICE": "10.82",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "8712 WESTPARK DR",
    "ADDRESS_CITY": "HOUSTON",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "77063"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ENDER| GARCIA MARIN",
    "JOB_NUMBER": "SNWQA31B7D53-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000343ASNWQA31B7D53-1",
    "ABC": "A10225292164",
    "PART_NUMBER": "DA32-00006S",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "12.05",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "DELROY| WHITE",
    "JOB_NUMBER": "SNWQA31B9D7E-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000294ASNWQA31B9D7E-1",
    "ABC": "A10242333463",
    "PART_NUMBER": "DG47-00064A",
    "PART_STATUS": "INS",
    "PART_PRICE": "150.1",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "GARRY| LEWIS",
    "JOB_NUMBER": "SNWQA31C2DF4-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "nullBSNWQA31C2DF4-1",
    "ABC": "A10225288953",
    "PART_NUMBER": "DA94-03757A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "51",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "NAHUM| GABRIEL",
    "JOB_NUMBER": "SNWQA31C56CC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000330ASNWQA31C56CC-1",
    "ABC": "A10225289452",
    "PART_NUMBER": "DC97-14486A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "152.67",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "BLUESTREAK-FSL-DAYTONA",
    "AGENT_NAME": "HOWARD| MCKENZIE",
    "JOB_NUMBER": "SNWQA31D075E-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-07T04:00:00.000Z",
    "RESOLUTION_CODE": null,
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN95-06374A",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1115 ENTERPRISE CT",
    "ADDRESS_CITY": "HOLLY HILL",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32117"
  },
  {
    "MARKET": "BLUESTREAK-FSL-DAYTONA",
    "AGENT_NAME": "HOWARD| MCKENZIE",
    "JOB_NUMBER": "SNWQA31D075E-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-07T04:00:00.000Z",
    "RESOLUTION_CODE": null,
    "WSN": "OCT000149ASNWQA31D075E-1",
    "ABC": "A10127444375",
    "PART_NUMBER": "BN96-39820H",
    "PART_STATUS": "PKD",
    "PART_PRICE": "5.65",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1115 ENTERPRISE CT",
    "ADDRESS_CITY": "HOLLY HILL",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32117"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "WADLER| VOIGT",
    "JOB_NUMBER": "SNWQA31E0D26-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000497BSNWQA31E0D26-1",
    "ABC": "A10238594897",
    "PART_NUMBER": "DA81-05595A",
    "PART_STATUS": "INS",
    "PART_PRICE": "22",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "D'ANDRE| BAKER",
    "JOB_NUMBER": "SNWQ93103985-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000083ASNWQ93103985-1",
    "ABC": "A10191360664",
    "PART_NUMBER": "ADD73358349",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "160.04",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5030 KRAFT AVE SE STE D",
    "ADDRESS_CITY": "GRAND RAPIDS",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49512"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "SCOTT| LEVENSON",
    "JOB_NUMBER": "SNWQ9311A997-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000325CSNWQ9311A997-1",
    "ABC": "A10235709531",
    "PART_NUMBER": "BN94-00054F",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQ93122BA1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000083CSNWQ93122BA1-1",
    "ABC": "A10211721331",
    "PART_NUMBER": "BN96-52796A",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DWAYNE| HILTUNEN",
    "JOB_NUMBER": "SNWQ93127155-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000742CSNWQ93127155-1",
    "ABC": "A10213784742",
    "PART_NUMBER": "BN94-15731A",
    "PART_STATUS": "INS",
    "PART_PRICE": "126",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "751 HOME GROVE DR",
    "ADDRESS_CITY": "WINTER GARDEN",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34787"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JAMES| CIRAOLO",
    "JOB_NUMBER": "SNWQ93148E3A-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "OTHER",
    "WSN": "SEP000288BSNWQ93148E3A-1",
    "ABC": "A10166310286",
    "PART_NUMBER": "DA63-06541D",
    "PART_STATUS": "PKD",
    "PART_PRICE": "78.64",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ERIK| FLEITES",
    "JOB_NUMBER": "SNWQ93155539-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000626BSNWQ93155539-1",
    "ABC": "A10225270463",
    "PART_NUMBER": "BN94-17410J",
    "PART_STATUS": "INS",
    "PART_PRICE": "270",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQ93155D20-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN94-16866D",
    "PART_STATUS": "SHP",
    "PART_PRICE": "85.95",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3174 LOWINGWOOD CT",
    "ADDRESS_CITY": "JENISON",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49428"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQ9315821A-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "SEP000165ASNWQ9315821A-1",
    "ABC": "A10191369831",
    "PART_NUMBER": "DC47-00019A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5030 KRAFT AVE SE STE D",
    "ADDRESS_CITY": "GRAND RAPIDS",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49512"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQ9315B1D1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DC97-16350S",
    "PART_STATUS": "DEL",
    "PART_PRICE": "78.22",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15325 LEBEAU LOOP",
    "ADDRESS_CITY": "WINTER GARDEN",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34787"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQ931603ED-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000319DSNWQ931603ED-1",
    "ABC": "A10166979619",
    "PART_NUMBER": "DG94-04349A",
    "PART_STATUS": "DEF",
    "PART_PRICE": "233.04",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQ931603ED-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DG94-04349A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "233.04",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQ931603ED-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DG94-04349A",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA3162309-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000001ASNWQA3162309-1",
    "ABC": "A10163545308",
    "PART_NUMBER": "BN96-53951T",
    "PART_STATUS": "PKD",
    "PART_PRICE": "130.03",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "LANCE| BROOKS",
    "JOB_NUMBER": "SNWQA31679E0-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000263ASNWQA31679E0-1",
    "ABC": "A10202833119",
    "PART_NUMBER": "BN94-00053T",
    "PART_STATUS": "INS",
    "PART_PRICE": "180",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "551 NW 42ND AVE",
    "ADDRESS_CITY": "PLANTATION",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33317"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "JOHN| TORRES",
    "JOB_NUMBER": "SNWQA316A39D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000041ASNWQA316A39D-1",
    "ABC": "A10163759153",
    "PART_NUMBER": "BN94-16871Z",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "NICHOLAS| SMITH",
    "JOB_NUMBER": "SNWQA3170FBB-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000202ASNWQA3170FBB-1",
    "ABC": "A10158808731",
    "PART_NUMBER": "218976447",
    "PART_STATUS": "INS",
    "PART_PRICE": "6.81",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "MICHAEL| FOLKS",
    "JOB_NUMBER": "SNWQA31741B7-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000332ASNWQA31741B7-1",
    "ABC": "A10236608531",
    "PART_NUMBER": "DC97-16350S",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "156.44",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "8487 BOCA RIO DR",
    "ADDRESS_CITY": "BOCA RATON",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33433"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "MICHAEL| FOLKS",
    "JOB_NUMBER": "SNWQA31741B7-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000332BSNWQA31741B7-1",
    "ABC": "A10238582919",
    "PART_NUMBER": "DC97-22745J",
    "PART_STATUS": "INS",
    "PART_PRICE": "141.86",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JAMES| NELSON",
    "JOB_NUMBER": "SNWQA31761EB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000096CSNWQA31761EB-1",
    "ABC": "A10158801542",
    "PART_NUMBER": "WR87X30876",
    "PART_STATUS": "INS",
    "PART_PRICE": "4",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JAMES| NELSON",
    "JOB_NUMBER": "SNWQA31761EB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000096BSNWQA31761EB-1",
    "ABC": "A10158801053",
    "PART_NUMBER": "WR87X30875",
    "PART_STATUS": "INS",
    "PART_PRICE": "21.84",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ENDER| GARCIA MARIN",
    "JOB_NUMBER": "SNWQA317B0FA-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DE94-03942D",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ENDER| GARCIA MARIN",
    "JOB_NUMBER": "SNWQA317B0FA-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000033ASNWQA317B0FA-1",
    "ABC": "A10239944508",
    "PART_NUMBER": "DE94-04028B",
    "PART_STATUS": "INS",
    "PART_PRICE": "48.38",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "TIRSO| MENESES",
    "JOB_NUMBER": "SNWQA317C13D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000396ASNWQA317C13D-1",
    "ABC": "A10225297119",
    "PART_NUMBER": "DD62-00136B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "2.68",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "TIRSO| MENESES",
    "JOB_NUMBER": "SNWQA317C13D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000396DSNWQA317C13D-1",
    "ABC": "A10237817820",
    "PART_NUMBER": "DD33-01002B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "60.92",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2801 FLORIDA AVE APT 413",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33133"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "CONNOR| WALLACE",
    "JOB_NUMBER": "SNWQA317C32B-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN59-01374A",
    "PART_STATUS": "SHP",
    "PART_PRICE": "64.14",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "334 BEVERLY CT",
    "ADDRESS_CITY": "MELBOURNE BEACH",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32951"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "KEVIN| PHILLIPS",
    "JOB_NUMBER": "SNWQA317F155-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000038DSNWQA317F155-1",
    "ABC": "A10166991819",
    "PART_NUMBER": "DA32-10104N",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "ROY| DUITMAN",
    "JOB_NUMBER": "SNWQA3182DB0-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN95-06431A",
    "PART_STATUS": "SHP",
    "PART_PRICE": "288.45",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA318B947-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000073CSNWQA318B947-1",
    "ABC": "A10211716064",
    "PART_NUMBER": "DA81-06006B",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "MANUEL| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA318CB60-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000146ESNWQA318CB60-1",
    "ABC": "A10152748463",
    "PART_NUMBER": "DA97-21806E",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "67.23",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "1453 MUIR CIR",
    "ADDRESS_CITY": "CLERMONT",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34711"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "MANUEL| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA318CB60-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000146ASNWQA318CB60-1",
    "ABC": "A10152745319",
    "PART_NUMBER": "DA97-21806B",
    "PART_STATUS": "INS",
    "PART_PRICE": "171.75",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "1453 MUIR CIR",
    "ADDRESS_CITY": "CLERMONT",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34711"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "MANUEL| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA318CB60-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000146CSNWQA318CB60-1",
    "ABC": "A10152746975",
    "PART_NUMBER": "DA32-10104W",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "14.72",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "1453 MUIR CIR",
    "ADDRESS_CITY": "CLERMONT",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34711"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA3190301-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000296ASNWQA3190301-1",
    "ABC": "A10187791142",
    "PART_NUMBER": "BN95-07297A",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "JASON| ALSTON",
    "JOB_NUMBER": "SNWQA3196C85-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": null,
    "WSN": "OCT000098BSNWQA3196C85-1",
    "ABC": "A10166998764",
    "PART_NUMBER": "BN95-07251A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "464",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "JASON| ALSTON",
    "JOB_NUMBER": "SNWQA3196C85-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": null,
    "WSN": "OCT000098ASNWQA3196C85-1",
    "ABC": "A10166985064",
    "PART_NUMBER": "BN96-52802A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "8.11",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "HOFFMAN| PEREZ-BORROTO",
    "JOB_NUMBER": "SNWQA3196EC0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA97-19028B",
    "PART_STATUS": "UNK",
    "PART_PRICE": "89.05",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "431 HILLTOP DR",
    "ADDRESS_CITY": "LAKE WALES",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33853"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "JOHN| TORRES",
    "JOB_NUMBER": "SNWQA3197FDC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000375BSNWQA3197FDC-1",
    "ABC": "A10213450519",
    "PART_NUMBER": "DE96-00269A",
    "PART_STATUS": "INS",
    "PART_PRICE": "25.5",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "8500 ROSALIND AVE UNIT 2",
    "ADDRESS_CITY": "CAPE CANAVERAL",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32920"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "MARC| DESOUVRE",
    "JOB_NUMBER": "SNWQA319839A-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000052ASNWQA319839A-1",
    "ABC": "A10166313586",
    "PART_NUMBER": "DC93-00634A",
    "PART_STATUS": "INS",
    "PART_PRICE": "31.28",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA319ADC1-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN95-07738A",
    "PART_STATUS": "SHP",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA319ADC1-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000307CSNWQA319ADC1-1",
    "ABC": "A10242341797",
    "PART_NUMBER": "BN95-07738A",
    "PART_STATUS": "INS",
    "PART_PRICE": "568",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA319ADC1-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000307CSNWQA319ADC1-1",
    "ABC": "A10242341797",
    "PART_NUMBER": "BN95-07738A",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "STEVEN| VENNE",
    "JOB_NUMBER": "SNWQA31AC528-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000201ASNWQA31AC528-1",
    "ABC": "A10205888342",
    "PART_NUMBER": "DD93-01013A",
    "PART_STATUS": "INS",
    "PART_PRICE": "166.49",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2780 ARBOR DR",
    "ADDRESS_CITY": "WEST LINN",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97068"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "STEVEN| VENNE",
    "JOB_NUMBER": "SNWQA31AC528-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000201CSNWQA31AC528-1",
    "ABC": "A10211730242",
    "PART_NUMBER": "DD97-00501E",
    "PART_STATUS": "INS",
    "PART_PRICE": "71.05",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA31AD9A6-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-16T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000274CSNWQA31AD9A6-1",
    "ABC": "A10152516619",
    "PART_NUMBER": "BN95-06739A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA31AD9A6-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-16T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000274ASNWQA31AD9A6-1",
    "ABC": "A10152500286",
    "PART_NUMBER": "BN94-16115X",
    "PART_STATUS": "PKD",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQA31AF1BE-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA32-10104N",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "12355 SE YELLOWSTONE ST",
    "ADDRESS_CITY": "DAMASCUS",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97089"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQA31AF1BE-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA82-01415A",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "12355 SE YELLOWSTONE ST",
    "ADDRESS_CITY": "DAMASCUS",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97089"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "STEVEN| VENNE",
    "JOB_NUMBER": "SNWQA31AF786-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000158ASNWQA31AF786-1",
    "ABC": "A10211713908",
    "PART_NUMBER": "DA81-05595A",
    "PART_STATUS": "INS",
    "PART_PRICE": "22",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15291 HENRICI RD",
    "ADDRESS_CITY": "OREGON CITY",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97045"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA31AFF06-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000303ASNWQA31AFF06-1",
    "ABC": "A10239011664",
    "PART_NUMBER": "DC97-19289F",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "54.38",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "311 5TH ST",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33458"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| CURRENS",
    "JOB_NUMBER": "SNWQA31B22C3-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000169ASNWQA31B22C3-1",
    "ABC": "A10211715075",
    "PART_NUMBER": "DA32-10104N",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "12.05",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "24604 NE 236TH AVE",
    "ADDRESS_CITY": "BATTLE GROUND",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98604"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQA31B24B2-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000151ASNWQA31B24B2-1",
    "ABC": "A10211712919",
    "PART_NUMBER": "DC92-02388S",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "143.94",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "16725 SE COHIBA CT",
    "ADDRESS_CITY": "DAMASCUS",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97089"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "MANUEL| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA31B6D94-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000347ASNWQA31B6D94-1",
    "ABC": "A10152508875",
    "PART_NUMBER": "DC97-16350S",
    "PART_STATUS": "INS",
    "PART_PRICE": "156.44",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "MARC| DESOUVRE",
    "JOB_NUMBER": "SNWQA31B7344-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000094BSNWQA31B7344-1",
    "ABC": "A10166320342",
    "PART_NUMBER": "DA32-10109Y",
    "PART_STATUS": "INS",
    "PART_PRICE": "7.67",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "WALTER| ANDERSON",
    "JOB_NUMBER": "SNWQA31B7A11-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000154ASNWQA31B7A11-1",
    "ABC": "A10166994886",
    "PART_NUMBER": "BN96-39820H",
    "PART_STATUS": "PKD",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DANIEL| VALLEJO",
    "JOB_NUMBER": "SNWQA31B9893-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA97-06707D",
    "PART_STATUS": "DEL",
    "PART_PRICE": "72.08",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "10150 REGENT PARK DR",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32825"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "WALTER| ANDERSON",
    "JOB_NUMBER": "SNWQA31B9895-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "OCT000143BSNWQA31B9895-1",
    "ABC": "A10166994053",
    "PART_NUMBER": "DA32-00029N",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "11.78",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA31BED24-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer No Show",
    "WSN": "OCT000211BSNWQA31BED24-1",
    "ABC": "A10211730320",
    "PART_NUMBER": "DA82-02848A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "248.49",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA31BED24-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer No Show",
    "WSN": "OCT000211ASNWQA31BED24-1",
    "ABC": "A10211722819",
    "PART_NUMBER": "DA82-02850A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "249.27",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "WADLER| VOIGT",
    "JOB_NUMBER": "SNWQA31C351A-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "OCT000393ASNWQA31C351A-1",
    "ABC": "A10238584819",
    "PART_NUMBER": "DC92-01848A",
    "PART_STATUS": "RTS",
    "PART_PRICE": "201.41",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQA31C375C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000253ASNWQA31C375C-1",
    "ABC": "A10211729686",
    "PART_NUMBER": "BN96-52802B",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "KENROD| ROBERTSON",
    "JOB_NUMBER": "SNWQA31C6621-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000385ASNWQA31C6621-1",
    "ABC": "A10238583097",
    "PART_NUMBER": "DA92-00282G",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "94.75",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "BRANDON| KENDALL",
    "JOB_NUMBER": "SNWQA31CB45B-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000284ASNWQA31CB45B-1",
    "ABC": "A10187790564",
    "PART_NUMBER": "DE94-03237R",
    "PART_STATUS": "INS",
    "PART_PRICE": "191.62",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "BRANDON| KENDALL",
    "JOB_NUMBER": "SNWQA31CC09B-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000236ASNWQA31CC09B-1",
    "ABC": "A10187786786",
    "PART_NUMBER": "DE96-00269A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "25.5",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQA31CFE59-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": "OCT000055ASNWQA31CFE59-1",
    "ABC": "A10191377675",
    "PART_NUMBER": "DC66-10170B",
    "PART_STATUS": "PKD",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5030 KRAFT AVE SE STE D",
    "ADDRESS_CITY": "GRAND RAPIDS",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49512"
  },
  {
    "MARKET": "BLUESTREAK-FSL-DAYTONA",
    "AGENT_NAME": "HOWARD| MCKENZIE",
    "JOB_NUMBER": "SNWQA31D075E-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-07T04:00:00.000Z",
    "RESOLUTION_CODE": null,
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN95-06739A",
    "PART_STATUS": "OBO",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1115 ENTERPRISE CT",
    "ADDRESS_CITY": "HOLLY HILL",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32117"
  },
  {
    "MARKET": "BLUESTREAK-FSL-DAYTONA",
    "AGENT_NAME": "HOWARD| MCKENZIE",
    "JOB_NUMBER": "SNWQA31D075E-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-07T04:00:00.000Z",
    "RESOLUTION_CODE": null,
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN94-16104Z",
    "PART_STATUS": "SHP",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1115 ENTERPRISE CT",
    "ADDRESS_CITY": "HOLLY HILL",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32117"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA31D18AD-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "OCT000241ASNWQA31D18AD-1",
    "ABC": "A10211728108",
    "PART_NUMBER": "DA92-00606E",
    "PART_STATUS": "PKD",
    "PART_PRICE": "51",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "WADLER| VOIGT",
    "JOB_NUMBER": "SNWQA31D1F5A-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000432ASNWQA31D1F5A-1",
    "ABC": "A10238587542",
    "PART_NUMBER": "DD81-02132A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "38.25",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "ROBERT| BOLDEN",
    "JOB_NUMBER": "SNWQA31D5556-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000071ASNWQA31D5556-1",
    "ABC": "A10191379242",
    "PART_NUMBER": "DD81-02635A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "97.26",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5030 KRAFT AVE SE STE D",
    "ADDRESS_CITY": "GRAND RAPIDS",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49512"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "WADLER| VOIGT",
    "JOB_NUMBER": "SNWQA31E0D26-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000497BSNWQA31E0D26-1",
    "ABC": "A10238595219",
    "PART_NUMBER": "DA97-15217D",
    "PART_STATUS": "INS",
    "PART_PRICE": "77",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "SCOTT| LEVENSON",
    "JOB_NUMBER": "SNWQ9311A997-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000325ESNWQ9311A997-1",
    "ABC": "A10238579620",
    "PART_NUMBER": "BN94-16104Z",
    "PART_STATUS": "RTS",
    "PART_PRICE": "108",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQ93122BA1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000083DSNWQ93122BA1-1",
    "ABC": "A10211727031",
    "PART_NUMBER": "BN95-07297A",
    "PART_STATUS": "INS",
    "PART_PRICE": "824",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "IVENER| MEREUS",
    "JOB_NUMBER": "SNWQ93144EFB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-01T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DC97-16350U",
    "PART_STATUS": "OBO",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JAMES| CIRAOLO",
    "JOB_NUMBER": "SNWQ93148E3A-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "OTHER",
    "WSN": "SEP000288ASNWQ93148E3A-1",
    "ABC": "A10157098820",
    "PART_NUMBER": "DA63-06541D",
    "PART_STATUS": "RTS",
    "PART_PRICE": "78.64",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9314C7DC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000807BSNWQ9314C7DC-1",
    "ABC": "A10238595474",
    "PART_NUMBER": "BN95-06815A",
    "PART_STATUS": "INS",
    "PART_PRICE": "1512",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9314C7DC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": "A10202834697",
    "PART_NUMBER": "BN94-15724B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "0",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "13453 SW 42ND ST",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33330"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ERIK| FLEITES",
    "JOB_NUMBER": "SNWQ93155539-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000626ASNWQ93155539-1",
    "ABC": "A10234901375",
    "PART_NUMBER": "BN94-17445D",
    "PART_STATUS": "REC",
    "PART_PRICE": "124.04",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "5765 SW 28TH ST",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33155"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQ93155D20-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000166ESNWQ93155D20-1",
    "ABC": "A10191397897",
    "PART_NUMBER": "BN94-16865Z",
    "PART_STATUS": "REC",
    "PART_PRICE": "180",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3174 LOWINGWOOD CT",
    "ADDRESS_CITY": "JENISON",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49428"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQ93155D20-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN39-02470A",
    "PART_STATUS": "SHP",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3174 LOWINGWOOD CT",
    "ADDRESS_CITY": "JENISON",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49428"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQ931588C4-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000741BSNWQ931588C4-1",
    "ABC": "A10163775564",
    "PART_NUMBER": "BN95-08571A",
    "PART_STATUS": "REC",
    "PART_PRICE": "1035.1",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "KEVIN| PHILLIPS",
    "JOB_NUMBER": "SNWQ931591E5-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000414ASNWQ931591E5-1",
    "ABC": "A10172434508",
    "PART_NUMBER": "DA91-05436A",
    "PART_STATUS": "DEF",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQ9315B1D1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000661ASNWQ9315B1D1-1",
    "ABC": "A10152739708",
    "PART_NUMBER": "DC31-00080B",
    "PART_STATUS": "REC",
    "PART_PRICE": "140.89",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15325 LEBEAU LOOP",
    "ADDRESS_CITY": "WINTER GARDEN",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34787"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQ9315B1D1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000661BSNWQ9315B1D1-1",
    "ABC": "A10164453619",
    "PART_NUMBER": "DC97-16350S",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "156.44",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15325 LEBEAU LOOP",
    "ADDRESS_CITY": "WINTER GARDEN",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34787"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "BLAKE| KOSTREBA",
    "JOB_NUMBER": "SNWQ9315ED67-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000273BSNWQ9315ED67-1",
    "ABC": "A10211731564",
    "PART_NUMBER": "DA97-21002A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "103.41",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA3162309-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000001CSNWQA3162309-1",
    "ABC": "A10152508531",
    "PART_NUMBER": "BN95-07326M",
    "PART_STATUS": "PKD",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA3162309-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000001BSNWQA3162309-1",
    "ABC": "A10152400520",
    "PART_NUMBER": "BN96-39820K",
    "PART_STATUS": "PKD",
    "PART_PRICE": "8.76",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "JOHN| TORRES",
    "JOB_NUMBER": "SNWQA316A39D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000041BSNWQA316A39D-1",
    "ABC": "A10163772753",
    "PART_NUMBER": "BN96-52744A",
    "PART_STATUS": "INS",
    "PART_PRICE": "8.4",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JAMES| NELSON",
    "JOB_NUMBER": "SNWQA31761EB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000096DSNWQA31761EB-1",
    "ABC": "A10158802042",
    "PART_NUMBER": "WR87X41607",
    "PART_STATUS": "INS",
    "PART_PRICE": "167.97",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ENDER| GARCIA MARIN",
    "JOB_NUMBER": "SNWQA317B0FA-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000033BSNWQA317B0FA-1",
    "ABC": "A10239947819",
    "PART_NUMBER": "DG94-01651C",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "375.18",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "GARRY| LEWIS",
    "JOB_NUMBER": "SNWQA317C056-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000080CSNWQA317C056-1",
    "ABC": "A10239958042",
    "PART_NUMBER": "DA97-12609C",
    "PART_STATUS": "INS",
    "PART_PRICE": "153.27",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "TIRSO| MENESES",
    "JOB_NUMBER": "SNWQA317C13D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000396DSNWQA317C13D-1",
    "ABC": "A10237817908",
    "PART_NUMBER": "DD82-01988A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "77.74",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2801 FLORIDA AVE APT 413",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33133"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "TIRSO| MENESES",
    "JOB_NUMBER": "SNWQA317C13D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000396CSNWQA317C13D-1",
    "ABC": "A10237818242",
    "PART_NUMBER": "DD67-00162A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "6.69",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2801 FLORIDA AVE APT 413",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33133"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "CONNOR| WALLACE",
    "JOB_NUMBER": "SNWQA317C32B-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000206BSNWQA317C32B-1",
    "ABC": "A10242334375",
    "PART_NUMBER": "BN95-07479A",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "KEVIN| PHILLIPS",
    "JOB_NUMBER": "SNWQA317F155-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000038CSNWQA317F155-1",
    "ABC": "A10166981429",
    "PART_NUMBER": "DA97-12609C",
    "PART_STATUS": "INS",
    "PART_PRICE": "153.27",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA318B947-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000073CSNWQA318B947-1",
    "ABC": "A10211716142",
    "PART_NUMBER": "NC1MV72ASR/SM3",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "MANUEL| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA318CB60-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000146FSNWQA318CB60-1",
    "ABC": "A10152748386",
    "PART_NUMBER": "DA97-21806E",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "67.23",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "1453 MUIR CIR",
    "ADDRESS_CITY": "CLERMONT",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34711"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA3190301-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000296BSNWQA3190301-1",
    "ABC": "A10187468731",
    "PART_NUMBER": "BN44-01112A",
    "PART_STATUS": "INS",
    "PART_PRICE": "134.05",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "85 SW 52ND AVE STE 500",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34474"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA3196D7F-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000221ASNWQA3196D7F-1",
    "ABC": "A10242325463",
    "PART_NUMBER": "DD61-00501B",
    "PART_STATUS": "INS",
    "PART_PRICE": "92.58",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "HOFFMAN| PEREZ-BORROTO",
    "JOB_NUMBER": "SNWQA3196EC0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000324BSNWQA3196EC0-1",
    "ABC": "A10152519019",
    "PART_NUMBER": "DA97-19965C",
    "PART_STATUS": "INS",
    "PART_PRICE": "195.64",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQA319A690-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000168ASNWQA319A690-1",
    "ABC": "A10167095920",
    "PART_NUMBER": "DC97-16782E",
    "PART_STATUS": "INS",
    "PART_PRICE": "216",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "10607 HOWERTON AVE",
    "ADDRESS_CITY": "FAIRFAX",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22030"
  },
  {
    "MARKET": "ASURION-FSL-HOUSTON",
    "AGENT_NAME": "NATHANIEL| SAMUELS",
    "JOB_NUMBER": "SNWQA319ED81-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000113BSNWQA319ED81-1",
    "ABC": "A10206793931",
    "PART_NUMBER": "2180353",
    "PART_STATUS": "INS",
    "PART_PRICE": "4.73",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "8712 WESTPARK DR",
    "ADDRESS_CITY": "HOUSTON",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "77063"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA319EDB9-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Parts not delivered",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DC97-16350U",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "RUBEN| LOPEZ",
    "JOB_NUMBER": "SNWQA319F0DC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000490ASNWQA319F0DC-1",
    "ABC": "A10152522975",
    "PART_NUMBER": "DA82-02804A",
    "PART_STATUS": "INS",
    "PART_PRICE": "247.68",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA31A155D-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000158ASNWQA31A155D-1",
    "ABC": "A10186567008",
    "PART_NUMBER": "DA82-02697A",
    "PART_STATUS": "INS",
    "PART_PRICE": "150",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "8869 SE 130TH LOOP",
    "ADDRESS_CITY": "SUMMERFIELD",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34491"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA31A155D-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000158BSNWQA31A155D-1",
    "ABC": "A10187782808",
    "PART_NUMBER": "DA82-02514A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "234.6",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA31A2BD0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000109ASNWQA31A2BD0-1",
    "ABC": "A10187775308",
    "PART_NUMBER": "BN96-39820H",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "5.65",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "ADDYSON| FABIAN POLANCO",
    "JOB_NUMBER": "SNWQA31ABF2D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000318BSNWQA31ABF2D-1",
    "ABC": "A10152518108",
    "PART_NUMBER": "BN95-07327D",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA31AD9A6-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-16T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000274ASNWQA31AD9A6-1",
    "ABC": "A10152500286",
    "PART_NUMBER": "BN94-16115X",
    "PART_STATUS": "PKD",
    "PART_PRICE": "162",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA31AD9A6-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-16T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000274BSNWQA31AD9A6-1",
    "ABC": "A10163798429",
    "PART_NUMBER": "BN96-39820H",
    "PART_STATUS": "PKD",
    "PART_PRICE": "5.65",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA31AD9A6-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-16T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN94-16115X",
    "PART_STATUS": "SHP",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "STEVEN| VENNE",
    "JOB_NUMBER": "SNWQA31AF786-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000158BSNWQA31AF786-1",
    "ABC": "A10205890064",
    "PART_NUMBER": "DA97-20156B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15291 HENRICI RD",
    "ADDRESS_CITY": "OREGON CITY",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97045"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "MANUEL| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA31B1C4D-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "OCT000388ASNWQA31B1C4D-1",
    "ABC": "A10152514897",
    "PART_NUMBER": "DC92-02965B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "202.06",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "EMMANUEL| CARDONA",
    "JOB_NUMBER": "SNWQA31B29B1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000216ASNWQA31B29B1-1",
    "ABC": "A10187785875",
    "PART_NUMBER": "BN96-39820G",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "5.36",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "GARRY| LEWIS",
    "JOB_NUMBER": "SNWQA31B6DE9-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000337ASNWQA31B6DE9-1",
    "ABC": "A10225290831",
    "PART_NUMBER": "DA94-04225A",
    "PART_STATUS": "INS",
    "PART_PRICE": "51",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "EMMANUEL| CARDONA",
    "JOB_NUMBER": "SNWQA31B6E4D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000196CSNWQA31B6E4D-1",
    "ABC": "A10187791308",
    "PART_NUMBER": "BN95-07997A",
    "PART_STATUS": "RTS",
    "PART_PRICE": "568",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "EMMANUEL| CARDONA",
    "JOB_NUMBER": "SNWQA31B6E4D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000196CSNWQA31B6E4D-1",
    "ABC": "A10187791308",
    "PART_NUMBER": "BN95-07997A",
    "PART_STATUS": "RTS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-FSL-HOUSTON",
    "AGENT_NAME": "ALLAN| HERRERA",
    "JOB_NUMBER": "SNWQA31B7A3C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000239ASNWQA31B7A3C-1",
    "ABC": "A10206800375",
    "PART_NUMBER": "WH16X26911",
    "PART_STATUS": "INS",
    "PART_PRICE": "11.32",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "8712 WESTPARK DR",
    "ADDRESS_CITY": "HOUSTON",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "77063"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQA31BB539-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000323BSNWQA31BB539-1",
    "ABC": "A10152518441",
    "PART_NUMBER": "BN95-06755D",
    "PART_STATUS": "INS",
    "PART_PRICE": "488",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "STEVEN| MANN",
    "JOB_NUMBER": "SNWQA31BD63B-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000194BSNWQA31BD63B-1",
    "ABC": "A10187783631",
    "PART_NUMBER": "DC47-00016A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "9.21",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-FSL-HOUSTON",
    "AGENT_NAME": "ALEJANDRO| MANZANO TROVAMALA",
    "JOB_NUMBER": "SNWQA31C2A00-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000225ASNWQA31C2A00-1",
    "ABC": "A10206799973",
    "PART_NUMBER": "8544771",
    "PART_STATUS": "INS",
    "PART_PRICE": "22.89",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "8712 WESTPARK DR",
    "ADDRESS_CITY": "HOUSTON",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "77063"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "GAGE| TATAR",
    "JOB_NUMBER": "SNWQA31C3869-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000315ASNWQA31C3869-1",
    "ABC": "A10158819708",
    "PART_NUMBER": "EBR67466116",
    "PART_STATUS": "INS",
    "PART_PRICE": "174.21",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "NAHUM| GABRIEL",
    "JOB_NUMBER": "SNWQA31C56CC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000330ASNWQA31C56CC-1",
    "ABC": "A10225289520",
    "PART_NUMBER": "DC97-21429D",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "133.28",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "BLAKE| KOSTREBA",
    "JOB_NUMBER": "SNWQA31D18A8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000240CSNWQA31D18A8-1",
    "ABC": "A10150860675",
    "PART_NUMBER": "DE92-03543A",
    "PART_STATUS": "INS",
    "PART_PRICE": "148.36",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3633 NE 17TH AVE",
    "ADDRESS_CITY": "PORTLAND",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97212"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "BLAKE| KOSTREBA",
    "JOB_NUMBER": "SNWQA31D18A8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000240ASNWQA31D18A8-1",
    "ABC": "A10211727786",
    "PART_NUMBER": "DE92-03671A",
    "PART_STATUS": "INS",
    "PART_PRICE": "192.95",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3633 NE 17TH AVE",
    "ADDRESS_CITY": "PORTLAND",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97212"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "WADLER| VOIGT",
    "JOB_NUMBER": "SNWQA31E0D26-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000497BSNWQA31E0D26-1",
    "ABC": "A10238595053",
    "PART_NUMBER": "DA97-13829C",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "83.3",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "BLUESTREAK-FSL-DAYTONA",
    "AGENT_NAME": "LOUIS| AHLHEIM",
    "JOB_NUMBER": "SNWQ93102203-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000152ASNWQ93102203-1",
    "ABC": "A10194225731",
    "PART_NUMBER": "BN44-01054E",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "74.41",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "31 CLUB HOUSE DR",
    "ADDRESS_CITY": "PALM COAST",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32137"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "SCOTT| LEVENSON",
    "JOB_NUMBER": "SNWQ9311A997-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000325FSNWQ9311A997-1",
    "ABC": "A10238590008",
    "PART_NUMBER": "BN95-06739A",
    "PART_STATUS": "RTS",
    "PART_PRICE": "232",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "SCOTT| LEVENSON",
    "JOB_NUMBER": "SNWQ9311A997-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000325FSNWQ9311A997-1",
    "ABC": "A10238590008",
    "PART_NUMBER": "BN95-06739A",
    "PART_STATUS": "RTS",
    "PART_PRICE": "0",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "SCOTT| LEVENSON",
    "JOB_NUMBER": "SNWQ9311A997-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000325ASNWQ9311A997-1",
    "ABC": "A10235681897",
    "PART_NUMBER": "BN96-39820H",
    "PART_STATUS": "RTS",
    "PART_PRICE": "5.65",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "SCOTT| LEVENSON",
    "JOB_NUMBER": "SNWQ9311A997-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000325CSNWQ9311A997-1",
    "ABC": "A10235709531",
    "PART_NUMBER": "BN94-00054F",
    "PART_STATUS": "INS",
    "PART_PRICE": "180",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9313285C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000457BSNWQ9313285C-1",
    "ABC": "A10235693853",
    "PART_NUMBER": "BN96-39821F",
    "PART_STATUS": "INS",
    "PART_PRICE": "4.49",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9313285C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000457DSNWQ9313285C-1",
    "ABC": "A10238590342",
    "PART_NUMBER": "BN95-07732A",
    "PART_STATUS": "RTS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "VENJONY| AMISIAL",
    "JOB_NUMBER": "SNWQ9313285C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000457CSNWQ9313285C-1",
    "ABC": "A10238586064",
    "PART_NUMBER": "BN95-07732A",
    "PART_STATUS": "RTS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JAMES| CIRAOLO",
    "JOB_NUMBER": "SNWQ93148E3A-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "OTHER",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA63-06541D",
    "PART_STATUS": "UNK",
    "PART_PRICE": "-78.64",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQ93155D20-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000166FSNWQ93155D20-1",
    "ABC": "A10191397719",
    "PART_NUMBER": "BN63-19442A",
    "PART_STATUS": "REC",
    "PART_PRICE": "125.08",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3174 LOWINGWOOD CT",
    "ADDRESS_CITY": "JENISON",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49428"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQ93155D20-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000166BSNWQ93155D20-1",
    "ABC": "A10197544219",
    "PART_NUMBER": "BN94-16865Z",
    "PART_STATUS": "PKD",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3174 LOWINGWOOD CT",
    "ADDRESS_CITY": "JENISON",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49428"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "STEVEN| VENNE",
    "JOB_NUMBER": "SNWQ9315B0BA-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000315BSNWQ9315B0BA-1",
    "ABC": "A10211717053",
    "PART_NUMBER": "DC97-16990L",
    "PART_STATUS": "NRQ",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "STEVEN| VENNE",
    "JOB_NUMBER": "SNWQ9315B0BA-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000315ASNWQ9315B0BA-1",
    "ABC": "A10150852675",
    "PART_NUMBER": "DC97-16984C",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "1405 ROSEMONT RD",
    "ADDRESS_CITY": "WEST LINN",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97068"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQ9315B1D1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000661BSNWQ9315B1D1-1",
    "ABC": "A10164453531",
    "PART_NUMBER": "DC96-01361A",
    "PART_STATUS": "REC",
    "PART_PRICE": "20.02",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15325 LEBEAU LOOP",
    "ADDRESS_CITY": "WINTER GARDEN",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34787"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQ9315B1D1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000661BSNWQ9315B1D1-1",
    "ABC": "A10164453463",
    "PART_NUMBER": "DC97-16350T",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "156.14",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15325 LEBEAU LOOP",
    "ADDRESS_CITY": "WINTER GARDEN",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34787"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "JOSEPH| BRADISH",
    "JOB_NUMBER": "SNWQ9315D673-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000297ASNWQ9315D673-1",
    "ABC": "A10166301452",
    "PART_NUMBER": "DC92-02388H",
    "PART_STATUS": "INS",
    "PART_PRICE": "219.28",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQ931603ED-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000319BSNWQ931603ED-1",
    "ABC": "A10166978397",
    "PART_NUMBER": "DG94-03474A",
    "PART_STATUS": "INS",
    "PART_PRICE": "83.97",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQ931603ED-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DG94-03551A",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "HAYDEN| WILLIAMS",
    "JOB_NUMBER": "SNWQ931603ED-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000319ASNWQ931603ED-1",
    "ABC": "A10166959407",
    "PART_NUMBER": "DG94-03551A",
    "PART_STATUS": "INS",
    "PART_PRICE": "195.43",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA3162309-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN94-17292Z",
    "PART_STATUS": "SHP",
    "PART_PRICE": "252",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DWAYNE| HILTUNEN",
    "JOB_NUMBER": "SNWQA31652F9-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000352CSNWQA31652F9-1",
    "ABC": "A10213785320",
    "PART_NUMBER": "BN94-16105Q",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "1151 POST LAKE PL APT 28-307",
    "ADDRESS_CITY": "APOPKA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32703"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "LANCE| BROOKS",
    "JOB_NUMBER": "SNWQA31679E0-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN96-39820H",
    "PART_STATUS": "DEL",
    "PART_PRICE": "5.65",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "551 NW 42ND AVE APT B302",
    "ADDRESS_CITY": "PLANTATION",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33317"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "LANCE| BROOKS",
    "JOB_NUMBER": "SNWQA31679E0-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000263BSNWQA31679E0-1",
    "ABC": "A10238591331",
    "PART_NUMBER": "BN95-07681A",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "ERNST| ANTOINE",
    "JOB_NUMBER": "SNWQA316DDA1-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000016BSNWQA316DDA1-1",
    "ABC": "A10238556108",
    "PART_NUMBER": "DC97-21456D",
    "PART_STATUS": "INS",
    "PART_PRICE": "203.34",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "ERNST| ANTOINE",
    "JOB_NUMBER": "SNWQA316DDA1-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000016ASNWQA316DDA1-1",
    "ABC": "A10235719697",
    "PART_NUMBER": "DC66-00470D",
    "PART_STATUS": "INS",
    "PART_PRICE": "163.36",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DWAYNE| HILTUNEN",
    "JOB_NUMBER": "SNWQA316DF64-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000378BSNWQA316DF64-1",
    "ABC": "A10213785407",
    "PART_NUMBER": "DC97-19289F",
    "PART_STATUS": "INS",
    "PART_PRICE": "54.38",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2858 N HIAWASSEE RD",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32818"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "ROBERT| BOLDEN",
    "JOB_NUMBER": "SNWQA3174DA7-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000004ASNWQA3174DA7-1",
    "ABC": "A10191370496",
    "PART_NUMBER": "DG94-03584B",
    "PART_STATUS": "RTS",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5030 KRAFT AVE SE STE D",
    "ADDRESS_CITY": "GRAND RAPIDS",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49512"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JAMES| NELSON",
    "JOB_NUMBER": "SNWQA31761EB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000096ASNWQA31761EB-1",
    "ABC": "A10159269575",
    "PART_NUMBER": "WR08X10111",
    "PART_STATUS": "REC",
    "PART_PRICE": "22.63",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15154 WISER RD",
    "ADDRESS_CITY": "FORNEY",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75126"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JAMES| NELSON",
    "JOB_NUMBER": "SNWQA31761EB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000096ASNWQA31761EB-1",
    "ABC": "A10159269653",
    "PART_NUMBER": "WR55X24064",
    "PART_STATUS": "REC",
    "PART_PRICE": "25.95",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15154 WISER RD",
    "ADDRESS_CITY": "FORNEY",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75126"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "CONNOR| WALLACE",
    "JOB_NUMBER": "SNWQA318745D-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Parts not delivered",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN59-01312G",
    "PART_STATUS": "SHP",
    "PART_PRICE": "41.33",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "MICHAEL| AGGE",
    "JOB_NUMBER": "SNWQA31875C8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": "OCT000164ASNWQA31875C8-1",
    "ABC": "A10168284429",
    "PART_NUMBER": "ADC73685905",
    "PART_STATUS": "PKD",
    "PART_PRICE": "200.8",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "313 VALEVIEW CT NW",
    "ADDRESS_CITY": "VIENNA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22180"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "MICHAEL| AGGE",
    "JOB_NUMBER": "SNWQA31875C8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": "OCT000164DSNWQA31875C8-1",
    "ABC": "A10172434197",
    "PART_NUMBER": "AGU73748205",
    "PART_STATUS": "PKD",
    "PART_PRICE": "121.51",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "313 VALEVIEW CT NW",
    "ADDRESS_CITY": "VIENNA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22180"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "MICHAEL| AGGE",
    "JOB_NUMBER": "SNWQA31875C8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "AGU73748202",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "MICHAEL| AGGE",
    "JOB_NUMBER": "SNWQA31875C8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "AGU73729908",
    "PART_STATUS": "DEL",
    "PART_PRICE": "131.33",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "313 VALEVIEW CT NW",
    "ADDRESS_CITY": "VIENNA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22180"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "KEVIN| PHILLIPS",
    "JOB_NUMBER": "SNWQA318800E-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000161ASNWQA318800E-1",
    "ABC": "A10166996786",
    "PART_NUMBER": "DA82-02869A",
    "PART_STATUS": "INS",
    "PART_PRICE": "134.39",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "HOFFMAN| PEREZ-BORROTO",
    "JOB_NUMBER": "SNWQA3196EC0-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DA97-19965C",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "JOHN| TORRES",
    "JOB_NUMBER": "SNWQA3197FDC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000375CSNWQA3197FDC-1",
    "ABC": "A10207057508",
    "PART_NUMBER": "DE26-00126A",
    "PART_STATUS": "INS",
    "PART_PRICE": "93.11",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "8500 ROSALIND AVE UNIT 2",
    "ADDRESS_CITY": "CAPE CANAVERAL",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32920"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "JOHN| TORRES",
    "JOB_NUMBER": "SNWQA3197FDC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000375CSNWQA3197FDC-1",
    "ABC": "A10207057353",
    "PART_NUMBER": "OM75P-21-ESGN",
    "PART_STATUS": "INS",
    "PART_PRICE": "93.74",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "8500 ROSALIND AVE UNIT 2",
    "ADDRESS_CITY": "CAPE CANAVERAL",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32920"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA319ADC1-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000307ASNWQA319ADC1-1",
    "ABC": "A10231739642",
    "PART_NUMBER": "BN94-17251R",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "12903 SE HOBE HILLS DR",
    "ADDRESS_CITY": "HOBE SOUND",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33455"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "RUBEN| LOPEZ",
    "JOB_NUMBER": "SNWQA319EE69-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000488ASNWQA319EE69-1",
    "ABC": "A10152522553",
    "PART_NUMBER": "DA82-02804A",
    "PART_STATUS": "INS",
    "PART_PRICE": "247.68",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA31AADB3-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000139BSNWQA31AADB3-1",
    "ABC": "A10187789186",
    "PART_NUMBER": "BN95-06383B",
    "PART_STATUS": "PKD",
    "PART_PRICE": "307.91",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA31AD9A6-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-16T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN96-39820H",
    "PART_STATUS": "SHP",
    "PART_PRICE": "5.65",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "MANUEL| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA31B1C4D-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "OCT000388ASNWQA31B1C4D-1",
    "ABC": "A10152514719",
    "PART_NUMBER": "DC34-00025G",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "93.94",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQA31B24B2-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000151BSNWQA31B24B2-1",
    "ABC": "A10150856142",
    "PART_NUMBER": "DC62-30312J",
    "PART_STATUS": "INS",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "16725 SE COHIBA CT",
    "ADDRESS_CITY": "DAMASCUS",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97089"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "MANUEL| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA31B6D94-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000347ASNWQA31B6D94-1",
    "ABC": "A10152508797",
    "PART_NUMBER": "DC97-16350T",
    "PART_STATUS": "INS",
    "PART_PRICE": "156.14",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "GARRY| LEWIS",
    "JOB_NUMBER": "SNWQA31B6DE9-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000337ASNWQA31B6DE9-1",
    "ABC": "A10225290919",
    "PART_NUMBER": "DA92-00763B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "108.59",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "EMMANUEL| CARDONA",
    "JOB_NUMBER": "SNWQA31B6E4D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000196BSNWQA31B6E4D-1",
    "ABC": "A10187785120",
    "PART_NUMBER": "BN94-17171G",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA31B732D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000279ASNWQA31B732D-1",
    "ABC": "A10242331308",
    "PART_NUMBER": "RA-F36DB3QL/AA",
    "PART_STATUS": "REC",
    "PART_PRICE": "132",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA31B732D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000279BSNWQA31B732D-1",
    "ABC": "A10242336764",
    "PART_NUMBER": "RA-F36DB3QL/AA",
    "PART_STATUS": "INS",
    "PART_PRICE": "132",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "WALTER| ANDERSON",
    "JOB_NUMBER": "SNWQA31B7A11-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000154BSNWQA31B7A11-1",
    "ABC": "A10166996452",
    "PART_NUMBER": "BN95-07156A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "480",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DANIEL| VALLEJO",
    "JOB_NUMBER": "SNWQA31B9893-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": "OCT000327ASNWQA31B9893-1",
    "ABC": "A10152507219",
    "PART_NUMBER": "DA97-07603B",
    "PART_STATUS": "PKD",
    "PART_PRICE": "95.07",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "WALTER| ANDERSON",
    "JOB_NUMBER": "SNWQA31B9895-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "OCT000143ASNWQA31B9895-1",
    "ABC": "A10166992808",
    "PART_NUMBER": "DA82-01415A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQA31BB539-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000323BSNWQA31BB539-1",
    "ABC": "A10152518441",
    "PART_NUMBER": "BN95-06755D",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "KEVIN| PHILLIPS",
    "JOB_NUMBER": "SNWQA31C3125-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000158BSNWQA31C3125-1",
    "ABC": "A10166996520",
    "PART_NUMBER": "BN94-15226H",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "71.9",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DAVID| PACHECO",
    "JOB_NUMBER": "SNWQA31CEC83-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000501ASNWQA31CEC83-1",
    "ABC": "A10152523886",
    "PART_NUMBER": "DC97-16782A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "23.56",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "BLAKE| KOSTREBA",
    "JOB_NUMBER": "SNWQA31D18A8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000240ASNWQA31D18A8-1",
    "ABC": "A10211727608",
    "PART_NUMBER": "DG34-00029A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "287.48",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3633 NE 17TH AVE",
    "ADDRESS_CITY": "PORTLAND",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97212"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "BLAKE| KOSTREBA",
    "JOB_NUMBER": "SNWQA31D18A8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000240BSNWQA31D18A8-1",
    "ABC": "A10150860753",
    "PART_NUMBER": "DA92-00486A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": null,
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "3633 NE 17TH AVE",
    "ADDRESS_CITY": "PORTLAND",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97212"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA31D18AD-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "OCT000241ASNWQA31D18AD-1",
    "ABC": "A10211728020",
    "PART_NUMBER": "DA97-13718C",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "73",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| CURRENS",
    "JOB_NUMBER": "SNWQA31E0397-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000273ASNWQA31E0397-1",
    "ABC": "A10211732631",
    "PART_NUMBER": "DG94-04085A",
    "PART_STATUS": "INS",
    "PART_PRICE": "209.15",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| CURRENS",
    "JOB_NUMBER": "SNWQA31E0397-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000273BSNWQA31E0397-1",
    "ABC": "A10211736519",
    "PART_NUMBER": "DG94-04105A",
    "PART_STATUS": "INS",
    "PART_PRICE": "111.83",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JEFFREY| WIEGMANN",
    "JOB_NUMBER": "SNWQ72FC9D80-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "JUL000335CSNWQ72FC9D80-1",
    "ABC": "A10157961608",
    "PART_NUMBER": "MCR67147702",
    "PART_STATUS": "PKD",
    "PART_PRICE": "33.04",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-FSL-NASHVILLE",
    "AGENT_NAME": "DYLAN| MCINTURF",
    "JOB_NUMBER": "SNWQ930F4F15-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": "SEP000068ASNWQ930F4F15-1",
    "ABC": "A10136567397",
    "PART_NUMBER": "AEB72913935",
    "PART_STATUS": "REC",
    "PART_PRICE": "98.52",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2000 MIDWAY LN",
    "ADDRESS_CITY": "SMYRNA",
    "ADDRESS_PROVINCE": "TN",
    "ADDRESS_POSTAL_CODE": "37167"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "CHRISTOPHER| MURRAY",
    "JOB_NUMBER": "SNWQ930F57B5-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000332BSNWQ930F57B5-1",
    "ABC": "A10211726953",
    "PART_NUMBER": "DA82-03399E",
    "PART_STATUS": "INS",
    "PART_PRICE": "186.03",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "CHRISTOPHER| MURRAY",
    "JOB_NUMBER": "SNWQ930F57B5-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000332ASNWQ930F57B5-1",
    "ABC": "A10211726797",
    "PART_NUMBER": "RA-F36DB3QL/AA",
    "PART_STATUS": "INS",
    "PART_PRICE": "133.76",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "BLUESTREAK-FSL-DAYTONA",
    "AGENT_NAME": "LOUIS| AHLHEIM",
    "JOB_NUMBER": "SNWQ93102203-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000152ASNWQ93102203-1",
    "ABC": "A10194225819",
    "PART_NUMBER": "BN94-15765G",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "0",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "31 CLUB HOUSE DR",
    "ADDRESS_CITY": "PALM COAST",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32137"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "IVENER| MEREUS",
    "JOB_NUMBER": "SNWQ93144EFB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-01T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000819ASNWQ93144EFB-1",
    "ABC": "A10226218808",
    "PART_NUMBER": "DC97-16350U",
    "PART_STATUS": "PKD",
    "PART_PRICE": "154.56",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ERIK| FLEITES",
    "JOB_NUMBER": "SNWQ93155539-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000626CSNWQ93155539-1",
    "ABC": "A10225279542",
    "PART_NUMBER": "BN95-08391A",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQ9315B1D1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "SEP000661CSNWQ9315B1D1-1",
    "ABC": "A10182142031",
    "PART_NUMBER": "DC97-16984C",
    "PART_STATUS": "RTS",
    "PART_PRICE": "172.74",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15325 LEBEAU LOOP",
    "ADDRESS_CITY": "WINTER GARDEN",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34787"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQ9315B1D1-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DC96-01361A",
    "PART_STATUS": "INS",
    "PART_PRICE": "20.02",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "15325 LEBEAU LOOP",
    "ADDRESS_CITY": "WINTER GARDEN",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34787"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "BLAKE| KOSTREBA",
    "JOB_NUMBER": "SNWQ9315ED67-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "SEP000273ASNWQ9315ED67-1",
    "ABC": "A10208192053",
    "PART_NUMBER": "DA97-21002A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "103.41",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "LANCE| BROOKS",
    "JOB_NUMBER": "SNWQA31679E0-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN94-00053T",
    "PART_STATUS": "UNK",
    "PART_PRICE": "-180",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "551 NW 42ND AVE APT B302",
    "ADDRESS_CITY": "PLANTATION",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33317"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "JOHN| TORRES",
    "JOB_NUMBER": "SNWQA316A39D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000041DSNWQA316A39D-1",
    "ABC": "A10207063031",
    "PART_NUMBER": "BN59-01360A",
    "PART_STATUS": "INS",
    "PART_PRICE": "57.16",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "245 S MARCO WAY",
    "ADDRESS_CITY": "SATELLITE BEACH",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32937"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "JOHN| TORRES",
    "JOB_NUMBER": "SNWQA316A39D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000041CSNWQA316A39D-1",
    "ABC": "A10163795864",
    "PART_NUMBER": "BN95-07271A",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "ERNST| ANTOINE",
    "JOB_NUMBER": "SNWQA316DDA1-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000016CSNWQA316DDA1-1",
    "ABC": "A10238578485",
    "PART_NUMBER": "DC97-21594C",
    "PART_STATUS": "INS",
    "PART_PRICE": "219.15",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "MICHAEL| FOLKS",
    "JOB_NUMBER": "SNWQA31741B7-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000332ASNWQA31741B7-1",
    "ABC": "A10236608619",
    "PART_NUMBER": "DC97-16350T",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "156.14",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "8487 BOCA RIO DR",
    "ADDRESS_CITY": "BOCA RATON",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33433"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "JAMES| NELSON",
    "JOB_NUMBER": "SNWQA31761EB-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000096DSNWQA31761EB-1",
    "ABC": "A10158802386",
    "PART_NUMBER": "WR97X32111",
    "PART_STATUS": "INS",
    "PART_PRICE": "19.83",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "GARRY| LEWIS",
    "JOB_NUMBER": "SNWQA317C056-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000080BSNWQA317C056-1",
    "ABC": "A10239955075",
    "PART_NUMBER": "DA82-01415A",
    "PART_STATUS": "INS",
    "PART_PRICE": "16.62",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "ROY| DUITMAN",
    "JOB_NUMBER": "SNWQA3181FF9-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000114ASNWQA3181FF9-1",
    "ABC": "A10211706407",
    "PART_NUMBER": "DA82-02692M",
    "PART_STATUS": "INS",
    "PART_PRICE": "205.63",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "MICHAEL| AGGE",
    "JOB_NUMBER": "SNWQA31875C8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": "OCT000164CSNWQA31875C8-1",
    "ABC": "A10172432942",
    "PART_NUMBER": "ADC73669601",
    "PART_STATUS": "PKD",
    "PART_PRICE": "200.8",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "MICHAEL| AGGE",
    "JOB_NUMBER": "SNWQA31875C8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "ADC73685905",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "MICHAEL| AGGE",
    "JOB_NUMBER": "SNWQA31875C8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "MCK66703705",
    "PART_STATUS": "CAN",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "MICHAEL| AGGE",
    "JOB_NUMBER": "SNWQA31875C8-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": "OCT000164ESNWQA31875C8-1",
    "ABC": "A10172434842",
    "PART_NUMBER": "AGL73954421",
    "PART_STATUS": "PKD",
    "PART_PRICE": "121.51",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA318B947-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000073DSNWQA318B947-1",
    "ABC": "A10211716485",
    "PART_NUMBER": "DA97-13253B",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "143.1",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA3190301-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN94-16871Z",
    "PART_STATUS": "INS",
    "PART_PRICE": "0",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "85 SW 52ND AVE STE 500",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34474"
  },
  {
    "MARKET": "BLUESTREAK-FSL-DAYTONA",
    "AGENT_NAME": "LOUIS| AHLHEIM",
    "JOB_NUMBER": "SNWQA319134C-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000150ASNWQA319134C-1",
    "ABC": "A10127444608",
    "PART_NUMBER": "DG44-01002A",
    "PART_STATUS": "INS",
    "PART_PRICE": "134.68",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1115 ENTERPRISE CT",
    "ADDRESS_CITY": "HOLLY HILL",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32117"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA3196D7F-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000221ASNWQA3196D7F-1",
    "ABC": "A10242325531",
    "PART_NUMBER": "DD97-00572A",
    "PART_STATUS": "INS",
    "PART_PRICE": "10.98",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "JOHN| TORRES",
    "JOB_NUMBER": "SNWQA3197FDC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000375ASNWQA3197FDC-1",
    "ABC": "A10213449064",
    "PART_NUMBER": "OM75P-21-ESGN",
    "PART_STATUS": "INS",
    "PART_PRICE": "93.74",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "8500 ROSALIND AVE UNIT 2",
    "ADDRESS_CITY": "CAPE CANAVERAL",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32920"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "JOHN| TORRES",
    "JOB_NUMBER": "SNWQA3197FDC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000375CSNWQA3197FDC-1",
    "ABC": "A10207057430",
    "PART_NUMBER": "DE92-03624F",
    "PART_STATUS": "INS",
    "PART_PRICE": "97.12",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "8500 ROSALIND AVE UNIT 2",
    "ADDRESS_CITY": "CAPE CANAVERAL",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32920"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA319ADC1-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN95-07738A",
    "PART_STATUS": "SHP",
    "PART_PRICE": "568",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "NIND-FSL-JUPITER",
    "AGENT_NAME": "HUBERT| ANTENOR",
    "JOB_NUMBER": "SNWQA319ADC1-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000307BSNWQA319ADC1-1",
    "ABC": "A10242335286",
    "PART_NUMBER": "BN96-52802A",
    "PART_STATUS": "INS",
    "PART_PRICE": "8.11",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "14924 CORPORATE RD S",
    "ADDRESS_CITY": "JUPITER",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33478"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "BLAKE| KOSTREBA",
    "JOB_NUMBER": "SNWQA319B995-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000109ASNWQA319B995-1",
    "ABC": "A10211704597",
    "PART_NUMBER": "DC97-21472P",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "142.34",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "4821 SE 60TH AVE",
    "ADDRESS_CITY": "PORTLAND",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97206"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "PEDRO| VAZQUEZ LOPEZ",
    "JOB_NUMBER": "SNWQA319EDB9-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Parts not delivered",
    "WSN": "OCT000237ASNWQA319EDB9-1",
    "ABC": "A10163794120",
    "PART_NUMBER": "DC97-16350U",
    "PART_STATUS": "PKD",
    "PART_PRICE": "38.64",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "RUBEN| LOPEZ",
    "JOB_NUMBER": "SNWQA319F0DC-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000490ASNWQA319F0DC-1",
    "ABC": "A10152522897",
    "PART_NUMBER": "DA82-02807B",
    "PART_STATUS": "INS",
    "PART_PRICE": "218.52",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA31A155D-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000158ASNWQA31A155D-1",
    "ABC": "A10186567186",
    "PART_NUMBER": "DA97-08680G",
    "PART_STATUS": "INS",
    "PART_PRICE": "99.24",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "8869 SE 130TH LOOP",
    "ADDRESS_CITY": "SUMMERFIELD",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34491"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DANIEL| VALLEJO",
    "JOB_NUMBER": "SNWQA31A20CB-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000287ASNWQA31A20CB-1",
    "ABC": "A10153405975",
    "PART_NUMBER": "DA97-19965C",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "195.64",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2974 ASHLAND LN S",
    "ADDRESS_CITY": "KISSIMMEE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34741"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DANIEL| VALLEJO",
    "JOB_NUMBER": "SNWQA31A20CB-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000287BSNWQA31A20CB-1",
    "ABC": "A10153405220",
    "PART_NUMBER": "DA97-19028B",
    "PART_STATUS": "INS",
    "PART_PRICE": "89.05",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "2974 ASHLAND LN S",
    "ADDRESS_CITY": "KISSIMMEE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34741"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "GERSON| ESPINOZA CHAVEZ",
    "JOB_NUMBER": "SNWQA31AB605-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000064BSNWQA31AB605-1",
    "ABC": "A10166323564",
    "PART_NUMBER": "BN95-06755D",
    "PART_STATUS": "INS",
    "PART_PRICE": "488",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "GERSON| ESPINOZA CHAVEZ",
    "JOB_NUMBER": "SNWQA31AB605-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000064ASNWQA31AB605-1",
    "ABC": "A10166316631",
    "PART_NUMBER": "BN96-39821F",
    "PART_STATUS": "INS",
    "PART_PRICE": "4.49",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQA31AF1BE-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000237ASNWQA31AF1BE-1",
    "ABC": "A10211727297",
    "PART_NUMBER": "DA97-13718C",
    "PART_STATUS": "INS",
    "PART_PRICE": "73",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "12355 SE YELLOWSTONE ST",
    "ADDRESS_CITY": "DAMASCUS",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97089"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| GALINDO",
    "JOB_NUMBER": "SNWQA31B259F-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000238ASNWQA31B259F-1",
    "ABC": "A10211727375",
    "PART_NUMBER": "DA97-07603B",
    "PART_STATUS": "INS",
    "PART_PRICE": "95.07",
    "SHIP_TO": "CU",
    "ADDRESS_LINE_1": "7430 RIDGEWOOD DR",
    "ADDRESS_CITY": "GLADSTONE",
    "ADDRESS_PROVINCE": "OR",
    "ADDRESS_POSTAL_CODE": "97027"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "EMMANUEL| CARDONA",
    "JOB_NUMBER": "SNWQA31B6E4D-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000196ASNWQA31B6E4D-1",
    "ABC": "A10187782642",
    "PART_NUMBER": "BN96-39823F",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "8.35",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "BLUESTREAK-FSL-FORT MYERS",
    "AGENT_NAME": "MARC| DESOUVRE",
    "JOB_NUMBER": "SNWQA31B7344-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000094ASNWQA31B7344-1",
    "ABC": "A10166319864",
    "PART_NUMBER": "DA32-00029R",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "7.93",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3080 WAREHOUSE RD UNIT A",
    "ADDRESS_CITY": "FORT MYERS",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33916"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ENDER| GARCIA MARIN",
    "JOB_NUMBER": "SNWQA31B7D53-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000343ASNWQA31B7D53-1",
    "ABC": "A10225292242",
    "PART_NUMBER": "DA32-00006Q",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "12.05",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "NIND-FSL-ALEXANDRIA",
    "AGENT_NAME": "WALTER| ANDERSON",
    "JOB_NUMBER": "SNWQA31B9895-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "OCT000143BSNWQA31B9895-1",
    "ABC": "A10166994131",
    "PART_NUMBER": "DA97-06317A",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "56.14",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5751 GENERAL WASHINGTON DR STE C",
    "ADDRESS_CITY": "ALEXANDRIA",
    "ADDRESS_PROVINCE": "VA",
    "ADDRESS_POSTAL_CODE": "22312"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "STEVEN| MANN",
    "JOB_NUMBER": "SNWQA31BD63B-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": "OCT000194BSNWQA31BD63B-1",
    "ABC": "A10187783553",
    "PART_NUMBER": "DC32-00008A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "104.58",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "NIND-FSL-OCALA",
    "AGENT_NAME": "PAXTON| CLINE",
    "JOB_NUMBER": "SNWQA31BE4F2-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000301ASNWQA31BE4F2-1",
    "ABC": "A10187792053",
    "PART_NUMBER": "DD82-01594B",
    "PART_STATUS": "INS",
    "PART_PRICE": "155.85",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1725 SW 12TH AVE",
    "ADDRESS_CITY": "OCALA",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "34471"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "ROY| DUITMAN",
    "JOB_NUMBER": "SNWQA31C137A-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000248ASNWQA31C137A-1",
    "ABC": "A10211728931",
    "PART_NUMBER": "DC97-21429D",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "133.28",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "KENROD| ROBERTSON",
    "JOB_NUMBER": "SNWQA31C6621-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000385BSNWQA31C6621-1",
    "ABC": "A10238584575",
    "PART_NUMBER": "DA97-02965E",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "128.16",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "KENROD| ROBERTSON",
    "JOB_NUMBER": "SNWQA31C6621-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000385CSNWQA31C6621-1",
    "ABC": "A10238587474",
    "PART_NUMBER": "DA97-06317G",
    "PART_STATUS": "NRQ",
    "PART_PRICE": "68.71",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DEANDRE| PEART",
    "JOB_NUMBER": "SNWQA31C6EE9-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer No Show",
    "WSN": "OCT000445ASNWQA31C6EE9-1",
    "ABC": "A10153147331",
    "PART_NUMBER": "DD82-01588A",
    "PART_STATUS": "PKD",
    "PART_PRICE": "84.64",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "ASURION-FSL-DALLAS TX",
    "AGENT_NAME": "TA'MIA| SMITH",
    "JOB_NUMBER": "SNWQA31CDE88-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer No Show",
    "WSN": "OCT000283BSNWQA31CDE88-1",
    "ABC": "A10158817153",
    "PART_NUMBER": "WD21X24676",
    "PART_STATUS": "PKD",
    "PART_PRICE": "109.24",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1861 VALLEY VIEW LN STE 140",
    "ADDRESS_CITY": "FARMERS BRANCH",
    "ADDRESS_PROVINCE": "TX",
    "ADDRESS_POSTAL_CODE": "75234"
  },
  {
    "MARKET": "ASURION-ORLANDO-MA",
    "AGENT_NAME": "DAVID| PACHECO",
    "JOB_NUMBER": "SNWQA31CEC83-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Additional Parts Needed",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DC97-14486A",
    "PART_STATUS": "INP",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "2900 TITAN ROW STE 114",
    "ADDRESS_CITY": "ORLANDO",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32809"
  },
  {
    "MARKET": "NIND-FSL-GRAND RAPIDS-MA",
    "AGENT_NAME": "JONATHAN| LOWRANCE",
    "JOB_NUMBER": "SNWQA31CFE59-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Requested / More Time Needed",
    "WSN": "OCT000055ASNWQA31CFE59-1",
    "ABC": "A10191377831",
    "PART_NUMBER": "DC31-00080B",
    "PART_STATUS": "PKD",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "5030 KRAFT AVE SE STE D",
    "ADDRESS_CITY": "GRAND RAPIDS",
    "ADDRESS_PROVINCE": "MI",
    "ADDRESS_POSTAL_CODE": "49512"
  },
  {
    "MARKET": "BLUESTREAK-FSL-DAYTONA",
    "AGENT_NAME": "HOWARD| MCKENZIE",
    "JOB_NUMBER": "SNWQA31D075E-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-07T04:00:00.000Z",
    "RESOLUTION_CODE": null,
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "BN94-16104Z",
    "PART_STATUS": "SHP",
    "PART_PRICE": "108",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1115 ENTERPRISE CT",
    "ADDRESS_CITY": "HOLLY HILL",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "32117"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "JEFFREY| MCCOY",
    "JOB_NUMBER": "SNWQA31D18AD-1",
    "JOB_STATUS": "CAN",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Customer Declined Repair",
    "WSN": "OCT000241BSNWQA31D18AD-1",
    "ABC": "A10211729764",
    "PART_NUMBER": "DA82-02367A",
    "PART_STATUS": "PKD",
    "PART_PRICE": null,
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-FSL-PORTLAND MA",
    "AGENT_NAME": "MICHAEL| CURRENS",
    "JOB_NUMBER": "SNWQA31E0397-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": null,
    "ABC": null,
    "PART_NUMBER": "DG94-04105A",
    "PART_STATUS": "CAN",
    "PART_PRICE": "111.83",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "9106 NE HIGHWAY 99 STE H",
    "ADDRESS_CITY": "VANCOUVER",
    "ADDRESS_PROVINCE": "WA",
    "ADDRESS_POSTAL_CODE": "98665"
  },
  {
    "MARKET": "ASURION-MA-SOUTH MIAMI",
    "AGENT_NAME": "ENDER| GARCIA MARIN",
    "JOB_NUMBER": "SNWQA31E03FF-1",
    "JOB_STATUS": "ACC",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000387ASNWQA31E03FF-1",
    "ABC": "A10225295708",
    "PART_NUMBER": "BN96-49483A",
    "PART_STATUS": "INS",
    "PART_PRICE": "206.01",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "1000 PARK CENTRE BLVD STE 111",
    "ADDRESS_CITY": "MIAMI",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33169"
  },
  {
    "MARKET": "ASURION-FSL-FORT LAUDERDALE",
    "AGENT_NAME": "LANCE| BROOKS",
    "JOB_NUMBER": "SNWQA31E1203-1",
    "JOB_STATUS": "CMP",
    "SCHEDULED_SERVICE_DATE": "2022-11-08T04:00:00.000Z",
    "RESOLUTION_CODE": "Repair Complete",
    "WSN": "OCT000494ASNWQA31E1203-1",
    "ABC": "A10226218986",
    "PART_NUMBER": "DC97-16350U",
    "PART_STATUS": "INS",
    "PART_PRICE": "154.56",
    "SHIP_TO": "SP",
    "ADDRESS_LINE_1": "3700 HACIENDA BLVD STE G",
    "ADDRESS_CITY": "DAVIE",
    "ADDRESS_PROVINCE": "FL",
    "ADDRESS_POSTAL_CODE": "33314"
  }
]
