import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import { getClientId, getClientName } from '../../../../utilities/clientUtility';
import { splitWsnInfo, removeLeadingZeros } from '../../../../utilities/genericUtility';
import {
  dashboardApiCall,
  getOrders,
  selectClientForWorkflow,
  updateTotalCount
} from '../../../../actions/orderActions';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import WarningIcon from '../../../../icons/warning.png';
import './Confirmation.scss';

const DoneInfo = ({ kitReferenceNumber }) => (
  <div className="unabletolocate-done-info">
    <img src={WarningIcon} alt="" />
    <h1 className="unabletolocate-done-info-h1">
      Job {kitReferenceNumber}
      <br />
      is in progress
    </h1>
  </div>
);

const WsnList = ({ wsnList, unscannedWsnList, kitReferenceNumber }) => (
  <div className="unabletolocate-done-wsn-list">
    <p className="unabletolocate-done-wsn-list-p">This job contains WSNs:</p>
    <p className="unabletolocate-done-wsn-list-p-bold">
      {wsnList.map(
        (wsnItem, idx) =>
          splitWsnInfo(wsnItem.wsn, kitReferenceNumber).wsnMonth +
          removeLeadingZeros(splitWsnInfo(wsnItem.wsn, kitReferenceNumber).wsnSerialNum) +
          splitWsnInfo(wsnItem.wsn, kitReferenceNumber).wsnSerialAlpha +
          (idx === wsnList.length - 1 ? '' : ', ')
      )}
    </p>
    <p className="unabletolocate-done-wsn-list-p">
      You didn't scan:
      <span className="unabletolocate-undone-wsn-list-p-bold">
        {unscannedWsnList.map(
          (wsnItem, idx) =>
            splitWsnInfo(wsnItem, kitReferenceNumber).wsnMonth +
            removeLeadingZeros(splitWsnInfo(wsnItem, kitReferenceNumber).wsnSerialNum) +
            splitWsnInfo(wsnItem, kitReferenceNumber).wsnSerialAlpha +
            (idx === unscannedWsnList.length - 1 ? '' : ', ')
        )}
      </span>
    </p>
  </div>
);

class Confirmation extends Component {
  state = {
    currentCarrierCount: -1,
    otherCarrierName: ''
  };

  componentDidMount = async () => {
    await this.getDataOnLoad();
  };

  getDataOnLoad = async () => {
    let sko = this.props.serviceKitOrders;
    if (!this.props.wsconnected) {
      const res = await dashboardApiCall();
      sko = res.serviceKitOrders;
    }
    const remainOrder = sko.filter(
      order => order.serviceKitOrderStatusType === this.props.bucketType
    )[0];
    const orderToPack = sko.filter(order => order.serviceKitOrderStatusType === 'OrdersToPack')[0];
    const clientData = orderToPack.serviceKitOrderClientCounts.filter(
      order => order.externalClientId === this.props.selectedClient.externalClientId
    )[0];
    const otherCarrierData = orderToPack.serviceKitOrderClientCounts.filter(
      order =>
        order.externalClientId !== this.props.selectedClient.externalClientId && order.count > 0
    );
    this.setState({
      currentCarrierCount: clientData ? clientData.count : 0,
      otherCarrierName: otherCarrierData[0]
        ? getClientName(otherCarrierData[0].externalClientId)
        : null,
      remainOrder: remainOrder
    });
    if (!this.props.wsconnected)
      await this.props.updateTotalCount(parseInt(orderToPack.serviceKitDisplayRows.length));
  };

  handleClick = async () => {
    const { remainOrder } = this.state;
    if (this.props.bucketType === 'Unfinished') {
      const nextorder = remainOrder.serviceKitDisplayRows.filter(
        order => order.kitReferenceNumber !== this.props.kitInfo.kitReferenceNumber
      )[0];
      if (nextorder) {
        await this.props.setAsPacked(this.props.bucketType, nextorder);
        if (this.props.processingStarted) {
          this.props.resetCurrentFlow();
          this.props.closeUnableToLocateShipment();
        }
      } else await this.props.closeWorkflow();
    } else if (this.props.bucketType === 'OrdersToPack') {
      if (this.props.orderToPack > 0) {
        if (!this.props.wsconnected) await this.props.getOrders(false);
        if (this.state.currentCarrierCount === 0)
          await this.props.selectClientForWorkflow(getClientId(this.state.otherCarrierName));
        await this.props.setAsPacked();
        if (this.props.processingStarted) {
          this.props.resetCurrentFlow();
          this.props.closeUnableToLocateShipment();
        }
      } else await this.props.closeWorkflow();
    }
  };

  filterUnscannedBarcodes = wsnList => {
    let unscannedBarcodes = [];

    for (let idx = 0; idx < wsnList.length; idx++) {
      if (wsnList[idx].status !== 'KITTED') {
        unscannedBarcodes.push(wsnList[idx].wsn);
      }
    }
    return unscannedBarcodes;
  };

  render() {
    const { remainOrder } = this.state;
    const jobCount =
      this.props.bucketType === 'Unfinished' && remainOrder && remainOrder.serviceKitDisplayRows
        ? remainOrder && remainOrder.serviceKitDisplayRows.length - 1
        : remainOrder && remainOrder.serviceKitDisplayRows.length;
    const infoContent = (
      <div className="info-content-wsn">
        <p className="info-place-shipment-text">
          You can place the completed shipments on to the Ready to Pickup rack.
        </p>
        <p className="info-jobs-left-text">
          You have <span className="info-jobs-left-text-bold">{jobCount} more orders</span> to kit.
        </p>
      </div>
    );
    const unScannedWsns = this.filterUnscannedBarcodes(this.props.scanWsnList);
    const buttonLabel =
      (this.props.bucketType === 'Unfinished' &&
        remainOrder &&
        remainOrder.serviceKitDisplayRows.length > 1) ||
      (this.props.bucketType === 'OrdersToPack' &&
        remainOrder &&
        remainOrder.serviceKitDisplayRows.length > 0)
        ? 'Next Order'
        : 'Complete';
    return (
      <div className="workflow-main">
        <CSSTransitionGroup
          transitionName="workflow-step"
          transitionAppear={true}
          transitionAppearTimeout={5000}
          transitionEnterTimeout={5000}
          transitionLeaveTimeout={3000}
        >
          {this.state.currentCarrierCount !== -1 && (
            <div className="workflow-step">
              <div className="workflow-step__main">
                <div className="workflow-step__messaging confirmation">
                  <DoneInfo kitReferenceNumber={this.props.kitInfo.kitReferenceNumber} />
                  <WsnList
                    wsnList={this.props.scanWsnList}
                    unscannedWsnList={unScannedWsns}
                    kitReferenceNumber={this.props.kitInfo.kitReferenceNumber}
                  />
                  {infoContent}
                </div>
              </div>
              <div className="workflow-step__footer">
                <PrimaryButton text={buttonLabel} onClick={this.handleClick.bind(this)} />
              </div>
            </div>
          )}
        </CSSTransitionGroup>
        <div className="dashboard-link" onClick={() => this.props.closeWorkflow()}>
          <span>GO TO DASHBOARD</span>
        </div>
      </div>
    );
  }
}

Confirmation.defaultProps = {
  kitInfo: {},
  orderToPack: -1
};

Confirmation.propTypes = {
  getOrders: PropTypes.func,
  kitInfo: PropTypes.object,
  orderToPack: PropTypes.number,
  processingStarted: PropTypes.bool
};

const mapStateToProps = ({ order, serviceKit, websocket, scanWsn }) => {
  const { orderToPack, selectedClient, serviceKitDisplayRows } = order;
  const { processingServiceKit, processingStarted } = serviceKit;
  return {
    kitInfo: processingServiceKit,
    orderToPack,
    processingStarted,
    selectedClient,
    serviceKitOrders: serviceKitDisplayRows,
    wsconnected: websocket.connected,
    scanWsnList: scanWsn.processingScanWsnList
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrders,
      selectClientForWorkflow,
      updateTotalCount
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);
