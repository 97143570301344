import React, { Component } from 'react';
import './App.scss';
import classNames from 'classnames';
import washing_machine from './../../icons/washing_machine.svg';
import receiving_box from './../../icons/receive_box_check.svg';
import returns_box from './../../icons/returns_box.svg';
import AppDashboard from './../AppDashboard/AppDashboard';
import ReceivingParts from '../Dashboard/MenuContent/ReceivingParts/ReceivingParts';
import RmaScreen from '../RmaScreen/RmaScreen';
import ModalRouter from '../Modal/ModalRouter/ModalRouter';
import { userInfo } from '../../utilities/apiUtitlity';
import { updateCurrentSession, updateSessionId } from '../../utilities/sessionUtility';
import { getUserData } from '../../actions/receiveActions';
import { generateCorrelationID } from '../../utilities/genericUtility';
import { FlowTypes } from '../../utilities/enums';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardScreen: true,
      receiveInventory: false,
      majorAppliance: false,
      rmaScreen: false,
      modal: false
    };
    updateSessionId()
    const url = new URL(window.location.href);
    userInfo.token = url.searchParams.get('t');
    userInfo.refreshToken = url.searchParams.get('r');
    updateCurrentSession(this.sessionExpired.bind(this));
    this.backToFSLDashboard = this.backToFSLDashboard.bind(this)
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    this.getuserinfo()
  }

  async getuserinfo() {
    generateCorrelationID(userInfo.companyId, FlowTypes.FSL_DASHBOARD);
    const useinfo = await getUserData();
    userInfo.defaultUserLocationId =
      useinfo.userInfoResponse.userInfoList[0].userInfo.defaultUserLocationId.toString();
  }

  sessionExpired() {
    sessionStorage.clear();
    this.openModal('TokenExpired');
  }

  backToFSLDashboard() {
    this.setState({
      majorAppliance: false,
      receiveInventory: false,
      dashboardScreen: true,
      rmaScreen: false
    })
  }

  openModal = newModal => {
    this.setState({
      modal: newModal
    });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  closeWindow() {
    // ==UserScript==
    // @grant       GM_addStyle
    // ==/UserScript==
    window.open(window.location, '_self').close();
  }

  render() {
    const isModalActive = this.state.modal;

    const dashboarMainScreen = (
      <div className='dashboard-selection-content'>
        <div className='dashboard-selection-content_header'>
          <label className='dashboard-selection-header_label'>FSL Dashboard</label>
        </div>
        <div className='grid-app-container_main'>
          <div className='grid-app-container_main_label'>
            <label className='grid-app-container_main_label_heading'>What do you want to do ?</label>
          </div>
          <div className='grid-app-container'>
            <div className='grid-app-container-body'>
              <label className='grid-app-container-body_label'>Receive Inventory</label>
              <button className='dashboard_selection_button' onClick={() => {
                this.setState({
                  receiveInventory: true,
                  dashboardScreen: false,
                  majorAppliance: false,
                  rmaScreen: false
                })
              }}>
                <img className='dashboard_image_selection' src={receiving_box} alt="Receiving_Items" />
              </button>
              <label className={classNames('grid-app-container-body_label', 'grid-app-container-body_label_footer')}>Major Appliance</label>
            </div>
            <div className='grid-app-container-body'>
              <label className='grid-app-container-body_label'>Pack Shipments (Kitting)</label>
              <button className='dashboard_selection_button' onClick={() => {
                this.setState({
                  majorAppliance: true,
                  dashboardScreen: false,
                  receiveInventory: false,
                  rmaScreen: false
                })
              }}>
                <img className='dashboard_image_selection' src={washing_machine} alt="Major_Appliance" />
              </button>
              <label className={classNames('grid-app-container-body_label', 'grid-app-container-body_label_footer')}>Major Appliance</label>
            </div>
            <div className='grid-app-container-body'>
              <label className='grid-app-container-body_label'>Returns</label>
              <button className='dashboard_selection_button' onClick={() => {
                this.setState({
                  rmaScreen: true,
                  receiveInventory: false,
                  dashboardScreen: false,
                  majorAppliance: false,
                })
              }}>
                <img className='dashboard_image_selection' src={returns_box} alt="Receiving_Items" />
              </button>
              <label className={classNames('grid-app-container-body_label', 'grid-app-container-body_label_footer')}>Major Appliance</label>
            </div>
          </div>
        </div>
        {isModalActive ? (
          <ModalRouter
            closeModal={this.closeModal}
            closeWindow={this.closeWindow}
            modal={this.state.modal}
          />
        ) : null}
      </div>
    );
    return (
      <div className="App">
        {
          this.state.dashboardScreen ? dashboarMainScreen :
          this.state.majorAppliance ? <AppDashboard backToFSLDashboard={this.backToFSLDashboard} /> :
          this.state.receiveInventory ? <ReceivingParts backToFSLDashboard={this.backToFSLDashboard} /> :
          this.state.rmaScreen ? <RmaScreen backToFSLDashboard={this.backToFSLDashboard} /> :
          <></>
        }
      </div>
    );
  }
}


export default App;
