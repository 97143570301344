import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import { returnABC } from '../../../../actions/orderActions';
import {
  incrementScannedCountAction,
  resetScannedCountAction
} from '../../../../actions/scannedPartsAction';
import Col from '../../../Common/Column';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import example from './ABCbarcodeExample.png';
import './ReturnABC.scss';

class ReturnABC extends PureComponent {
  goToNextStep() {
    if (this.props.returnAbcCompleted) {
      // increment scanned parts count
      this.props.incrementScannedCountAction();
      // go to next step
      this.props.nextStep(this.props.step);
    } else setTimeout(() => this.goToNextStep(), 500);
  }

  closeWorkflow() {
    // reset scannedCount
    this.props.resetScannedCountAction();
    // close workflow
    this.props.closeWorkflow();
  }

  render() {
    return (
      <WorkflowStep
        {...this.props}
        title="Scan ABC barcode"
        footer={
          <PrimaryInput
            nextStep={this.goToNextStep.bind(this)}
            onSubmit={this.props.returnABC}
            placeholder="Scan or type in barcode"
            rules="required|abc"
            title="ABC Barcode"
            type="Barcode"
          />
        }
        after={
          <div className="return-dashboard-link" onClick={this.closeWorkflow.bind(this)}>
            <span>GO TO DASHBOARD</span>
          </div>
        }
      >
        <Row gutter>
          <Col oneoftwo>
            <ul>
              <li>Scan ABC barcode on the part and follow the next step</li>
            </ul>
          </Col>
          <Col oneoftwo>
            <img src={example} className="step-1-graphic-return-abc" alt="" />
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

ReturnABC.propTypes = {
  returnAbcCompleted: PropTypes.bool,
  returnABC: PropTypes.func,
  incrementScannedCountAction: PropTypes.func,
  resetScannedCountAction: PropTypes.func
};

ReturnABC.defaultProps = {
  returnAbcCompleted: false
};

const mapStateToProps = ({ serviceKit }) => {
  const { returnABCStatus } = serviceKit;
  return {
    returnAbcCompleted: !isEmpty(returnABCStatus)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      returnABC,
      incrementScannedCountAction,
      resetScannedCountAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReturnABC);
