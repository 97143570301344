import React from 'react';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import GoBackArrow from '../../../../icons/gobackarrow.png';
import './ContactFSL.scss';

const ContactFSL = (props) => {
    const { unableToLocate,unableToLocateStep,goBackScanWSN } = props;
    const buttonLabel = 'I CONTACTED MANAGEMENT, CONTINUE';

const continueRacking = ()=>{
  unableToLocate();
}

const goBack = ()=>{
  goBackScanWSN();
}
    return (
      <>
      <div className="missing-wsn-go-back-link">
      <img src={GoBackArrow} alt="" />
      <span className="missing-wsn-link-text">Located the missing shipment?</span>
      <span className="missing-wsn-link-text go-back" onClick={goBack}>GO BACK</span>
      </div>
        <WorkflowStep
        {...props}
        step={unableToLocateStep}
        title="If you can't find a shipment for your order, contact Asurion FSL management."
        footer={
          <PrimaryButton
            text={buttonLabel}
            onClick={continueRacking}
          />
        }
      >
      </WorkflowStep>
      </>
    )
}

export default ContactFSL;
