import {
    SET_PROCESSING_SCAN_WSN_LIST
} from '../actions/scanWsnAction';

const initialState = {
    processingScanWsnList: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_PROCESSING_SCAN_WSN_LIST:
            return { ...state, processingScanWsnList: action.payload };
        default:
            return state;
    }
}
