import { useCallback } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Workflow from '../Workflow/Workflow';
import './RmaScreen.scss';

const RmaWorkflowNames = {
  RESTAGE: 'RESTAGE',
  RETURN: 'RETURN',
  SCRAP: 'SCRAP',
  PDRETURN: 'PDRETURN',
  PDRETURN_EXT:'PDRETURN_EXT'
};

const RmaScreen = (props) => {
    const closeModal = useCallback(() => {
        props.backToFSLDashboard();
    }, [props]);

  let workflowOrder = [];
    switch (props.rmaScanPartsStatus) {
        case RmaWorkflowNames.PDRETURN_EXT:
            workflowOrder = [
                'scanningreturns',
                'returntopdext'
            ];
            break;
        case RmaWorkflowNames.RESTAGE:
        case RmaWorkflowNames.RETURN:
            workflowOrder = [
                'scanningreturns',
                'returnorrestock'
            ];
            break;
        case RmaWorkflowNames.SCRAP:
            workflowOrder = [
                'scanningreturns',
                'returnscrap'
            ];
            break;
        case RmaWorkflowNames.PDRETURN:
            workflowOrder = [
                'scanningreturns',
                'returntopdprint',
                'returntopdaffix',
                'returntopdshelf'
            ];
            break;
        default:
            workflowOrder = [
                'scanningreturns'
            ];
            break;
  }

    return (
        <Workflow
            closeWorkflow={closeModal}
            modal={true}
            workflowOrder={workflowOrder}
            rmaWorkflow={true}
            />
    );
};

RmaScreen.propTypes = {
    backToFSLDashboard: PropTypes.func,
    rmaScanPartsStatus: PropTypes.string
};

RmaScreen.defaultProps = {
    rmaScanPartsStatus: ''
};

const mapStateToProps = ({ rma }) => ({
    rmaScanPartsStatus: rma.rmaScanPartsStatus
});

export default connect(mapStateToProps, null)(RmaScreen);
