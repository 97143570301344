import styled from 'styled-components';
export const BodyText = styled.li`
    font-weight: 400;
    font-size: 20px;
    align-items: center;
`;

export const List = styled.ul`
    list-style-type: disc;
`;

export const BottomBox = styled.div`
    width: 100%;
    height: 105px;
    left: 0px;
    top: 615px;
    background: #8223D2;
    box-shadow: 0px 0px 20px rgba(130, 35, 210, 0.75);
    img{
        padding-left: 10px;
    }
    span {
        font-weight: 700;
        font-size: 40px;
        line-height: 115%;
        display: flex;
        align-items: flex-end;
        text-align: center;
        color: #FFFFFF;
        margin: 30px auto;
        width: max-content;
        cursor: pointer;
    }
`;

export const AfterCardText = styled.div`
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration-line: underline;
    color: #8223D2;
    margin: 36px auto;
    width: max-content;
    cursor: pointer;
    img{
        padding-left: 10px;
    }
`;