import { FlowTypes, WorkflowTaskMapping } from '../utilities/enums';
import { Get, Post, uriName, userInfo } from '../utilities/apiUtitlity';
import { hasError, showErrorMessage, showInputErrorMessage } from './errorActions';
import { hideLoading, showLoading } from '../actions/appInteractionActions';
import {
  updateRefreshRate,
  updateRefreshRateOnError,
  updateSessionId
} from '../utilities/sessionUtility';
import { wsDisconnect, wsDisconnected } from './wsConnectActions';
import { calculateHotNewsCount, saveHotNews } from './configurationActions';
import {
  setReceivingError,
  setWsnForReceivedParts,
  setWsnListForReceivedParts
} from './receiveActions';
import { generateCorrelationID } from '../utilities/genericUtility';

export const CLEAR_CLIENT_SELECTION = 'CLEAR_CLIENT_SELECTION';
export const CLEAR_PROCESSING_KIT = 'CLEAR_PROCESSING_KIT';
export const COLLATERAL_STEP_COMPLETE = 'COLLATERAL_STEP_COMPLETE';
export const GET_ORDERS = 'GET_ORDERS';
export const PRINT_STEP_COMPLETE = 'PRINT_STEP_COMPLETE';
export const PRINT_WSN_STEP_COMPLETE = 'PRINT_WSN_STEP_COMPLETE';
export const RESTAGING_SCAN_WSN_STEP_COMPLETE = 'RESTAGING_SCAN_WSN_STEP_COMPLETE';
export const RESCHEDULE_SCAN_WSN_STEP_COMPLETE = 'RESCHEDULE_SCAN_WSN_STEP_COMPLETE';
export const RESTAGING_AFFIX_AND_SCAN_WSN_STEP_COMPLETE = 'RESTAGING_AFFIX_AND_SCAN_WSN_STEP_COMPLETE';
export const RESTAGING_CLOSE_BOX_STEP_COMPLETE = 'RESTAGING_CLOSE_BOX_STEP_COMPLETE';
export const RESUME_KIT_SUCCESSFUL = 'RESUME_KIT_SUCCESSFUL';
export const RESUME_ORDER = 'RESUME_ORDER';
export const SAVE_ESN_NUMBER = 'SAVE_ESN_NUMBER';
export const SAVE_FIRSTNET_SIM_NUMBER = 'SAVE_FIRSTNET_SIM_NUMBER';
export const SAVE_RMA_NUMBER = 'SAVE_RMA_NUMBER';
export const SAVE_SKIP_STEP = 'SAVE_SKIP_STEP';
export const SAVE_TRACKING_NUMBER = 'SAVE_TRACKING_NUMBER';
export const SAVE_FLIER_GUIDE = 'SAVE_FLIER_GUIDE';
export const SCAN_AFFIX_COMPLETE = 'SCAN_AFFIX_COMPLETE';
export const SELECT_CLIENT_FOR_WORKFLOW = 'SELECT_CLIENT_FOR_WORKFLOW';
export const START_NEXT_ORDER = 'START_NEXT_ORDER';
export const UPDATE_RESCHEDULE_ROW_DATA="UPDATE_RESCHEDULE_ROW_DATA"
export const UPDATE_RESHIP_STATUS = 'UPDATE_RESHIP_STATUS';
export const UPDATE_TOTAL_COUNT = 'UPDATE_TOTAL_COUNT';
export const DECREMENT_TOTAL_COUNT = 'DECREMENT_TOTAL_COUNT';
export const ORDER_CANCELED_FLAG = 'ORDER_CANCELED_FLAG';
export const RETURN_ABC_COMPLETE = 'RETURN_ABC_COMPLETE';
export const SET_MULTI_WORKFLOW_OBJ = 'SET_MULTI_WORKFLOW_OBJ';
export const SET_MULTI_WORKFLOW_LIST = 'SET_MULTI_WORKFLOW_LIST';
export const GET_RECEIVING_PARTS = 'GET_RECEIVING_PARTS';
export const SET_RMA_SCAN_PARTS_STATUS = 'SET_RMA_SCAN_PARTS_STATUS';
export const SET_RMA_SCAN_LABEL_URL = 'SET_RMA_SCAN_LABEL_URL';
export const SET_RMA_SCAN_TRACKING_NUM = 'SET_RMA_SCAN_TRACKING_NUM';
export const SET_RMA_SCAN_DISTRIBUTOR_NAME = 'SET_RMA_SCAN_DISTRIBUTOR_NAME';
export const getOrders = refreshFlag => async (dispatch, getState) => {
  try {
    //dispatch(showLoading());
    generateCorrelationID(userInfo.companyId, FlowTypes.DASHBOARD);
    if (userInfo.sessionId === '') updateSessionId();
    const res = await dashboardApiCall();
      dispatch(hideLoading());
    if (hasError(res) && !refreshFlag) {
      updateRefreshRateOnError();
      dispatch(showErrorMessage('Unable to load the data for Dashboard'));
    } else {
      const orderToPack = res.serviceKitOrders.filter(
        order => order.serviceKitOrderStatusType === 'OrdersToPack'
      )[0];
      updateRefreshRate(res.refreshTime);
      const dataToUpdate = {
        ...res,
        clientCounts: orderToPack.serviceKitOrderClientCounts.sort((a, b) =>
          parseInt(a.externalClientId - b.externalClientId)
        ),
        orderToPack: orderToPack.serviceKitDisplayRows.length
      };
      if (res.websocketON !== undefined && !res.websocketON && getState().websocket.connected) {
        await dispatch(wsDisconnect(true));
        await dispatch(wsDisconnected());
      }
      dispatch(updateOrdersOnLoad(dataToUpdate));
    }
  } catch (error) {
    updateRefreshRateOnError();
    hasError(error) &&
      !refreshFlag &&
      dispatch(showErrorMessage('Unable to load the data for Dashboard'));
  }
};

export const dashboardApiCall = async () => await Get(uriName, '/v1/servicekit/ma/dashboard');

export const updateOrderData = res => {
  if (res.error || res.message) {
    return showErrorMessage('Unable to load the data for Dashboard');
  }
  if (res.serviceKitOrders) {
    const orderToPack = res.serviceKitOrders.filter(
      order => order.serviceKitOrderStatusType === 'OrdersToPack'
    )[0];
    const dataToUpdate = {
      ...res,
      clientCounts: orderToPack.serviceKitOrderClientCounts.sort((a, b) =>
        parseInt(a.externalClientId - b.externalClientId)
      ),
      orderToPack: orderToPack.serviceKitDisplayRows.length
    };
    return updateOrdersOnLoad(dataToUpdate);
  } else if (res.newsInfo) {
    const countData = calculateHotNewsCount(res);
    const newRes = { ...res, ...countData };
    return saveHotNews(newRes);
  }
};

export const selectClientForWorkflow = clientId => (dispatch, getState) => {
  const payload = getState().order.clientCounts.filter(
    client => client.externalClientId === clientId
  )[0];
  dispatch({ type: SELECT_CLIENT_FOR_WORKFLOW, payload });
};

export const clearClientSelection = () => dispatch => {
  dispatch({ type: CLEAR_CLIENT_SELECTION });
};

export const searchOrder = serviceKitNumber => async dispatch => {
  try {
    dispatch(showLoading());
    const res = await Get(uriName, `/v1/servicekit/search?serviceKitNumber=${serviceKitNumber}`);
    dispatch(hideLoading());
    if (hasError(res)) return null;
    return res;
  } catch (error) {
    dispatch(hideLoading());
    return null;
  }
};

export const nextServiceKitOrder = (kitReferenceNumber='',serviceKitNumber='') => async (dispatch, getState) => {
  try {
    let nextOrderRoute = '/v1/servicekit/ma/nextorder',orderStatusType = 'OrdersToPack';
    dispatch(showLoading());
    updateSessionId();
    if(kitReferenceNumber){
      nextOrderRoute = `/v1/servicekit/ma/nextorder?kitReferenceNumber=${kitReferenceNumber}`;
      orderStatusType = 'Unfinished';
    }
   else if(serviceKitNumber){
    nextOrderRoute = `/v1/servicekit/ma/nextorder?serviceKitNumber=${serviceKitNumber}`;

   }
    
    
    // NOTE: Leaving this for future when we add back in additional external clients
    // const res = await Get(
    //   uriName,
    //   `/v1/servicekit/nextorder?externalClientId=${
    //     getState().order.selectedClient.externalClientId
    //   }`
    // );
    const res = await Get(uriName, nextOrderRoute);
    dispatch(hideLoading());
    if (hasError(res)) {
      dispatch(clearProcessingKit());
      dispatch(showErrorMessage('Unable to proceed further due to Server issues'));
    } else {
      res.zone = '';
      const orderToKit = getState().order.serviceKitDisplayRows.filter(
        order => order.serviceKitOrderStatusType === orderStatusType
      )[0];
      orderToKit.serviceKitDisplayRows.forEach(serviceKit => {
        if (serviceKit.serviceKitNumber === res.serviceKitNumber && serviceKit.zone)
          res.zone = serviceKit.zone;
      });
      dispatch(startNewServiceKitOrder(res));
    }
  } catch (error) {
    dispatch(clearProcessingKit());
    dispatch(hideLoading());
    hasError(error) && dispatch(showErrorMessage('Unable to proceed further due to Server issues'));
  }
};

export const printPackagingLabel = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { kitReferenceNumber, sku } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitSKU: sku,
      serviceKitTasks: [
        {
          taskNumber: 'PRINT',
          taskDescription: 'Print packaging label'
        }
      ]
    };
    const res = await Post(
      uriName,
      `/v1/servicekit/${
        getState().serviceKit.processingServiceKit.serviceKitNumber
      }/printpackinglabel`,
      postData
    );
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server.'));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(printStepCompleted());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showErrorMessage('Unable to send the data to the server.'));
  }
};

export const printWsnPackagingLabel = (skn) => async (dispatch, getState) => {
  try {
    const { kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    dispatch(showLoading());
    const postData = {
      kitReferenceNumber,
      serviceKitTasks: [
        {
          taskNumber: 'PRINT_WSN',
          taskDescription: 'Print Restage WSN label'
        }
      ]
    };
    const res = await Post(
      uriName,
      `/v1/servicekit/ma/${skn}/restage/prepareshipment-printwsn`,
      postData
    );
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server.'));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(printWsnStepCompleted());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showErrorMessage('Unable to send the data to the server.'));
  }
};

export const affixAndScanLabel = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitTasks: [
        {
          taskNumber: 'AFFIX',
          taskDescription: 'Affix and scan'
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/affixandscan`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server'));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(scanAndAffixComplete());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
  }
};

export const affixReshipLabel = zipCode => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitTasks: [
        {
          taskNumber: 'AFFIXANDSCANRESHIPLABEL',
          taskDescription: 'Affix and scan reship label',
          taskName: 'affixandscanreshiplabel',
          taskDetail: zipCode
        }
      ]
    };
    const res = await Post(
      uriName,
      `/v1/servicekit/${serviceKitNumber}/affixandscanreshiplabel`,
      postData
    );
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to upload the Envelope Data'));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(updateReshipStatus());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to upload the Envelope Data'));
  }
};

export const scanZip = zipCode => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitTasks: [
        {
          taskNumber: 'SCANZIP',
          taskDescription: 'Scan zip bar code',
          taskName: 'scanzip',
          taskDetail: zipCode
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/scanzip`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to upload the Envelope Data'));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(updateReshipStatus());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to upload the Envelope Data'));
  }
};

export const scanRmaNumber = smartLabel => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemNumber: 'RMA',
          itemName: 'RMA Number',
          itemType: 'RMA',
          rmaNumber: smartLabel.substr(smartLabel.indexOf('AS'), 9)
        },
        {
          itemNumber: 'SMARTLABEL',
          itemName: 'Smart label number',
          itemType: 'SMARTLABEL',
          smartLabelNumber: smartLabel
        }
      ],
      serviceKitTasks: [
        {
          taskNumber: 'SCANRMA',
          taskDescription: 'Scan rma'
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/scanrma`, postData);
    dispatch(hideLoading());
    if (hasError(res)) {
      dispatch(showErrorMessage('Unable to upload the Envelope Data'));
      dispatch(saveRmaScanNumber());
    } 
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(saveRmaScanNumber(smartLabel));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to upload the Envelope Data'));
    dispatch(saveRmaScanNumber(''));
  }
};

export const scanReturnEnvelope = uspsTrackingNumber => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemNumber: 'ENVELOPE',
          itemName: 'Return Envelope',
          itemType: 'ENVELOPE',
          envelopeTrackingNumber: uspsTrackingNumber
        }
      ],
      serviceKitTasks: [
        {
          taskNumber: 'ENVELOPE',
          taskDescription: 'Scan return envelope'
        }
      ]
    };
    const res = await Post(
      uriName,
      `/v1/servicekit/${serviceKitNumber}/scanreturnenvelope`,
      postData
    );
    dispatch(hideLoading());
    if (hasError(res)) {
      dispatch(showInputErrorMessage(res.error.message));
      dispatch(saveEnvelopTrackingNumber(''));
    } 
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(saveEnvelopTrackingNumber(uspsTrackingNumber));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to upload the Envelope Data'));
    dispatch(saveEnvelopTrackingNumber(''));
  }
};

export const packCollateral = type => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    let collateralType = 'COLLATERAL',
      description = 'Pack collateral',
      itemName = 'Collateral',
      uri = 'packcollateral';
    switch (type) {
      case 'DUAL':
        collateralType = 'COLLATERALDUAL';
        description = 'Pack dual sim collateral';
        itemName = 'Collateral Dual';
        uri = 'packcollateraldual';
        break;
      case 'FIRSTNET':
        collateralType = 'COLLATERALFIRSTNET';
        description = 'Pack firstnet collateral';
        itemName = 'Collateral Firstnet';
        uri = 'packcollateralfirstnet';
        break;
      case 'INBOX':
        collateralType = 'COLLATERALINBOX';
        description = 'Pack inbox collateral';
        itemName = 'Collateral Inbox';
        uri = 'packcollateralinbox';
        break;
      default:
        break;
    }
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemNumber: collateralType,
          itemName: itemName,
          itemType: 'COLLATERAL'
        }
      ],
      serviceKitTasks: [
        {
          taskNumber: collateralType,
          taskDescription: description
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/${uri}`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server'));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(collateralStepComplete());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
  }
};

export const scanFirstNetSim = simNumber => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemNumber: 'FIRSTNETSIM',
          itemName: 'Firstnet SIM',
          itemType: 'SIM',
          simNumber
        }
      ],
      serviceKitTasks: [
        {
          taskNumber: 'SCANFIRSTNETSIM',
          taskDescription: 'Scan firstnet sim'
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/scansim`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server'));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(saveFirstNetSimNumber(simNumber));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
    dispatch(saveFirstNetSimNumber(''));
  }
};

export const scanFlier = (itemNumber, flierType) => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    let postData = {};
    switch (flierType) {
      case WorkflowTaskMapping.SCANVPHGUIDE:
        postData = {
          kitReferenceNumber,
          serviceKitItems: [
            {
              itemNumber,
              itemName: 'VPH Guide',
              itemType: 'VPHGUIDE'
            }
          ],
          serviceKitTasks: [
            {
              taskNumber: 'SCANVPHGUIDE',
              taskDescription: 'Scan vhdp guide barcode'
            }
          ]
        };
        break;
      case WorkflowTaskMapping.SCANHOMEPLUSGUIDE:
        postData = {
          kitReferenceNumber,
          serviceKitItems: [
            {
              itemNumber,
              itemName: 'Homeplus Guide',
              itemType: 'HOMEPLUSGUIDE'
            }
          ],
          serviceKitTasks: [
            {
              taskNumber: 'SCANHOMEPLUSGUIDE',
              taskDescription: 'Scan Hometech brochure barcode'
            }
          ]
        };
        break;
      case WorkflowTaskMapping.SCANVPHTOSBOOKLET:
        postData = {
          kitReferenceNumber,
          serviceKitItems: [
            {
              itemNumber,
              itemName: 'VPH TOS Booklet',
              itemType: 'VPHTOSBOOKLET'
            }
          ],
          serviceKitTasks: [
            {
              taskNumber: 'SCANVPHTOSBOOKLET',
              taskDescription: 'Scan vhdp tos booklet barcode'
            }
          ]
        };
        break;
      default:
        postData = {};
        break;
    }
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/scanflier`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server'));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(saveFlierGuide(itemNumber));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
    dispatch(saveFlierGuide(''));
  }
};

export const loadStockIndicator = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, sku } = getState().serviceKit.processingServiceKit;
    const res = await Get(
      uriName,
      `/v1/servicekit/skucount?skuReferenceNumber=${sku}&serviceKitNumber=${serviceKitNumber}`
    );
    dispatch(hideLoading());
    if (hasError(res)) return 0;
    return res.quantityAvailableInStock;
  } catch (error) {
    dispatch(hideLoading());
    return 0;
  }
};

export const scanSkuEsn = esnNumber => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const {
      serviceKitNumber,
      kitReferenceNumber,
      sku
    } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemNumber: sku,
          itemName: sku,
          itemType: 'PHONE',
          skuEsnNumber: esnNumber
        }
      ],
      serviceKitTasks: [
        {
          taskNumber: 'SCAN SKU',
          taskDescription: 'Scan SKU ESN'
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/scanskuesn`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage(res));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(saveEsnNumber(esnNumber));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
    dispatch(saveEsnNumber(''));
  }
};

export const scanWsn = (wsn) => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemName: 'WSN_Part_Number',
          itemType: 'WSN_PART'
        }
      ],
      serviceKitTasks: [
        {
          taskNumber: 'SCAN_WSN',
          taskDescription: 'Scan Wsn'
        }
      ]
    };
    const res = await Post(
      uriName,
      `/v1/servicekit/ma/${wsn}/scanwsn`,
      postData
    );
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server.'));
    else if(res.hasOwnProperty('code') && res.code === "4005") dispatch(showInputErrorMessage('Barcode already scanned.'));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    return res;
    // else if a different error with scanning wsn we update UI so it doesn't show it is scanned?
    // else do nothing
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showErrorMessage('Unable to send the data to the server.'));
  }
};

export const scanWSNUnableToLocate = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const {serviceKit,scanWsn} = getState();
    const { kitReferenceNumber } = serviceKit.processingServiceKit;
   const filterUnscannedBarcodes = (wsnList) => {
      let unscannedBarcodes = [];
  
      for (let idx = 0; idx < wsnList.length; idx++) {
        if (wsnList[idx].status !== 'KITTED') {
          unscannedBarcodes.push(wsnList[idx].wsn);
        }
      }
      return unscannedBarcodes;
  }
    const unableToLocateWSN = filterUnscannedBarcodes(scanWsn.processingScanWsnList);
    const postData = {
      kitReferenceNumber,
      serviceKitItems: [
        {
          itemName: 'WSN_Part_Number',
          itemType: 'WSN_PART'
        }
      ],
      serviceKitTasks: [
        {
          taskNumber: 'SCAN_WSN',
          taskDescription: 'Scan Wsn'
        }
      ],
      unableToLocateWSN:true,
      notFoundWSNList:unableToLocateWSN

    };
    const res = await Post(
      uriName,
      `/v1/servicekit/ma/${scanWsn.processingScanWsnList[0].wsn}/scanwsn`,
      postData
    );
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server.'));
    else if(res.hasOwnProperty('code') && res.code === "4005") dispatch(showInputErrorMessage('Barcode already scanned.'));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    return res;
    // else if a different error with scanning wsn we update UI so it doesn't show it is scanned?
    // else do nothing
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showErrorMessage('Unable to send the data to the server.'));
  }
};
export const restagingScanWsn = (wsn) => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitTasks: [
        {
          taskNumber: 'SCAN_WSN',
          taskDescription: 'Scan Restage WSN label'
        }
      ]
    };
    const res = await Post(
      uriName,
      `/v1/servicekit/ma/${wsn}/restage/prepareshipment-scanwsn`,
      postData
    );
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server.'));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(restagingScanWsnStepCompleted());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(showInputErrorMessage('Invalid Barcode. Try again.'));
  }
};

export const rescheduleScanWsn = (wsn) => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const existingwsn = getState().serviceKit.rescheduleRowData.serviceKitNumber;
    const kitReferenceNumber= getState().serviceKit.rescheduleRowData.kitReferenceNumber;
    if(wsn===existingwsn){
      const res = await Post(uriName, '/v1/servicekit/update', { serviceKitReferenceNumber: kitReferenceNumber, serviceKitStatus:"OPEN" });
      dispatch(hideLoading());
      if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server.'));
      else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
      else dispatch(rescheduleScanWsnStepCompleted());
    }else{
      dispatch(hideLoading());
      dispatch(showInputErrorMessage('Invalid Barcode. Try again.'));
    }
  } catch (error) {
    dispatch(hideLoading());
    dispatch(showInputErrorMessage('Invalid Barcode. Try again.'));
  }
};

export const restagingAffixAndScan = (wsn) => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      serviceKitTasks: [
        {
          taskNumber: 'SCAN_WSN',
          taskDescription: 'Scan Restage WSN label'
      }
      ]
    };
    const res = await Post(
      uriName,
      `/v1/servicekit/ma/${wsn}/restage/prepareshipment-affixandscanwsn`,
      postData
    );
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server.'));
    else if(res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(restagingAffixAndScanWsnStepCompleted());
  } catch (error) {
    dispatch(hideLoading());
    dispatch(showInputErrorMessage('Invalid Barcode. Try again.'));
  }
};

export const restagingScanABC = abcNumber => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const {
      serviceKitNumber,
      kitReferenceNumber
    } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber: kitReferenceNumber,
      serviceKitItems: [
        {
          "itemTrackingNumber": abcNumber,
          "itemType": "WSN_PART"
        }
      ],
      serviceKitTasks: [
        {
          "taskNumber": "SCAN_WSN_PART",
          "taskDescription": "Scan ABC part label"
        }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/ma/${serviceKitNumber}/restage/scanparts`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage(res));
    else if (res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else if (res.hasOwnProperty('code') && res.code === "1001") dispatch(showInputErrorMessage('Barcode has already been processed'));
    else if (res.hasOwnProperty('code') && res.code === "1002") dispatch(showInputErrorMessage('Barcode already scanned.'));
    return res;
  } catch (error) {
    dispatch(hideLoading());
    dispatch(showInputErrorMessage('Invalid ABC barcode. Does not belong to WSN.'));
  }
};

export const restagingCloseBox = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const {
      serviceKitNumber,
      kitReferenceNumber
    } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber: kitReferenceNumber,
      serviceKitTasks: [
        {
          "taskNumber": "CLOSE_BOX",
          "taskDescription": "Close the shipment box"
      }
      ]
    };
    const res = await Post(uriName, `/v1/servicekit/ma/${serviceKitNumber}/restage/closebox`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage(res));
    else if (res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else dispatch(restagingCloseBoxStepCompleted());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
  }
};

export const restagingUnableToLocatePart = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const {
      serviceKitNumber,
      kitReferenceNumber
    } = getState().serviceKit.processingServiceKit;

    const postData = {
      kitReferenceNumber: kitReferenceNumber,
      serviceKitItems: [
        {
          // required for API but not needed for missing part
          "itemTrackingNumber": '00000000000',
          "itemType": "WSN_PART"
        }
      ],
      serviceKitTasks: [
        {
          "taskNumber": "SCAN_WSN_PART",
          "taskDescription": "Unable to locate part."
        }
      ],
      unableToLocatePart: true
    };

    const res = await Post(uriName, `/v1/servicekit/ma/${serviceKitNumber}/restage/scanparts`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage(res));
    else if (res.hasOwnProperty('code') && res.code === "4004") dispatch(orderCanceledFlag());
    else if (res.hasOwnProperty('code') && res.code === "1001") dispatch(showInputErrorMessage('Bar code cannot be scanned.'));
    else if (res.hasOwnProperty('code') && res.code === "1002") dispatch(showInputErrorMessage('Bar code already scanned.'));
    return res;
  } catch (error) {
    dispatch(hideLoading());
    dispatch(showInputErrorMessage('Invalid ABC barcode. Does not belong to WSN.'));
  }
}

export const resumeOrder = (rowInfo, callback) => async (dispatch, getState) => {
  dispatch(showLoading());
  await selectClientForWorkflow(rowInfo.externalClientId)(dispatch, getState);
  await dispatch({ type: RESUME_ORDER, rowInfo });
  try {
    const res = await Get(
      uriName,
      `/v1/servicekit/ma/nextorder?kitReferenceNumber=${rowInfo.kitReferenceNumber}`
    );
    if (hasError(res)) {
      dispatch(showErrorMessage('Unable to load the data for Resume'));
    } else {
      res.zone = '';
      const orderToKit = getState().order.serviceKitDisplayRows.filter(
        order => order.serviceKitOrderStatusType === 'Unfinished'
      )[0];
      orderToKit.serviceKitDisplayRows.forEach(serviceKit => {
        if (serviceKit.kitReferenceNumber === res.kitReferenceNumber && serviceKit.zone)
          res.zone = serviceKit.zone;
      });
      await dispatch(startNewServiceKitOrder(res));
      callback(res.workflow.serviceKitTasks[0]);
    }
    dispatch(hideLoading());
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showErrorMessage('Unable to load the data for Resume'));
  }
};

export const restageOrder = (rowInfo, callback) => async (dispatch) => {
  try {
    dispatch(showLoading());
    if (userInfo.sessionId === '') updateSessionId();
    let res = "";
    await dispatch({ type: RESUME_ORDER, rowInfo });
    if (rowInfo.serviceKitNumber)
      res = await Get(
        uriName,
        `/v1/servicekit/ma/nextorder?serviceKitNumber=${rowInfo.serviceKitNumber}`
      );
    dispatch(hideLoading());
    if (hasError(res)) {
      dispatch(clearProcessingKit());
      dispatch(showErrorMessage('Unable to proceed further due to Server issues'));
    } else {
      dispatch(startNewServiceKitOrder(res));
      // determine what step to continue restaging on
      let currentStep = 1;
      let lastStepIndex = 0;
      let scanWorkflow = true;
      if (res.serviceKitTasks.length > 0) {
        let lastExecutedTaskName = res.serviceKitTasks[0].taskName;
        if (lastExecutedTaskName !== "returnskuesn") {
          // current step is not returnskuesn (past print/scan step)
          // See if that task exists in scanWSN workflow --> scanWSN: ["prepareshipment-scanwsn", "scanparts", "closebox"];
          lastStepIndex = res.workflow.restage.serviceKitTasks.scanWSN.findIndex((e) => e === lastExecutedTaskName);
          if (lastStepIndex === -1) {
            // lastExecutedTaskName not found in scanWSN so lets look in printWSN workflow
            // printWSN: ["prepareshipment-printwsn", "prepareshipment-affixandscanwsn", "scanparts", "closebox"];
            let lastStepIndexForPrintWSNFlow = res.workflow.restage.serviceKitTasks.printWSN.findIndex((e) => e === lastExecutedTaskName);
            if (lastStepIndexForPrintWSNFlow === -1) {
              // As current step is not defined in any of the workflows which means 
              // We need to start from the first step in workflow
              currentStep = 1;
            } else {
              scanWorkflow = false;
              // currentStep for when in printWSN workflow
              currentStep = lastStepIndexForPrintWSNFlow + 1;
            }
          }
          // if lastStepIndex is prepareshipment-scanwsn step then land on next page
          if (lastStepIndex === 0) {
            currentStep = (lastStepIndex + 1) + 1;
          }
          // if lastStepIndex is scanparts step then land on respective page
          if (lastStepIndex === 1) {
            // if atleast one item is ready for restage then land on the same scanParts page to kit the item
            if (res.serviceKitItems.some(ele => ele.status === "RESTAGE")) {
              currentStep = lastStepIndex + 1;
            } else {
              // if none of the items are for restaging, all are either Kitted or Pending Return then land on close box page
              currentStep = (lastStepIndex + 1) + 1;
            }
          }
        } else {
          // current step is returnskuesn which means we have to start from the first step in workflow
          currentStep = 1;
        }
      }
      // remove 'prepareshipment-printwsn' task from workflow array
      const idxFound = res.workflow.restage.serviceKitTasks.printWSN.findIndex((element) => element === 'prepareshipment-printwsn');
      if (idxFound === 0) {
        res.workflow.restage.serviceKitTasks.printWSN.shift();
      }
      if (res.workflow.restage.serviceKitTasks) dispatch(saveMultiWorkflowObj(res.workflow.restage.serviceKitTasks));
      if (res.workflow.restage.serviceKitTasks && res.workflow.restage.serviceKitTasks.scanWSN && scanWorkflow) dispatch(saveMultiWorkflowList(res.workflow.restage.serviceKitTasks.scanWSN));
      else if (res.workflow.restage.serviceKitTasks && res.workflow.restage.serviceKitTasks.printWSN && !scanWorkflow) dispatch(saveMultiWorkflowList(res.workflow.restage.serviceKitTasks.printWSN));
      else dispatch(saveMultiWorkflowList(['unable-to-find-workflow-task']));
      callback(currentStep);
    }
  } catch (error) {
    dispatch(clearProcessingKit());
    dispatch(hideLoading());
    hasError(error) && dispatch(showErrorMessage('Unable to proceed further due to Server issues'));
  }
};

export const getServiceKitOrderDetails = (event, saveWsn = false, partNum = '') => async (dispatch, getState) => {
  try {
    let dataToUpdate = {};
    let wsnForPartNum = '';
    let allWsnForPartsReceived = [];
    let uniqueAllWsnForPartsReceived = [];
    if (userInfo.sessionId === '') updateSessionId();
    dispatch(showLoading());

    const today = new Date();
    const todayStr = today.toJSON().toString().substring(0, 10);
    const daysAgo = new Date(today.getTime() - 31536000000);
    // 365 days back (in milliseconds: 365 * 24 * 60 * 60 * 1000)
    const daysAgoStr = daysAgo.toJSON().toString().substring(0, 10);

    const reqOptions = {
      orderSearchType: "FSL",
      fromDate: daysAgoStr,
      toDate: todayStr
    };

    if (event.searchByOption === "Tracking/Shipment Number") {
      reqOptions.trackingNumber = event.searchString;
    } else if (event.searchByOption === "Order/Reference Number") {
      reqOptions.orderNumber = event.searchString;
    }

    const res = await Post("fslma-receiving", '/ordering/v4/orderlist/search', reqOptions);
    dispatch(hideLoading());
    if (hasError(res)) {
      updateRefreshRateOnError();
      dispatch(showErrorMessage('Unable to load the data for Received Orders'));
    } else {
      if (res.Errors.length > 0) {
        let error = {
          message: 'Error please contact System Administrator.'
        };

        if (res.Errors[0].description) {
          error.message = res.Errors[0].description;
        }
        // orderlist/search API returned some error
        dispatch(setReceivingError(error));
      } else {
        const { orders } = res;
        dataToUpdate = {
          "serviceOrder": [{
            "serviceJob": [{
              "serviceJobNumber": orders[0].transactionNumber,
              "serviceJobId": "",
              "orders": orders.map((order) => {
                return {
                  "orderId": order.orderId,
                  "purchaseOrderNumber": order.purchaseOrderNumber,
                  "partsDistributorName": order.partsDistributorName,
                  "partsDistributorId": order.partsDistributorId,
                  "serviceAdministratorId": order.serviceAdministratorId,
                  "orderItems": order.orderItems.map(oi => {
                    let shipment = {
                      "trackingNumber": '',
                      "receivedDate": '',
                      "wsn": '',
                      "shippingCarrierCode": '',
                      "barCode": '',
                      "shipmentDate": ''
                    };
                    let quantityReceived = '';

                    if (oi.orderShipmentDetails.length > 0) {
                      shipment = oi.orderShipmentDetails.map((osd) => {
                        return {
                          "trackingNumber": osd.orderShipment.trackingNumber,
                          "receivedDate": osd.orderShipment.receivedDate,
                          "wsn": osd.orderShipment.wsn,
                          "shippingCarrierCode": osd.orderShipment.shippingCarrierCode,
                          "barCode": osd.orderShipment.barCode,
                          "shipmentDate": osd.orderShipment.shipmentDate
                        }
                      })[0];
                      quantityReceived = oi.orderShipmentDetails.map(osd => osd.quantityReceived)[0];
                    }

                    return {
                      "partNumber": oi.partNumber,
                      "partLineNumber": oi.partLineNumber,
                      "partDescription": oi.partDescription,
                      "orderItemStatusCode": oi.orderItemStatusCode,
                      "quantityOrderded": oi.quantity,
                      "quantityReceived": quantityReceived,
                      "shipment": shipment,
                    }
                  }),

                }
              })
            }]
          }]
        }

        if (saveWsn && partNum !== "") {
          for (const order of orders) {
            for (const orderItem of order.orderItems) {
              if (orderItem.partNumber === partNum) {
                for (const shipmentDetails of orderItem.orderShipmentDetails) {
                  wsnForPartNum = shipmentDetails.orderShipment.wsn;
                }
              }
            }
          }
          dispatch(setWsnForReceivedParts(wsnForPartNum));
        }

        for (const order of orders) {
          for (const orderItem of order.orderItems) {
            for (const shipmentDetails of orderItem.orderShipmentDetails) {
              if (shipmentDetails.orderShipment.wsn !== '') {
                allWsnForPartsReceived.push(shipmentDetails.orderShipment.wsn);
              }
            }
          }
        }

        uniqueAllWsnForPartsReceived = allWsnForPartsReceived.filter((item, index) => {
          return allWsnForPartsReceived.indexOf(item) === index;
        });

        dispatch(setWsnListForReceivedParts(uniqueAllWsnForPartsReceived));
        dispatch(getServiceOrderForReceivingParts(dataToUpdate));
      }
    }
  }
  catch (error) {
    if (error.Error.Message) {
      const errObj = {
        message: error.Error.Message
      };

      dispatch(setReceivingError(errObj));
    } else {
      const errObj = {
        message: error.message
      }

      dispatch(setReceivingError(errObj));
    }
    dispatch(hideLoading());
    updateRefreshRateOnError();
  }
};

export const changeMultiWorkflowList = (newWorkflowKey) => async (dispatch, getState) => {
  const newWorkflowList = getState().serviceKit.serviceKitMultiWorkflow[newWorkflowKey];
  if (newWorkflowList) {
    dispatch(saveMultiWorkflowList(newWorkflowList));
  }
};

export const outOfStock = stepName => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const { serviceKitNumber, kitReferenceNumber } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      outofStockWorkflow: stepName
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/outofstock`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server'));
    else dispatch(saveSkipStep(stepName));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
    dispatch(saveSkipStep(''));
  }
};

export const outOfStockSku = () => async (dispatch, getState) => {
  try {
    dispatch(showLoading());
    const {
      serviceKitNumber,
      kitReferenceNumber,
      sku
    } = getState().serviceKit.processingServiceKit;
    const postData = {
      kitReferenceNumber,
      outofStockWorkflow: 'scanskuesn',
      partNumber: sku
    };
    const res = await Post(uriName, `/v1/servicekit/${serviceKitNumber}/outofstock`, postData);
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showErrorMessage('Unable to send the data to the server'));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to send the data to the server'));
  }
};

export const returnOrder = skuEsnNumber => async dispatch => {
  try {
    dispatch(showLoading());
    const res = await Post(uriName, '/v1/servicekit/return', { skuEsnNumber });
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showInputErrorMessage('Unable to load the data for Return'));
    else await dispatch(resumeKitSuccessfull(res.partStatus));
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to load the data for Return'));
  }
};

export const returnABC = abcBarcode => async dispatch => {
  try {
    if (userInfo.sessionId === '') updateSessionId();

    dispatch(showLoading());
    const res = await Post(uriName, '/v1/servicekit/ma/return', { skuEsnNumber: abcBarcode });
    dispatch(hideLoading());
    if (hasError(res)) dispatch(showInputErrorMessage('Unable to load the data for ABC Return'));
    else if (res.code && res.description && hasError(res)) dispatch(showInputErrorMessage('Unable to load the data for ABC Return'));
    else {
      await dispatch(returnABCSuccessful(res.partStatus));
      await dispatch(setRmaScanPartStatus(res.partStatus));
      if (res.labelUrl !== '') {
        await dispatch(setRmaScanLabelUrl(res.labelUrl));
      }
      if (res.trackingNumber !== '') {
        await dispatch(setRmaScanTrackingNum(res.trackingNumber));
      }
      if (res.pdName !== '') {
        await dispatch(setRmaScanDistributorName(res.pdName));
      }

    };
  } catch (error) {
    dispatch(hideLoading());
    hasError(error) && dispatch(showInputErrorMessage('Unable to load the data for ABC Return'));
  }
}

export const processReschedule = data => dispatch => {
  generateCorrelationID(data?.kitReferenceNumber, FlowTypes.RETURN_SHIPMENT);
  dispatch(startNewServiceKitOrder({ jobNumber: data.kitReferenceNumber }));
  dispatch(updateRescheduleRowData(data));
}
export const rescheduleComplete = jobNumber => async dispatch => {
    try {
      dispatch(showLoading());
      const res = await Post(uriName, '/v1/servicekit/update', { serviceKitReferenceNumber:jobNumber,serviceKitStatus:"OPEN" });
      dispatch(hideLoading());
      if (hasError(res)) dispatch(showInputErrorMessage('Unable to load the response from Reschedule'));
    } catch (error) {
      dispatch(hideLoading());
      hasError(error) && dispatch(showInputErrorMessage('Unable to load the response from Reschedule'));
    }
  };

export const clearProcessingKit = () => dispatch =>
  dispatch({
    type: CLEAR_PROCESSING_KIT
  });

export const updateTotalCount = count => dispatch =>
  dispatch({
    type: UPDATE_TOTAL_COUNT,
    count
  });

export const decrementTotalCount = () => dispatch =>
  dispatch({
    type: DECREMENT_TOTAL_COUNT
  });

export const resetReturnABCSuccessful = () => dispatch =>
  dispatch({
    type: RETURN_ABC_COMPLETE,
    returnABCStatus: ''
  });

const updateOrdersOnLoad = payload => ({
  type: GET_ORDERS,
  payload
});

const startNewServiceKitOrder = payload => ({
  type: START_NEXT_ORDER,
  payload
});

const updateRescheduleRowData = payload => ({
  type: UPDATE_RESCHEDULE_ROW_DATA,
  payload
});

const collateralStepComplete = () => ({ type: COLLATERAL_STEP_COMPLETE });

const printStepCompleted = () => ({ type: PRINT_STEP_COMPLETE });

const printWsnStepCompleted = () => ({ type: PRINT_WSN_STEP_COMPLETE });

const restagingScanWsnStepCompleted = () => ({ type: RESTAGING_SCAN_WSN_STEP_COMPLETE });

const rescheduleScanWsnStepCompleted = ()=>({ type: RESCHEDULE_SCAN_WSN_STEP_COMPLETE });

const restagingAffixAndScanWsnStepCompleted = () => ({ type: RESTAGING_AFFIX_AND_SCAN_WSN_STEP_COMPLETE });

const restagingCloseBoxStepCompleted = () => ({ type: RESTAGING_CLOSE_BOX_STEP_COMPLETE });

const scanAndAffixComplete = () => ({ type: SCAN_AFFIX_COMPLETE });

const updateReshipStatus = () => ({ type: UPDATE_RESHIP_STATUS });

const saveRmaScanNumber = payload => ({
  type: SAVE_RMA_NUMBER,
  payload
});

const saveEnvelopTrackingNumber = payload => ({
  type: SAVE_TRACKING_NUMBER,
  payload
});

const saveFirstNetSimNumber = payload => ({
  type: SAVE_FIRSTNET_SIM_NUMBER,
  payload
});

const saveFlierGuide = payload => ({
  type: SAVE_FLIER_GUIDE,
  payload
});

const saveEsnNumber = payload => ({
  type: SAVE_ESN_NUMBER,
  payload
});

const saveSkipStep = payload => ({
  type: SAVE_SKIP_STEP,
  payload
});

const resumeKitSuccessfull = partStatus => ({
  type: RESUME_KIT_SUCCESSFUL,
  partStatus
});

const returnABCSuccessful = (returnABCStatus) => ({
  type: RETURN_ABC_COMPLETE,
  returnABCStatus
});

const orderCanceledFlag = () => ({
   type: ORDER_CANCELED_FLAG
});

const saveMultiWorkflowObj = (workflowObj) => ({
  type: SET_MULTI_WORKFLOW_OBJ,
  workflowObj
});

const saveMultiWorkflowList = (workflowList) => ({
  type: SET_MULTI_WORKFLOW_LIST,
  workflowList
});

const getServiceOrderForReceivingParts = payload => ({
  type: GET_RECEIVING_PARTS,
  payload
});

export const setRmaScanPartStatus = payload => ({
  type: SET_RMA_SCAN_PARTS_STATUS,
  payload
});

const setRmaScanLabelUrl = payload => ({
  type: SET_RMA_SCAN_LABEL_URL,
  payload
});

const setRmaScanTrackingNum = payload => ({
  type: SET_RMA_SCAN_TRACKING_NUM,
  payload
});

const setRmaScanDistributorName = payload => ({
  type: SET_RMA_SCAN_DISTRIBUTOR_NAME,
  payload
});