import {
    SET_RMA_SCAN_PARTS_STATUS,
    SET_RMA_SCAN_LABEL_URL,
    SET_RMA_SCAN_TRACKING_NUM,
    SET_RMA_SCAN_DISTRIBUTOR_NAME
} from '../actions/orderActions';

const initialState = {
    rmaScanPartsStatus: 'initialRmaStatus',
    rmaScanPartsLabelUrl: '',
    rmaScanPartsTrackingNum: '',
    rmaScanDistributorName:''
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_RMA_SCAN_PARTS_STATUS:
            return { ...state, rmaScanPartsStatus: action.payload };
        case SET_RMA_SCAN_LABEL_URL:
            return { ...state, rmaScanPartsLabelUrl: action.payload };
        case SET_RMA_SCAN_TRACKING_NUM:
            return { ...state, rmaScanPartsTrackingNum: action.payload };
        case SET_RMA_SCAN_DISTRIBUTOR_NAME:
            return { ...state,rmaScanDistributorName : action.payload };
        default:
            return state;
    }
}
