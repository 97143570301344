import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { restagingScanABC } from '../../../../actions/orderActions';
import Col from '../../../Common/Column';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import CheckMarkImage from './checkmark.svg';
import GoBackArrow from './gobackarrow.png';
import './ScanParts.scss';

const ScanABCTitle = () => {
    return (
        <span className="abc-title">
            <span>Scan each part and place in box </span>
        </span>
    );
};

class ScanParts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            abcListState: (Array.isArray(props.kitInfo.serviceKitItems) && props.kitInfo.serviceKitItems.length > 0)
                ? [...props.kitInfo.serviceKitItems] : [],
            showUnableToLocatePart: false
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.goToNextStep = this.goToNextStep.bind(this);
        this.displayListOfABC = this.displayListOfABC.bind(this);
    }

    handleAfterPrint = evt => {
        if (evt.type === 'afterprint') {
            this.props.handleAfterPrintAbc();
        }
    }
  
    componentDidMount() {
        this.props.closeModal();

        window.addEventListener('afterprint', this.handleAfterPrint);
    }

    componentWillUnmount() {
        window.removeEventListener('afterprint', this.handleAfterPrint);
    }

    onClickAbcPrint = (trackingNum) => {
        this.props.handlePrintAbc(trackingNum);
    }

    displayListOfABC = () => {
        let listOfABCDetails = [...this.state.abcListState];
        return (
            <div className="abcListContainer">
                {listOfABCDetails.map(
                    abcPartDetail => {
                        let statusCheck = (abcPartDetail.status.toUpperCase() === 'PENDING_RECEIVING') ? true : false;
                        return (
                            <div className={classNames('abcContainer', { 'abcContainerGreyOut': statusCheck })} key={abcPartDetail.itemTrackingNumber}>
                                <div style={{ 'justifySelf': 'end' }}>
                                    <span>{abcPartDetail.status === 'KITTED' && <img src={CheckMarkImage} className='checkmark-icon-kitted' alt="" />}</span>
                                    <ul style={{ 'listStyleType': 'none', 'padding': 0, 'paddingLeft':'30px' }}>
                                        <li className='abcHead'>ABC #</li>
                                        <li style={{ 'color': '#6E767D' }}>{abcPartDetail.itemTrackingNumber}</li>
                                    </ul>
                                </div>
                                <div style={{ 'justifySelf': 'center' }}>
                                    <span>{abcPartDetail.itemDescription}</span>
                                </div>
                                <div style={{ 'justifySelf': 'center' }}>
                                    <button className="abcPrintBtn" onClick={() => this.onClickAbcPrint(abcPartDetail.itemTrackingNumber)}>Print</button>
                                </div>
                            </div>)
                    })}
            </div>
        );
    };

    goToNextStep = async () => {
        // do nothing
    };

    nextStepUnableToLocatePart = () => {
        // Set app state that we are in missing parts workflow
        this.props.handleIsMissingPartsWorkflow(true);
        // move on to next
        this.props.nextStep(this.props.step);
    }

    onSubmit = async (inputVal) => {
        await this.props.restagingScanABC(inputVal);
        const bufferABCList = JSON.parse(JSON.stringify(this.state.abcListState));
        if (bufferABCList.length === 1) {
            this.props.nextStep(this.props.step);
        }
        bufferABCList.forEach(abcObj => {
            if (abcObj.itemTrackingNumber === inputVal && abcObj.status.toUpperCase() === "RESTAGE") {
                abcObj.status = 'KITTED';
            }
        });
        if (bufferABCList.some(ele => ele.status.toUpperCase() === "RESTAGE")) {
            this.setState({
                abcListState: [...bufferABCList]
            });
        } else {
            this.props.nextStep(this.props.step);
        }
    };

    handleShowUnableToLocatePart = () => {
        this.setState({
            showUnableToLocatePart: true
        });
    }

    handleShowScanParts = () => {
        this.setState({
            showUnableToLocatePart: false
        });
    }

    unableToLocatePartRender = () => (
        <>
            <div className="missing-part-go-back-link">
                <img src={GoBackArrow} alt="" />
                <span className="missing-part-link-text">Located the missing part?</span>
                <span className="missing-part-link-text go-back" onClick={this.handleShowScanParts}>GO BACK</span>
            </div>
            <WorkflowStep
                {...this.props}
                title="If you can't find a part for your shipment, contact Asurion FSL management"
                footer={
                    <PrimaryButton
                        text="I contacted management, continue"
                        onClick={this.nextStepUnableToLocatePart}
                    />
                }
            />
        </>
    );

    scanPartsRender = () => (
        <WorkflowStep
            {...this.props}
            title={<ScanABCTitle />}
            footer={
                <PrimaryInput
                    nextStep={this.goToNextStep}
                    onSubmit={this.onSubmit}
                    placeholder="Scan or type in ABC barcode"
                    rules={'required|restageabc'}
                    title="ABC barcode"
                    type="barcode"
                />
            }
            after={
                <div className="locate-shipment-link" onClick={this.handleShowUnableToLocatePart}>
                    <span>UNABLE TO LOCATE A PART?</span>
                </div>
            }
            scanPartFlag = {true}
        >
            <Row gutter>
                <Col oneofone>
                    <ul>
                        <li>Identify and scan each part listed.</li>
                        <li>Place part in box after scanning.</li>
                        <li>Grayed-out parts do not need to be restocked.</li>
                    </ul>
                </Col>
            </Row>
            <Row gutter>
                {this.displayListOfABC()}
            </Row>
        </WorkflowStep>
    );

    render() {
        const { showUnableToLocatePart } = this.state;
        return (
            <>
                {
                    showUnableToLocatePart ?
                    this.unableToLocatePartRender() :
                    this.scanPartsRender()
                }
            </>
        );
    }
}


ScanParts.propTypes = {
    restagingScanABC: PropTypes.func,
    kitInfo: PropTypes.object,
    handleIsMissingPartsWorkflow: PropTypes.func
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            restagingScanABC
        },
        dispatch
    );

export default connect(null, mapDispatchToProps)(ScanParts);
