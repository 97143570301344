import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    changeMultiWorkflowList,
    printWsnPackagingLabel,
    restagingScanWsn
} from '../../../../actions/orderActions';
import { splitWsnInfo, removeLeadingZeros } from '../../../../utilities/genericUtility';
import Col from '../../../Common/Column';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import example from './restaging-barcode-example.png';
import printIcon from './print-icon.png';
import './RestagingScanWsn.scss';

const RestagingTitle = ({kitNumber}) => {
    return (
      <span className="">
            <span>Prepare shipment and scan: </span>
            <span className="kitNumber">{
                splitWsnInfo(kitNumber).wsnMonth +
                removeLeadingZeros(splitWsnInfo(kitNumber).wsnSerialNum) +
                splitWsnInfo(kitNumber).wsnSerialAlpha
            }</span>
      </span>
    );
};

class RestagingScanWsn extends Component {
    constructor(props) {
        super(props);
    }

    goToNextStep = async () => {
        // do nothing
    };

    onSubmit = async (inputVal) => {
        // API call to restaging scan wsn
        await this.props.restagingScanWsn(inputVal);
        if (this.props.restagingScanWsnComplete) {
            this.props.nextStep(this.props.step);
        }
    };

    render() {
        const { kitInfo } = this.props;
        return (
            <WorkflowStep
                {...this.props}
                title={<RestagingTitle kitNumber={kitInfo.serviceKitNumber} />}
                footer={
                    <PrimaryInput
                        nextStep={this.goToNextStep.bind(this)}
                        onSubmit={this.onSubmit.bind(this)}
                        placeholder="Scan or type in barcode"
                        rules={'required|wsn'}
                        title="WSN barcode"
                        type="barcode"
                    />
                }
                after={
                    <div className="print-wsn-link" onClick={this.props.handlePrintWsn.bind(this,kitInfo.serviceKitNumber)}>
                        <img src={printIcon} className="restagingPrintIcon" alt="" />
                        <span>PRINT WSN</span>
                    </div>
                }
            >
                <Row gutter>
                    <Col oneoftwo>
                        <ul>
                            <li>Make sure the box has the same WSN barcode listed above.</li>
                            <li>Scan the WSN barcode on the box.</li>
                            <li>Or, Print WSN label if needed.</li>
                        </ul>
                    </Col>
                    <Col oneoftwo>
                        <img src={example} className="step-1-graphic-restaging" alt="" />
                    </Col>
                </Row>
            </WorkflowStep>
        );
    }
}

RestagingScanWsn.defaultProps = {
    printWsnComplete: false,
    restagingScanWsnComplete: false
};

RestagingScanWsn.propTypes = {
    printWsnComplete: PropTypes.bool,
    restagingScanWsnComplete: PropTypes.bool,
    changeMultiWorkflowList: PropTypes.func,
    printWsnPackagingLabel: PropTypes.func,
    restagingScanWsn: PropTypes.func
};

const mapStateToProps = ({ serviceKit }) => {
    return {
        printWsnComplete: serviceKit.printWsnStepComplete,
        restagingScanWsnComplete: serviceKit.restagingScanWsnStepComplete
    };
};

const mapDispatchToProps = dispatch =>
bindActionCreators(
    {
        changeMultiWorkflowList,
        printWsnPackagingLabel,
        restagingScanWsn
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(RestagingScanWsn);
