import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    changeMultiWorkflowList,
    printWsnPackagingLabel,
    rescheduleScanWsn
} from '../../../../actions/orderActions';
import { splitWsnInfo, removeLeadingZeros } from '../../../../utilities/genericUtility';
import Col from '../../../Common/Column';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import example from '../Restaging/restaging-barcode-example.png';
import printIcon from '../Restaging/print-icon.png';
import './RescheduleScanWsn.scss';

const RescheduleTitle = ({kitNumber}) => {
    return (
      <span className="">
            <span>Locate shipment and scan: </span>
            <span className="kitNumber">{
                splitWsnInfo(kitNumber).wsnMonth +
                removeLeadingZeros(splitWsnInfo(kitNumber).wsnSerialNum) +
                splitWsnInfo(kitNumber).wsnSerialAlpha
            }</span>
      </span>
    );
};

class RescheduleScanWsn extends Component {
    constructor(props) {
        super(props);
    }

    goToNextStep = async () => {
        // do nothing
    };

    onSubmit = async (inputVal) => {
        // API call to Reschedule scan wsn
        await this.props.rescheduleScanWsn(inputVal);
        if (this.props.rescheduleScanWsnStepComplete) {
            this.props.nextStep(this.props.step);
        }
    };

    render() {
        return (
            <WorkflowStep
                {...this.props}
                title={<RescheduleTitle kitNumber={this.props.kitNumber} />}
                footer={
                    <PrimaryInput
                        nextStep={this.goToNextStep.bind(this)}
                        onSubmit={this.onSubmit.bind(this)}
                        placeholder="Scan or type in barcode"
                        rules={'required|wsn'}
                        title="WSN barcode"
                        type="barcode"
                    />
                }
                after={
                    <div className="print-wsn-link" onClick={this.props.handleReschedulePrintWsn.bind(this,this.props.kitNumber)}>
                        <img src={printIcon} className="restagingPrintIcon" alt="" />
                        <span>PRINT WSN</span>
                    </div>
                }
            >
                <Row gutter>
                    <Col oneoftwo>
                        <ul>
                            <li>Make sure the box has the same WSN barcode listed above.</li>
                            <li>Scan the WSN barcode on the box.</li>
                            <li>Reprint WSN if needed.</li>
                        </ul>
                    </Col>
                    <Col oneoftwo>
                        <img src={example} className="step-1-graphic-restaging" alt="" />
                    </Col>
                </Row>
            </WorkflowStep>
        );
    }
}

RescheduleScanWsn.defaultProps = {
    printWsnComplete: false,
    restagingScanWsnComplete: false
};

RescheduleScanWsn.propTypes = {
    printWsnComplete: PropTypes.bool,
    restagingScanWsnComplete: PropTypes.bool,
    changeMultiWorkflowList: PropTypes.func,
    printWsnPackagingLabel: PropTypes.func,
    restagingScanWsn: PropTypes.func
};

const mapStateToProps = ({ serviceKit }) => {
    const { rescheduleRowData } = serviceKit;
    return {
        printWsnComplete: serviceKit.printWsnStepComplete,
        rescheduleScanWsnStepComplete: serviceKit.rescheduleScanWsnStepComplete,
        kitNumber:rescheduleRowData.serviceKitNumber,
    };
};

const mapDispatchToProps = dispatch =>
bindActionCreators(
    {
        changeMultiWorkflowList,
        printWsnPackagingLabel,
        rescheduleScanWsn
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleScanWsn);
