import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import ReactTable from 'react-table';
import {
  isEmpty,
  splitWsnInfo,
  removeLeadingZeros,
  generateCorrelationID
} from '../../../utilities/genericUtility';
import { FlowTypes, MONTH } from '../../../utilities/enums';

const mapOfOrderStatus = {
  'CANCELLED-UNKITTED': 'Canceled & De-kitted',
  CANCELLED: 'Canceled',
  IN_PROCESS: 'Unfinished',
  KITTED: 'Ready for pickup',
  OUTOFSKU: 'Out of stock',
  PICKED_UP: 'Out for delivery',
  UNKITTED: 'Return complete'
};

const displayShipment = skn => {
  const splitWsn = splitWsnInfo(skn);
  return skn
    ? splitWsn.wsnMonth + removeLeadingZeros(splitWsn.wsnSerialNum) + splitWsn.wsnSerialAlpha
    : '';
};

const displayWarehouseStaging = skn => {
  const splitWsn = splitWsnInfo(skn);
  return splitWsn.wsnMonth + splitWsn.wsnSerialNum;
};

const DashTable = ({
  exceptions,
  orders,
  resumeable,
  restaging,
  reschedule,
  openOrders,
  returnProcess,
  rowSelected,
  restage,
  handleReschedule,
  searchString,
  showCustomerInfo,
  openSelectClient
}) => {
  let columns = [
    {
      Header: 'Shipment',
      accessor: 'serviceKitNumber',
      Cell: props =>
        props.value ? (
          <span
            className={classNames('orderID', {
              orderID__selected:
                !isEmpty(searchString) &&
                searchString.length <= 2 &&
                props.value.toString().endsWith(searchString)
            })}
          >
            {displayShipment(props.value)}
          </span>
        ) : (
          <></>
        ),
      width: 150
    },
    /* we commented below code as of now we are matching with mock up
    {
      Header: 'Warehouse Staging #',
      accessor: 'serviceKitNumber',
      Cell: props => (
        <span>{displayWarehouseStaging(props.value)}</span>
      ),
      width: 300
    }, */
    {
      Header: 'Service Job Number',
      accessor: 'kitReferenceNumber',
      Cell: props => <span>{props.value}</span>,
      width: 250
    },
    {
      Header: 'Scheduled Service Date',
      accessor: 'pickupTimeStamp',
      Cell: props => {
        const pickupTimeDate = props.value.split('T')[0].split('-');
        return (
          <span>
            {MONTH[parseInt(pickupTimeDate[1])] +
              ' ' +
              pickupTimeDate[2] +
              ', ' +
              pickupTimeDate[0]}
          </span>
        );
      },
      width: 300
    },
    {
      Header: 'Tech Name',
      accessor: 'techAssignedName',
      Cell: props => <span title={props.value}>{props.value}</span>,
      width: 290
    }
  ];

  if (resumeable) {
    columns = columns.filter(column => column.accessor !== 'esn');
    columns.push({
      Header: '',
      accessor: 'kitReferenceNumber',
      Cell: props => (
        <span
          className="resumeLink"
          onClick={() => {
            generateCorrelationID(props.original.kitReferenceNumber, FlowTypes.UNFINISHED);
            rowSelected(props.original.serviceKitNumber);
            setTimeout(() => showCustomerInfo(), 0);
          }}
        >
          Resume
        </span>
      )
    });
  }

  if (exceptions) {
    const scheduledServiceDateColumn = columns.pop();
    columns.push({
      Header: 'Reason',
      accessor: 'status',
      Cell: props => (
        <span>
          {props.value.toString().charAt(0) + props.value.toString().slice(1).toLowerCase()}
        </span>
      ),
      width: 250
    });
    columns.push(scheduledServiceDateColumn);
    // start return link
    columns.push({
      Header: '',
      accessor: '',
      Cell: props => (
        <span
          className="startReturnLink"
          onClick={() => {
            generateCorrelationID(props.original.kitReferenceNumber, FlowTypes.EXCEPTION);
            returnProcess();
            setTimeout(() => showCustomerInfo(), 0);
          }}
        >
          Start
        </span>
      )
    });
  }

  if (restaging) {
    // status column
    columns.push({
      Header: 'Status',
      accessor: 'status',
      Cell: props =>
        // else if props.value === 'RESTAGE_ACTION_REQUIRED' and action required then display action required
        props.value === 'RESTAGE_ACTION_REQUIRED' ? (
          <span className="actionRequiredText">Action Required</span>
        ) : // if props.value === 'RESTAGE_IN_PROCESS' then display in progress status
        props.value === 'RESTAGE_IN_PROCESS' ? (
          <span className="inProgressText">In progress</span>
        ) : (
          // else no display
          <></>
        ),
      width: 250
    });

    // restage/resume column
    columns.push({
      Header: '',
      accessor: 'status',
      Cell: props =>
        // if props.value === 'RESTAGE_IN_PROCESS' OR 'RESTAGE_ACTION_REQUIRED' then display resume link
        props.value === 'RESTAGE_IN_PROCESS' || props.value === 'RESTAGE_ACTION_REQUIRED' ? (
          <span
            className="resumeLink"
            onClick={() => {
              generateCorrelationID(props.original.kitReferenceNumber, FlowTypes.RESTAGING);
              restage(props.original.serviceKitNumber);
              setTimeout(() => showCustomerInfo(), 0);
            }}
          >
            Resume
          </span>
        ) : // else if props.value === 'RESTAGE_OPEN' then display restage link
        props.value === 'RESTAGE_OPEN' ? (
          <span
            className="restageLink"
            onClick={() => {
              restage(props.original.serviceKitNumber);
              setTimeout(() => showCustomerInfo(), 0);
            }}
          >
            Restage
          </span>
        ) : (
          // else no link to display
          <></>
        )
    });
  }

  if (reschedule) {
    columns.push({
      Header: 'Action Required',
      accessor: 'status',
      Cell: props => (
        <span
          className="rescheduleProcessLink"
          onClick={() => {
            handleReschedule(props.original.serviceKitNumber);
          }}
        >
          Start
        </span>
      ),
      width: 250
    });
  }

  if (openOrders) {
    columns.push({
      Header: '',
      accessor: '',
      Cell: props => (
        <span
          className="rescheduleProcessLink"
          onClick={() => {
            openSelectClient(props.original.kitReferenceNumber);
          }}
        >
          Start
        </span>
      ),
      width: 250
    });
  }

  return (
    <ReactTable
      data={orders}
      columns={columns}
      getTrProps={(state, rowInfo, column) => {
        return {
          onClick: e => {
            // TODO: implementation of clicking for details
            // showCustomerInfo(rowInfo.original);
          }
        };
      }}
      minRows={0}
      noDataText="No information available."
      showPageSizeOptions={true}
      showPagination={orders.length > 10}
    />
  );
};

DashTable.propTypes = {
  orders: PropTypes.array,
  resumeable: PropTypes.bool.isRequired,
  rowSelected: PropTypes.func,
  restage: PropTypes.func
};

export default memo(DashTable);
