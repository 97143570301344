import React, { memo } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { isEmpty } from '../../../utilities/genericUtility';
import DashAlert from '../DashAlert/DashAlert';
import DashChart from './DashChart';
import DashModal from './DashModal';
import DashTable from './DashTable';
import DashTableFilter from './DashTableFilter';
import '../../../styles/_reactTable-overrides.scss';
import './DashMain.scss';

const DashMain = ({
  clientCounts,
  clientFilterSelect,
  currentOrder,
  displayRowInfo,
  openSelectClient,
  orderToKit,
  orderToPack,
  resumeable,
  restaging,
  reschedule,
  openOrders,
  showRescheduleWorkflow,
  rowSelected,
  showRestagingWorkflow,
  startReturnProcess,
  searchString,
  showAlert,
  showCustomerInfo,
  showExceptions,
  showTable,
  showUnfinished,
  tableContent,
  unfinishedOnes
}) => {
  const rowToSelect = serviceKitNumber => {
    const selectedData = tableContent.filter(
      order => order.serviceKitNumber === serviceKitNumber
    )[0];
    rowSelected(selectedData);
  };

  const handleRestaging = serviceKitNumber => {
    const selectedData = tableContent.filter(
      order => order.serviceKitNumber === serviceKitNumber
    )[0];
    showRestagingWorkflow(selectedData);
  };

  const handleReschedule = serviceKitNumber => {
    // showRescheduleWorkflow(jobNumber);
    const selectedData = tableContent.filter(
      order => order.serviceKitNumber === serviceKitNumber
    )[0];
    showRescheduleWorkflow(selectedData);
  };

  const data = tableContent.filter(order => {
    order.customerFullName = `${order.customerFirstName ? order.customerFirstName : ''} ${
      order.customerLastName ? order.customerLastName : ''
    }`.trim();
    order.customerFullName = !isEmpty(order.customerFullName)
      ? `${order.customerFullName}|${order.externalClientId}`
      : `|${order.externalClientId}`;
    return order;
  });
  const chartProps = { openSelectClient, orderToKit, orderToPack, searchString };

  const content = showTable ? (
    <>
      {currentOrder && (
        <DashModal
          closeModal={showCustomerInfo}
          currentOrder={currentOrder}
          searchString={searchString}
          showContent={displayRowInfo}
        />
      )}
      {/* If we add more clients and need the table filter then we add back in this code */}
      {/* {clientCounts.length > 1 && <DashTableFilter clientFilterSelect={clientFilterSelect} />} */}
      <DashTable
        exceptions={showExceptions}
        orders={data}
        resumeable={resumeable}
        restaging={restaging}
        reschedule={reschedule}
        openOrders={openOrders}
        returnProcess={startReturnProcess}
        rowSelected={rowToSelect}
        restage={handleRestaging}
        handleReschedule={handleReschedule}
        searchString={searchString}
        showCustomerInfo={showCustomerInfo}
        openSelectClient={openSelectClient}
      />
    </>
  ) : (
    <DashChart {...chartProps} />
  );
  return (
    <main className={showTable ? 'dash-main' : 'dash-main-chart'} role="main" id="main-content">
      {showAlert && (
        <DashAlert
          buttonValue="View order"
          message={`You have ${unfinishedOnes} unfinished order${unfinishedOnes > 1 ? 's' : ''}`}
          onClick={showUnfinished}
          showAlert={unfinishedOnes !== 0}
        />
      )}
      {content}
    </main>
  );
};

DashMain.defaultProps = {
  orderToKit: [
    {
      externalClientId: 300,
      count: 0
    },
    {
      externalClientId: 31,
      count: 0
    }
  ],
  orderToPack: 0,
  resumeable: false,
  showAlert: false,
  showTable: true,
  unfinishedOnes: 0
};

DashMain.propTypes = {
  openSelectClient: PropTypes.func,
  orderToKit: PropTypes.array,
  orderToPack: PropTypes.number,
  resumeable: PropTypes.bool,
  rowSelected: PropTypes.func,
  showAlert: PropTypes.bool,
  showExceptions: PropTypes.bool,
  showTable: PropTypes.bool,
  showUnfinished: PropTypes.func,
  tableContent: PropTypes.array,
  unfinishedOnes: PropTypes.number
};

const mapStateToProps = ({ order }) => ({
  clientCounts: order.clientCounts
});

export default connect(mapStateToProps, null)(memo(DashMain));
