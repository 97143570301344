import React from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WorkflowStep from '../../WorkflowStep/WorkflowStep'
import styled from 'styled-components';
import ReturnIcon from './return-icon.png'
import Frame from './Frame.png'
import { BodyText, List, BottomBox } from '../commonStyledComponent';


export const ReturnToParts = ({
    nextStep,
    step,
    rmaScanPartsLabelUrl,
    rmaScanPartsTrackingNum,
    rmaScanDistributorName
}) => {
    const goToNextStep = () => {
        open(rmaScanPartsLabelUrl)
        nextStep(step)
    }
    return <WorkflowStep
        title="Return to Parts distributor"
        subtitle={rmaScanDistributorName ? `Part Distributor: ` + rmaScanDistributorName : null }  
        subtileBold = {true}     
        step={step}
        footer={
            <BottomBox>
                <span onClick={()=>goToNextStep()}>
                    PRINT SHIPPING LABEL
                    <img src={ReturnIcon} alt="icon" />
                </span>
            </BottomBox>
        }
    >
        <div>
            <List>
                <BodyText>Re-package the part into the box</BodyText>
                <BodyText>Confirm there is one part inside of one box</BodyText>
                <BodyText>Print shipping label from the UPS page</BodyText>
                <BodyText>Confirm tracking number matches with UPS label</BodyText>
            </List>
            <BorderedBox><BoxContent><span>Tracking number:</span> {rmaScanPartsTrackingNum}</BoxContent></BorderedBox>
        </div>
        <RightSideImage src={Frame} alt="frame" />
    </WorkflowStep>
}

ReturnToParts.propTypes = {
    rmaScanPartsLabelUrl: PropTypes.string,
    rmaScanPartsTrackingNum: PropTypes.string,
    rmaScanDistributorName: PropTypes.string
};

ReturnToParts.defaultProps = {
    rmaScanPartsLabelUrl: '',
    rmaScanPartsTrackingNum: '',
    rmaScanDistributorName:''
};

const mapStateToProps = ({ rma }) => ({
    rmaScanPartsLabelUrl: rma.rmaScanPartsLabelUrl,
    rmaScanPartsTrackingNum: rma.rmaScanPartsTrackingNum,
    rmaScanDistributorName: rma.rmaScanDistributorName
});

export const ConnectedReturnToParts = connect(mapStateToProps, null)(ReturnToParts);

const BorderedBox = styled.div`
    width: max-content;
    margin-top: 40px;
    height: 79px;
    left: 52px;
    top: 388px;
    background: #F0F0F5;
    border: 1px solid #A5AAAF;
    border-radius: 8px;
`;

const BoxContent = styled.div`
    font-size: 24px;
    line-height: 130%;
    color: #000000;
    margin: 24px;
    span{
        font-weight: 700;

    }
`;


const RightSideImage = styled.img`
    margin: auto;
    width: 377px;
    height: 306px;
`;