import moment from 'moment';
import { userInfo } from './apiUtitlity';
import { CURRENT_TIME, phoneRegex, MONTH } from './enums';

export const isEmpty = input => {
  if (typeof input === 'undefined' || input === 'null') {
    return true;
  }
  if (typeof input === 'function') {
    return false;
  }
  if (Array.isArray(input)) {
    return input.length === 0;
  }
  return !input || Object.keys(input).length === 0;
};

export const isEquivalent = (a, b) => {
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];
    if (a[propName] !== b[propName]) {
      return false;
    }
  }
  return true;
};

export const objectContainsValue = (obj, val) => {
  let valuePresent = false;
  if (obj)
    for (const key of Object.keys(obj)) {
      const value = obj[key];
      if (typeof value === 'string' && value.toLowerCase().includes(val.toLowerCase())) {
        if (
          key === 'kitSequenceNumber' &&
          ((val.length <= 2 && !value.endsWith(val)) || val.length > 2)
        )
          valuePresent = false;
        else valuePresent = true;
      } else if (typeof value === 'number' && toString(value).includes(val)) valuePresent = true;
      else if (typeof value === 'object' && objectContainsValue(value, val)) valuePresent = true;
    }
  else valuePresent = false;
  return valuePresent;
};

export const objectIncludesStringValue = (obj, val) =>
  obj.toString().trim().toLowerCase().includes(val.toString().trim().toLowerCase());

export const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = decodeURIComponent(
    atob(base64Url)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(base64);
};

export const formatPhoneNumber = number =>
  number.toString().replace('-', '').replace(phoneRegex, '$1-$2-$3');

export const convertTimestamp = timestamp => {
  const date = new Date(timestamp);
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
    year: '2-digit',
    month: 'numeric',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short'
  });
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: min },
    ,
    { value: m },
    ,
    { value: tz }
  ] = dateTimeFormat.formatToParts(date);
  return `${month}-${day}-${year} at ${hour}:${min}${m.toLowerCase()} ${tz}`;
};

export const convertDateToES = timestamp => {
  const date = new Date(timestamp);
  const dateTimeFormat = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'America/New_York',
    timeZoneName: 'short'
  });
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: min },
    ,
    { value: m },
    ,
    { value: tz }
  ] = dateTimeFormat.formatToParts(date);
  return `${MONTH[month]} ${parseInt(day)}, ${year} at ${hour}:${min}${m.toLowerCase()} ${tz}`;
};

export const patienceDiff = (aLines, bLines, diffPlusFlag) => {
  const findUnique = (arr, lo, hi) => {
    var lineMap = new Map();

    for (let i = lo; i <= hi; i++) {
      let line = arr[i];
      if (lineMap.has(line)) {
        lineMap.get(line).count++;
        lineMap.get(line).index = i;
      } else {
        lineMap.set(line, { count: 1, index: i });
      }
    }

    lineMap.forEach((val, key, map) => {
      if (val.count !== 1) {
        map.delete(key);
      } else {
        map.set(key, val.index);
      }
    });

    return lineMap;
  };

  const uniqueCommon = (aArray, aLo, aHi, bArray, bLo, bHi) => {
    let ma = findUnique(aArray, aLo, aHi);
    let mb = findUnique(bArray, bLo, bHi);

    ma.forEach((val, key, map) => {
      if (mb.has(key)) {
        map.set(key, { indexA: val, indexB: mb.get(key) });
      } else {
        map.delete(key);
      }
    });

    return ma;
  };

  const longestCommonSubsequence = abMap => {
    var ja = [];

    abMap.forEach((val, key, map) => {
      let i = 0;
      while (ja[i] && ja[i][ja[i].length - 1].indexB < val.indexB) {
        i++;
      }

      if (!ja[i]) {
        ja[i] = [];
      }

      if (0 < i) {
        val.prev = ja[i - 1][ja[i - 1].length - 1];
      }

      ja[i].push(val);
    });

    let lcs = [];
    if (0 < ja.length) {
      let n = ja.length - 1;
      lcs = [ja[n][ja[n].length - 1]];
      while (lcs[lcs.length - 1].prev) {
        lcs.push(lcs[lcs.length - 1].prev);
      }
    }

    return lcs.reverse();
  };

  let result = [];
  let deleted = 0;
  let inserted = 0;

  let aMove = [];
  let aMoveIndex = [];
  let bMove = [];
  let bMoveIndex = [];

  const addToResult = (aIndex, bIndex) => {
    if (bIndex < 0) {
      aMove.push(aLines[aIndex]);
      aMoveIndex.push(result.length);
      deleted++;
    } else if (aIndex < 0) {
      bMove.push(bLines[bIndex]);
      bMoveIndex.push(result.length);
      inserted++;
    }

    result.push({
      line: 0 <= aIndex ? aLines[aIndex] : bLines[bIndex],
      aIndex: aIndex,
      bIndex: bIndex
    });
  };

  const addSubMatch = (aLo, aHi, bLo, bHi) => {
    while (aLo <= aHi && bLo <= bHi && aLines[aLo] === bLines[bLo]) {
      addToResult(aLo++, bLo++);
    }

    let aHiTemp = aHi;
    while (aLo <= aHi && bLo <= bHi && aLines[aHi] === bLines[bHi]) {
      aHi--;
      bHi--;
    }

    let uniqueCommonMap = uniqueCommon(aLines, aLo, aHi, bLines, bLo, bHi);
    if (uniqueCommonMap.size === 0) {
      while (aLo <= aHi) {
        addToResult(aLo++, -1);
      }
      while (bLo <= bHi) {
        addToResult(-1, bLo++);
      }
    } else {
      recurseLCS(aLo, aHi, bLo, bHi, uniqueCommonMap);
    }

    // Finally, let's add the matches at the end to the result.
    while (aHi < aHiTemp) {
      addToResult(++aHi, ++bHi);
    }
  };

  const recurseLCS = (aLo, aHi, bLo, bHi, uniqueCommonMap) => {
    var x = longestCommonSubsequence(
      uniqueCommonMap || uniqueCommon(aLines, aLo, aHi, bLines, bLo, bHi)
    );
    if (x.length === 0) {
      addSubMatch(aLo, aHi, bLo, bHi);
    } else {
      if (aLo < x[0].indexA || bLo < x[0].indexB) {
        addSubMatch(aLo, x[0].indexA - 1, bLo, x[0].indexB - 1);
      }

      let i;
      for (i = 0; i < x.length - 1; i++) {
        addSubMatch(x[i].indexA, x[i + 1].indexA - 1, x[i].indexB, x[i + 1].indexB - 1);
      }

      if (x[i].indexA <= aHi || x[i].indexB <= bHi) {
        addSubMatch(x[i].indexA, aHi, x[i].indexB, bHi);
      }
    }
  };

  recurseLCS(0, aLines.length - 1, 0, bLines.length - 1);

  if (diffPlusFlag) {
    return {
      lines: result,
      lineCountDeleted: deleted,
      lineCountInserted: inserted,
      lineCountMoved: 0,
      aMove: aMove,
      aMoveIndex: aMoveIndex,
      bMove: bMove,
      bMoveIndex: bMoveIndex
    };
  }

  return {
    lines: result,
    lineCountDeleted: deleted,
    lineCountInserted: inserted,
    lineCountMoved: 0
  };
};

export const downloadFile = (filename, content) => {
  const blob = new Blob([content]);
  const url = URL.createObjectURL(blob);
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const downloadFileFromUrl = (res, fileName) => {
  res.blob().then(b => {
    const element = document.createElement('a');
    element.setAttribute('download', fileName);
    element.setAttribute('href', URL.createObjectURL(b));
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  });
};

export const getCurrentTimeStamp = () => localStorage.getItem(CURRENT_TIME);

export const setCurrentTimeStamp = () =>
  localStorage.setItem(CURRENT_TIME, new Date().getTime() / 1000);

export const splitWsnInfo = (wsn, krn) => {
  let month, serialNum, serialAlpha, sjNum;
  let temp = wsn;

  // split string to retrieve month
  month = temp.substring(0, 3);
  temp = wsn.substring(3);

  // split string to retrieve sjNum
  // asume 7 digits total for serialAlpha and serialNum combined
  // example: 000039A
  let idxOfSjNumStart = 7;
  if (krn) { // if krn provided we can just search for it to find where it starts
    idxOfSjNumStart = temp.search(krn);
  }

  const idxOfSjNumEnd = temp.length;
  sjNum = temp.substring(idxOfSjNumStart, idxOfSjNumEnd);
  temp = temp.slice(0, idxOfSjNumStart);

  // split string to retrieve serialAlpha
  serialAlpha = temp.substring(temp.length - 1);
  temp = temp.substring(0, temp.length - 1);

  // serialNum
  serialNum = temp;

  return {
    wsnMonth: month,
    wsnSerialNum: serialNum,
    wsnSerialAlpha: serialAlpha,
    wsnSjNum: sjNum
  };
};

export const removeLeadingZeros = (str) => {
  return parseInt(str, 10).toString();
};

export const validateLuhn = (abcBarcode) => {
  const barcode = Number(abcBarcode.slice(1, -2));
  const barcodeCheck = `A${barcode}${luhn(barcode)}${luhn(barcode + 1)}`;
  const isValidLuhn = abcBarcode === barcodeCheck;

  return isValidLuhn;
}

const luhn = (value) => {
  let nCheck = 0,
    nDigit = 0,
    bEven = true;
  value = value.toString();
  value = value.replace(/D/g, "");

  for (let n = value.length - 1; n >= 0; n--) {
    let cDigit = value.charAt(n),
      nDigit = parseInt(cDigit, 10);

    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }
  return (1000 - nCheck) % 10;
};

export const generateCorrelationID = (jobNumber, flowName) => {
  const formatedDate = moment().format('MMDDYYYY');
  const correlationID = flowName ? (jobNumber ? jobNumber?.split('-')[0] : '') + '_' + formatedDate + '_' + flowName : sessionStorage.getItem('correlationId');
  sessionStorage.setItem('correlationId', correlationID);
  return true;
};
