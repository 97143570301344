import { Get, Post, invUriName, userUriName, userInfo, uriName } from '../utilities/apiUtitlity';
import { updateSessionId } from '../utilities/sessionUtility';
import { hasError, showErrorMessage, showInputErrorMessage } from './errorActions';
import { hideLoading, showLoading } from '../actions/appInteractionActions';
import { GET_RECEIVING_PARTS, getServiceKitOrderDetails } from '../actions/orderActions';

export const SET_RECEIVING_ERROR = 'SET_RECEIVING_ERROR';
export const SET_RECEIVING_SEARCH_STRING = 'SET_RECEIVING_SEARCH_STRING';
export const SET_RECEIVING_SEARCH_BY_OPTION = 'SET_RECEIVING_SEARCH_BY_OPTION';
export const SET_RECEIVING_WSN_FOR_PARTS = 'SET_RECEIVING_WSN_FOR_PARTS';
export const SET_RECEIVING_WSN_LIST_FOR_PARTS = 'SET_RECEIVING_WSN_LIST_FOR_PARTS';
export const SET_COMPLETED_RECEIVED_PARTS = 'SET_COMPLETED_RECEIVED_PARTS';


export const getUserData = () =>{
  return Get(userUriName, `/v2/userinfo?userAccountId=${userInfo.userName}&serviceAdministratorId=43`);
}

export const receivedParts = (parts, completedList) => async (dispatch, getState) => {
    try {
      dispatch(showLoading());
      const serviceOrderDetails = getState().receivingParts.receivingPartsList;
      const receivingSearchString = getState().receivingParts.receivingSearchString;
      const receivingSearchByOption = getState().receivingParts.receivingSearchByOption;

      let partList = [], orderDetail, sjNumber;
      for (let so of serviceOrderDetails.serviceOrder) {
        so.serviceJob.map(sj => {
          sj.orders.map((o) => {
            orderDetail = {
              partsDistributorId: o.partsDistributorId,
              purchaseOrderNumber:
                o.purchaseOrderNumber ?
                o.purchaseOrderNumber :
                receivingSearchString,
              serviceAdministratorId: o.serviceAdministratorId
            }

            orderDetail.trackingNumber =
            o.orderItems.length > 0 ?
            o.orderItems[0].shipment.trackingNumber :
            receivingSearchString;
          });
          sjNumber = sj.serviceJobNumber;
        });
      };
      parts.map((part) => {
        if (part.AsurionBarcode &&
          !completedList.includes(part.PartNumber) &&
          part.needsUserInputAsurionBarcode
        ) {
          partList.push(
            {
              "serviceJobNumber": sjNumber,
              "quantityIncrement": part.Received ? parseInt(part.Received) : 1,
              "partNumber": part.PartNumber,
              "partLineNumber": parseInt(part.PartLineNumber),
              "barCode": part.AsurionBarcode
            }
          );
        }
      })

      // TODO: Part location call needs to be removed after parts team makes the change to accept the physical location#
      const userDataRes = await Get(userUriName, `/v2/userinfo?userAccountId=${userInfo.userName}&serviceAdministratorId=43`);
      if (hasError(userDataRes)) dispatch(showErrorMessage('Unable to receive user details'));
      const locationPostData = {
        companyId: userInfo.companyId,
        serviceProviderLocationNumber: userDataRes.userInfoResponse.userInfoList[0].userInfo.defaultUserLocationId.toString()
      }
      const partLocationDataRes = await Post(invUriName, `/facilities/v2/location/search`, locationPostData);
      if (hasError(partLocationDataRes)) dispatch(showErrorMessage('Unable to receive part location data'));

      const postData =
      {
        "partDistributorId": orderDetail.partsDistributorId,
        "companyId": userInfo.companyId,
        "serviceAdministratorId": orderDetail.serviceAdministratorId,
        "locationIdOrNumber": partLocationDataRes.locations[0].locationId,
        "partList": partList,
        "username": userInfo.userName
      };

      if (receivingSearchByOption === "Tracking/Shipment Number") {
        postData.trackingNumber = orderDetail.trackingNumber;
      } else if (receivingSearchByOption === "Order/Reference Number") {
        postData.purchaseOrderNumber = orderDetail.purchaseOrderNumber;
      }

      const res = await Post(invUriName, `/receiving/v1/receiveitems`, postData);

      dispatch(hideLoading());
      if (res.errors) {
        let error = {
          message: 'Error please contact System Administrator.'
        };

        if (res.errors.length > 0) {
          error.message = res.errors[0].description;
        } else {
          error.message = res.errors.message;
        }
        // receiveitems API returned some error
        dispatch(setReceivingError(error));
      } else {
        if (res.partList.length > 0) {
          const partNumber = res.partList[0].partNumber;
          let partNumList = [];

          if (partNumber !== "") {
            const searchParams = {
              searchString: receivingSearchString,
              searchByOption: receivingSearchByOption,
            };

            await dispatch(getServiceKitOrderDetails(searchParams, true, partNumber));
          }

          for (let part of res.partList) {
            partNumList.push(part.partNumber);
          }
          dispatch(setCompletedReceivedParts(partNumList));
        } else {
          dispatch(setReceivingError({ message: 'Receiving issue no parts in API response. Contact System Administrator.' }));
        }
      }
    } catch (error) {
      if (error.Error.Message) {
        const errObj = {
          message: error.Error.Message
        };

        dispatch(setReceivingError(errObj));
      }
      dispatch(hideLoading());
    }
};

export const receivingUnknownShipment = (unknownShipment) => async (dispatch, getState) => {
  try {
    if (userInfo.sessionId === '') updateSessionId();

    const postData = {
      'unknownPackage': {
        'tracking_nbr': unknownShipment.unknownPackage.trackingNum,
        'vendor_id': unknownShipment.unknownPackage.vendorId,
        'received_date': new Date(unknownShipment.unknownPackage.receivedDate).toISOString(),
        'location_id': userInfo.defaultUserLocationId,
        'service_type': unknownShipment.unknownPackage.serviceType
      },
      'unknownParts': []
    };

    // map UI form properties to API properties
    postData.unknownParts = unknownShipment.unknownParts.map((item) => {
      return {
        'reason_code': 'UnknownPackage',
        'service_job_nbr': item.serviceJobNum,
        'vendor_order_nbr': item.vendorOrderNum,
        'purchase_order_nbr': item.orderNum,
        'vendor_sku': item.itemSku,
        'dax_sku_nbr': item.itemDistrNum,
        'serial_nbr': '',
        'item_description': item.itemName,
        'quantity_received': item.inventoryQuantity
      }
    });

    dispatch(showLoading());
    const res = await Post(uriName, `/v1/servicekit/unknown-shipment`, postData);
    dispatch(hideLoading());

    return res;
  } catch (error) {
    return error;
  }
}

export const setReceivingError = payload => ({
  type: SET_RECEIVING_ERROR,
  payload
});

export const clearReceivingError = () => ({
  type: SET_RECEIVING_ERROR,
  payload: {}
});

export const clearReceivingParts = () => ({
  type: GET_RECEIVING_PARTS,
  payload: {}
});

export const setReceivingSearchString = payload => ({
  type: SET_RECEIVING_SEARCH_STRING,
  payload
});

export const setReceivingSearchByOption = payload => ({
  type: SET_RECEIVING_SEARCH_BY_OPTION,
  payload
});

export const setWsnForReceivedParts = payload => ({
  type: SET_RECEIVING_WSN_FOR_PARTS,
  payload
});

export const setWsnListForReceivedParts = payload => ({
  type: SET_RECEIVING_WSN_LIST_FOR_PARTS,
  payload
});

export const clearWsnForReceivedParts = () => ({
  type: SET_RECEIVING_WSN_FOR_PARTS,
  payload: ''
});

export const setCompletedReceivedParts = payload => ({
  type: SET_COMPLETED_RECEIVED_PARTS,
  payload
});

export const clearCompletedReceivedParts = () => ({
  type: SET_COMPLETED_RECEIVED_PARTS,
  payload: []
});
