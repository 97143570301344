import React, { ReactElement } from 'react';
import styled from 'styled-components';

export const Button = ({
    title,
    onClick,
    loading,
    children,
    disabled = false,
    ...rest
}) => {
    return <ButtonStyle {...rest} onClick={onClick} disabled={loading || disabled}><span>{title || children}</span></ButtonStyle>
}


const ButtonStyle = styled.button`
    background: #8223D2;
    border-radius: 9999px;
    color: white;
    opacity: ${props => props?.disabled ? '0.8' : ""};
    border-radius: 6px;
    height: 48px;
    min-width: 136px;
    width: fit-content;
    margin: auto;
    border: transparent;
    cursor: ${props => props?.disabled ? 'not-allowed' : "pointer"}; ;
    padding: 15px;
    

    @media (max-width: 480px) {
        font-size: 24px;
        width: auto;
        height: 72px;
        padding: 20px;
        min-width: 300px;
    }
`;
