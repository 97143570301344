import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import Button from '../../../Common/Button/Button';
import Col from '../../../Common/Column';
import ReceivingPartSearch from './ReceivingPartSearch';
import ReceiveTable from './ReceiveTable';
import ReceiveByTrackingNumOrOrderNum from './ReceiveByTrackingNumOrOrderNum';
import './ReceivingParts.scss';
import {
  clearReceivingParts,
  clearReceivingError,
  setReceivingSearchString,
  setReceivingSearchByOption,
  clearWsnForReceivedParts,
  clearCompletedReceivedParts,
  setWsnListForReceivedParts
} from '../../../../actions/receiveActions';
import logo from './asurion_logo_black_rgb.svg';

class ReceivingParts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      searchByOption: '',
      searchFlag: false
    };
    this.onSearchType = this.onSearchType.bind(this);
    this.goToSearch = this.goToSearch.bind(this);
  }

  onSearchType = value => {
    this.setState({
      searchString: value.searchString.trim(),
      searchByOption: value.searchByOption,
      searchFlag: value.searchSubmitted
    });
  };

  goToSearch = () => {
    this.props.clearReceivingParts();
    this.props.clearReceivingError();
    this.props.setReceivingSearchString('');
    this.props.setReceivingSearchByOption('');
    this.props.clearWsnForReceivedParts();
    this.props.clearCompletedReceivedParts();
    this.props.setWsnListForReceivedParts([]);

    this.setState({
      searchFlag: false
    });
  }

  searchDetail = (serviceOrderDetails) => {
    let orders, shipment, trackingNo, carrier, carrierCode;
    for (let so of serviceOrderDetails.serviceOrder) {
      so.serviceJob.map(sj => {
        orders = sj.orders;
      });
    };

    orders.map(o => {
      shipment = o.orderItems.map(oi => oi.shipment.trackingNumber);
      carrier = o.orderItems.map(oi => oi.shipment.shippingCarrierCode);
      const uniqueShipment = [... new Set(shipment)];
      const uniqueCarrier = [... new Set(carrier)];
      trackingNo = uniqueShipment.length > 0 ? uniqueShipment.join(',') : uniqueShipment;
      carrierCode = uniqueCarrier.length > 0 ? uniqueCarrier.join(',') : uniqueCarrier;
    });

    let convertShipmentDate = new Date(orders[0].orderItems[0].shipment.shipmentDate);
    convertShipmentDate =
      (convertShipmentDate instanceof Date && !isNaN(convertShipmentDate)) &&
        convertShipmentDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })

    return {
      "Order #": orders[0].purchaseOrderNumber,
      "Tracking/shipment #": trackingNo,
      "Carrier": carrierCode,
      "Distributor": orders[0].partsDistributorName,
      "Shipped date": convertShipmentDate,
      "Program": "Major Appliance"
    }
  }
  orderItems = (serviceOrderDetails) => {
    let ordersItems = [];
    for (let so of serviceOrderDetails.serviceOrder) {
      so.serviceJob.map(sj => {
        sj.orders.map((o) => {
          o.orderItems.map(oi => {
            ordersItems.push({
              "PartNumber": oi.partNumber,
              "Description": oi.partDescription,
              "Ordered": oi.quantityOrderded,
              "Received": oi.quantityReceived,
              "AsurionBarcode": oi.shipment.barCode,
              "ReceiveDate": oi.shipment.receivedDate,
              "PartLineNumber": oi.partLineNumber
            });
          })
        });
      });
    };
    return ordersItems;
  }

  render() {
    const receivingContent = (
      <div className="receive_container">
        <Col oneoffour>
          <div className="receive-left-content">
            {!isEmpty(this.props.serviceOrderDetails) && this.state.searchFlag ?
              <ReceiveByTrackingNumOrOrderNum
                goBack={this.goToSearch}
                shipmentList={this.searchDetail(this.props.serviceOrderDetails)}
                searchByOption={this.state.searchByOption}></ReceiveByTrackingNumOrOrderNum> :
              <ReceivingPartSearch onSearch={this.onSearchType}></ReceivingPartSearch>
            }
          </div>
        </Col>
        <Col threeoffour>
          <div className="receive-right-content">
            {!isEmpty(this.props.serviceOrderDetails) && this.state.searchFlag ?
              <ReceiveTable
                orders={this.orderItems(this.props.serviceOrderDetails)}
                resetSearch={this.goToSearch}
              /> :
              <ReceiveTable orders={[]} />
            }
          </div>
        </Col>
      </div>
    );

    return (
      <div>
        <div className="receive-header__left">
          <img className="receive-app-logo" src={logo} alt="Asurion Logo" onClick={() => {
            this.props.clearReceivingError();
            this.props.backToFSLDashboard();
          }} />
        </div>
        <div>
          <div className="receive_item_header_label">
            <label>Receive Inventory</label>
          </div>
          {receivingContent}
        </div>
      </div>
    )
  }
}

ReceivingParts.propTypes = {
  serviceOrderDetails: PropTypes.object,
  clearReceivingParts: PropTypes.func,
  clearReceivingError: PropTypes.func,
  setReceivingSearchString: PropTypes.func,
  setReceivingSearchByOption: PropTypes.func,
  clearWsnForReceivedParts: PropTypes.func,
  clearCompletedReceivedParts: PropTypes.func,
  setWsnListForReceivedParts: PropTypes.func
};

const mapStateToProps = ({ receivingParts }) => ({
  serviceOrderDetails: receivingParts.receivingPartsList
});

const mapDispatchToProps = dispatch => bindActionCreators({
  clearReceivingParts,
  clearReceivingError,
  setReceivingSearchString,
  setReceivingSearchByOption,
  clearWsnForReceivedParts,
  clearCompletedReceivedParts,
  setWsnListForReceivedParts
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReceivingParts);
