import React, { Component } from 'react';
import './HelpButton.scss';
import HelpIcon from '../../../../icons/icon-help--asurion-primary.svg';

class HelpButton extends Component {
  handleClick = () => {
    if (this.props.isHelpDrawerActive) {
      this.props.closeHelpDrawer();
    } else {
      this.props.openHelpDrawer();
    }
  };
  render() {
    return (
      <button onClick={this.handleClick.bind(this)} className="workflow-help-button">
        <img src={HelpIcon} alt="" className="workflow-help-button__icon" />
        Help
      </button>
    );
  }
}

export default HelpButton;
