import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import { getClientId, getClientName } from '../../../../utilities/clientUtility';
import { splitWsnInfo, removeLeadingZeros } from '../../../../utilities/genericUtility';

import {
  dashboardApiCall,
  getOrders,
  selectClientForWorkflow,
  updateTotalCount
} from '../../../../actions/orderActions';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import SuccessIcon from '../../../../icons/done-checkmark.svg';
import WarningIcon from '../../../../icons/warning.png';
import './restagingConfirmation.scss';

const DoneInfo = ({ infoText, icon }) => (
  <div className="restaging-done-info">
    <img src={icon} alt="" />
    <h1 className="restaging-done-info-h1">
      {infoText}
    </h1>
  </div>
);

class RestagingConfirmation extends Component {
  closeWorkflow = () => {
    // ensure we clear out restaging workflow state
    this.props.handleIsMissingPartsWorkflow(false);

    this.props.closeWorkflow();
  }

  render() {
    const { kitInfo } = this.props;
    const kitNumber = kitInfo.serviceKitNumber;
    const buttonLabel = 'Next Shipment';
    let displayTexts = {
      doneInfo: 'Restage Completed',
      doneIcon: SuccessIcon,
      kitStatusInfo: 'You have restaged',
      nextStepInfo: 'You can place the shipments on the Active Job Rack.'
    };

    if (this.props.missingPartsWorkflow) {
      displayTexts = {
        doneInfo: 'Restage - Action Required',
        doneIcon: WarningIcon,
        kitStatusInfo: 'You have started restaging',
        nextStepInfo: 'You can place the shipment back on the Restaging Rack.'
      };
    }

    return (
      <div className="workflow-main">
        <CSSTransitionGroup
          transitionName="workflow-step"
          transitionAppear={true}
          transitionAppearTimeout={5000}
          transitionEnterTimeout={5000}
          transitionLeaveTimeout={3000}
        >
          {1==1 && (
            <div className="workflow-step">
              <div className="workflow-step__main">
                <div className="workflow-step__messaging restaging-confirmation">
                  <DoneInfo infoText={displayTexts.doneInfo} icon={displayTexts.doneIcon} />
                  <div className="restaging-completed-main">
                    <span className="restage-text">{displayTexts.kitStatusInfo} {
                      splitWsnInfo(kitNumber).wsnMonth +
                      removeLeadingZeros(splitWsnInfo(kitNumber).wsnSerialNum) +
                      splitWsnInfo(kitNumber).wsnSerialAlpha}.</span>
                  </div>
                  <div>
                  <span className="place-shipment-text">{displayTexts.nextStepInfo}</span>
                  </div>
                </div>
                
              </div>
              <div className="workflow-step__footer">
                <PrimaryButton text={buttonLabel} onClick={this.closeWorkflow} />
              </div>
            </div>
          )}
        </CSSTransitionGroup>
        <div className="dashboard-link" onClick={this.closeWorkflow}>
          <span>GO TO DASHBOARD</span>
        </div>
      </div>
    );
  }
}

RestagingConfirmation.defaultProps = {
  kitInfo: {},
  orderToPack: -1
};

RestagingConfirmation.propTypes = {
  getOrders: PropTypes.func,
  kitInfo: PropTypes.object,
  orderToPack: PropTypes.number,
  processingStarted: PropTypes.bool,
  handleIsMissingPartsWorkflow: PropTypes.func
};

const mapStateToProps = ({ order, serviceKit, websocket }) => {
  const { orderToPack, selectedClient, serviceKitDisplayRows } = order;
  const { processingServiceKit, processingStarted } = serviceKit;
  return {
    kitInfo: processingServiceKit,
    orderToPack,
    processingStarted,
    selectedClient,
    serviceKitOrders: serviceKitDisplayRows,
    wsconnected: websocket.connected
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrders,
      selectClientForWorkflow,
      updateTotalCount
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RestagingConfirmation);
