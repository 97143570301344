import React, { useCallback, useReducer, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { receivingUnknownShipment, clearReceivingError } from '../../../../actions/receiveActions';
import CheckMark from '../../../../icons/checkmark.svg';
import IconClose from '../../../../icons/icon-close.svg';
import './UnknownShipmentForm.scss';

const initialFormState = {
    unknownPackage: {
        serviceType: 'ma',
        trackingNum: '',
        vendorId: 'PDMARCONE',
        receivedDate: new Date().toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
    },
    unknownParts: [
        {
            serviceJobNum: '',
            orderNum: '',
            itemName: '',
            itemSku: '',
            inventoryQuantity: '',
            vendorOrderNum: '',
            itemDistrNum: ''
        }
    ]
};

const formStateReducer = (state, action) => {
    switch (action.type) {
        case 'serviceType':
            return {
                ...state,
                unknownPackage: {
                    ...state.unknownPackage,
                    serviceType: action.payload
                }
            }
        case 'trackingNum':
            return {
                ...state,
                unknownPackage: {
                    ...state.unknownPackage,
                    trackingNum: action.payload
                }
            }
        case 'vendorId':
            return {
                ...state,
                unknownPackage: {
                    ...state.unknownPackage,
                    vendorId: action.payload
                }
            }
        case 'receivedDate':
            return {
                ...state,
                unknownPackage: {
                    ...state.unknownPackage,
                    receivedDate: action.payload
                }
            }
        case 'serviceJobNum':
        case 'orderNum':
        case 'itemName':
        case 'itemSku':
        case 'inventoryQuantity':
        case 'vendorOrderNum':
        case 'itemDistrNum':
            const newUnknownPartsArr = JSON.parse(JSON.stringify(state.unknownParts));
            newUnknownPartsArr[action.idx][action.type] = action.payload;

            return {
                ...state,
                unknownParts: newUnknownPartsArr
            }
        case 'unknownPartsAddNewItem':
            const newItem = {
                serviceJobNum: '',
                orderNum: '',
                itemName: '',
                itemSku: '',
                inventoryQuantity: '',
                vendorOrderNum: '',
                itemDistrNum: ''
            };
            return {
                ...state,
                unknownParts: [
                    ...state.unknownParts,
                    newItem
                ]
            }
        case 'unknownPartsRemoveItem':
            const newUnknownPartsArrTwo = JSON.parse(JSON.stringify(state.unknownParts));
            newUnknownPartsArrTwo.splice(action.payload, 1);

            return {
                ...state,
                unknownParts: newUnknownPartsArrTwo
            }
        default:
            return { ...state }
    }
}

const UnknownShipmentForm = (props) => {
    const [formStep, setFormStep] = useState(1);
    const [requireUserInput, setRequireUserInput] = useState(true);
    const [formValuesState, dispatch] = useReducer(formStateReducer, initialFormState);
    const [displayError, setDisplayError] = useState('');

    useEffect(() => {
        let requireUserInputFlag = true;

        switch (formStep) {
            case 1:
                if (formValuesState.unknownPackage.trackingNum &&
                    formValuesState.unknownPackage.receivedDate) {
                        const isValidDate = moment(formValuesState.unknownPackage.receivedDate, 'MM/DD/YYYY', true).isValid();
                        const isTrackingNumberValid = formValuesState.unknownPackage.trackingNum !== null;

                        if (isValidDate && isTrackingNumberValid) {
                            requireUserInputFlag = false;
                        }
                    }
                break;
            case 2:
                let countFilledInputs = [];
                if (formValuesState.unknownParts.length > 0) {
                    formValuesState.unknownParts.forEach((unknownPart) => {
                        let countFilledInputsPerItem = 0;
                        if (unknownPart.serviceJobNum !== '') countFilledInputsPerItem++;
                        if (unknownPart.orderNum !== '') countFilledInputsPerItem++;
                        if (unknownPart.itemName !== '') countFilledInputsPerItem++;
                        if (unknownPart.itemSku !== '') countFilledInputsPerItem++;
                        if (unknownPart.inventoryQuantity !== '') countFilledInputsPerItem++;
                        if (unknownPart.vendorOrderNum !== '') countFilledInputsPerItem++;
                        if (unknownPart.itemDistrNum !== '') countFilledInputsPerItem++;

                        countFilledInputs.push(countFilledInputsPerItem);
                    });
                }

                if (countFilledInputs.filter(count => count >= 1).length === formValuesState.unknownParts.length) requireUserInputFlag = false;
                break;
            case 3:
                break;
            default:
                break;
        }

        setRequireUserInput(requireUserInputFlag);
    }, [formValuesState, formStep]);

    const cancelOnClick = useCallback((evt) => {
        evt.preventDefault();
        props.closeModal();
    }, [props.closeModal]);

    const nextOnClick = useCallback((valuesToSubmit) => async (evt) => {
        evt.preventDefault();

        if (formStep < 2) {
            setFormStep(formStep + 1);
        } else if (formStep === 2) {
            setDisplayError('');

            await props.receivingUnknownShipment(valuesToSubmit).then((result) => {
                if (result.Error) {
                    setDisplayError(result.Error.Message);
                } else if (result === 'success') {
                    setFormStep(formStep + 1);
                }
            });
        } else if (formStep === 3) {
            props.clearReceivingError();
            props.closeModal();
        }
    }, [formStep]);

    const formAddItem = useCallback((evt) => {
        evt.preventDefault();
        dispatch({type: 'unknownPartsAddNewItem'});
    });

    const updateFormState = useCallback((idx) => (evt) => {
        const { name, value } = evt.target;
        dispatch({type: name, payload: value, idx});
    });

    const handleRemoveItem = useCallback((idx) => (evt) => {
        dispatch({type: 'unknownPartsRemoveItem', payload: idx});
      });

    const formOne = () => {
        const serviceTypeList = [
            {
                displayValue: 'Major Appliance',
                value: 'ma'
            }
        ];
        const vendorIdList = [
            {
                displayValue: 'PDMARCONE',
                value: 'PDMARCONE'
            },
            {
                displayValue: 'PDVANDV',
                value: 'PDVANDV'
            },
            {
                displayValue: 'PDTRIBLES',
                value: 'PDTRIBLES'
            },
            {
                displayValue: 'PDRELIABLE',
                value: 'PDRELIABLE'
            },
            {
                displayValue: 'PDVANCEBALDWIN',
                value: 'PDVANCEBALDWIN'
            },
            {
                displayValue: 'PDGSPNSAMSUNG',
                value: 'PDGSPNSAMSUNG'
            }
        ];

        return (
            <>
                <span className="unknown-shipment-description">Fill out the information below.</span>
                <span className="shipment-info">Shipment information</span>
                <select
                    name="serviceType"
                    className="shipment-servicetype"
                    disabled={true}
                    value={formValuesState.unknownPackage.serviceType}
                    onChange={updateFormState(null)}
                >
                    {
                        serviceTypeList.map((serviceTypeLabel) => (
                            <option
                                key={`${serviceTypeLabel.displayValue}-key`}
                                value={serviceTypeLabel.value}
                            >
                                {serviceTypeLabel.displayValue}
                            </option>
                        ))
                    }
                </select>
                <input
                    name="trackingNum"
                    className="shipment-tracking"
                    value={formValuesState.unknownPackage.trackingNum}
                    placeholder="Tracking number"
                    autoComplete="off"
                    onChange={updateFormState(null)}
                />
                <select
                    name="vendorId"
                    className="shipment-vendor"
                    value={formValuesState.unknownPackage.vendorId}
                    onChange={updateFormState(null)}
                >
                    {
                        vendorIdList.map((vendorIdLabel) => (
                            <option
                                key={`${vendorIdLabel.displayValue}-key`}
                                value={vendorIdLabel.value}
                            >
                                {vendorIdLabel.displayValue}
                            </option>
                        ))
                    }
                </select>
                <input
                    name="receivedDate"
                    className="shipment-received-date"
                    value={formValuesState.unknownPackage.receivedDate}
                    placeholder="Received date"
                    autoComplete="off"
                    onChange={updateFormState(null)}
                />
            </>
        );
    }

    const formTwo = () => {
        const inputElementPropertiesArr = [
            {
                name: 'serviceJobNum',
                className: 'inventory-service-job-num',
                placeholder: 'Service job number',
                autoComplete: 'off'
            },
            {
                name: 'orderNum',
                className: 'inventory-order-num',
                placeholder: 'Order number',
                autoComplete: 'off'
            },
            {
                name: 'itemName',
                className: 'inventory-item-name',
                placeholder: 'Item name',
                autoComplete: 'off'
            },
            {
                name: 'itemSku',
                className: 'inventory-item-sku',
                placeholder: 'Item SKU',
                autoComplete: 'off'
            },
            {
                name: 'inventoryQuantity',
                className: 'inventory-quantity',
                placeholder: 'Quantity received',
                autoComplete: 'off'
            },
            {
                name: 'vendorOrderNum',
                className: 'inventory-vendor-order-num',
                placeholder: 'Vendor order number',
                autoComplete: 'off'
            },
            {
                name: 'itemDistrNum',
                className: 'inventory-item-distr-num',
                placeholder: 'Item distributor number',
                autoComplete: 'off'
            }
        ];
        return (
          <>
            <span className="unknown-shipment-description-inventory">
              Fill out as much information as you can per item.
            </span>
            <div className="unknown-shipment-inventory-items">
              <span className="inventory-info">Inventory information</span>

              {formValuesState.unknownParts.map((part, idx) => (
                <div key={`unknown-parts-${idx}`} className="inventory-info-item-container">
                  <div className="inventory-item-label-container">
                    <span>Item #{idx + 1}</span>
                    <span className="inventory-item-remove" onClick={handleRemoveItem(idx)}>remove</span>
                  </div>

                  <div className="inv-container">
                    {inputElementPropertiesArr.map(element => (
                      <div className="inv-container-item" key={`${element.name}-input`}>
                        <input
                          name={element.name}
                          className={`${element.className} inv-container-item-width`}
                          value={formValuesState.unknownParts[idx][element.name]}
                          placeholder={element.placeholder}
                          autoComplete={element.autoComplete}
                          onChange={updateFormState(idx)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="inventory-item-add-container">
              <span className="inventory-item-add" onClick={formAddItem}>
                Add another item
              </span>
            </div>
          </>
        );
    }

    return (
        <div className="receiving-unknown-shipment-container">
            <div className="unknown-shipment-header">
                {
                    formStep === 3 ?
                        (
                            <>
                                <img
                                    className="unknown-shipment-checkmark"
                                    src={CheckMark}
                                    alt="Check Mark"
                                />
                                <span className="unknown-shipment-confirm-message">Thanks! The information has been sent.</span>
                            </>
                        ) :
                        (<span className="title">Unknown shipment form - {formStep} of 2</span>)
                }
                <img
                    className="unknown-shipment-close"
                    onClick={props.closeModal}
                    src={IconClose}
                    alt="Close icon"
                />
            </div>
            <div className="unknown-shipment-body">
                {
                    formStep === 1 ?
                        formOne() :
                        formStep === 2 ?
                            formTwo() :
                            formStep === 3 ?
                                <></> :
                                <></>
                }
            </div>
            <div className={`unknown-shipment-footer ${formStep !== 3 ? 'top-border' : ''}`}>
                {
                    displayError &&
                    <div className="error-msg-container">
                        <span className="error-msg">*{displayError}</span>
                    </div>
                }
                <div className="btn-container">
                    {formStep !== 3 && <button className="cancel-btn" onClick={cancelOnClick}>Cancel</button>}
                    <button
                        className={`next-btn ${formStep === 3 ? false : requireUserInput ? 'next-btn-disabled' : ''}`}
                        onClick={nextOnClick(formValuesState)}
                        disabled={formStep === 3 ? false : requireUserInput}
                    >
                        {
                            formStep < 2 ?
                                'Next' :
                                formStep === 2 ?
                                    'Submit' :
                                    formStep === 3 ?
                                        'Done' :
                                        ''
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

UnknownShipmentForm.propTypes = {
    receivingUnknownShipment: PropTypes.func,
    closeModal: PropTypes.func
};
  
UnknownShipmentForm.defaultProps = {
};

const mapDispatchToProps = dispatch => bindActionCreators({
    receivingUnknownShipment,
    clearReceivingError
}, dispatch);

export default connect(null, mapDispatchToProps)(UnknownShipmentForm);
