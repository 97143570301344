import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Barcode from 'react-barcode';
import PropTypes from 'prop-types';
import Row from '../Common/Row';
import Col from '../Common/Column';
import { splitWsnInfo, removeLeadingZeros } from '../../utilities/genericUtility';
import './PackingLabel.scss';

class PackingLabel extends PureComponent {
  render() {
    const { serviceKitNumber } = this.props.kitInfo;
    const { wsnMonth, wsnSerialNum, wsnSerialAlpha, wsnSjNum } = splitWsnInfo(serviceKitNumber);

    return (
      <div className={this.props.class}>
        <Row>
          <Col oneofone>
            <div className="barcode">
              <Barcode
                displayValue={false}
                height={100}
                width={2}
                marginTop={-5}
                marginBottom={-40}
                text={serviceKitNumber}
                value={serviceKitNumber}
              />
            </div>
            <span className="barcodeText">
              {serviceKitNumber ? serviceKitNumber : ''}
            </span>
            <span className="barcodeSerial">
              {wsnMonth}-{removeLeadingZeros(wsnSerialNum)}{wsnSerialAlpha}
            </span>
          </Col>
        </Row>
      </div>
    );
  }
}

PackingLabel.defaultProps = {
  kitInfo: {}
};

PackingLabel.propTypes = {
  kitInfo: PropTypes.object
};

const mapStateToProps = ({ serviceKit }) => {
  return {
    kitInfo: serviceKit.processingServiceKit
  };
};

export default connect(mapStateToProps, null)(PackingLabel);
