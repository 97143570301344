import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTable from 'react-table';
import Button from '../../../Common/Button/Button';
import ModalRouter from '../../../Modal/ModalRouter/ModalRouter';
import './ReceiveTable.scss';
import { receivedParts, clearReceivingError } from '../../../../actions/receiveActions';
import ReceivingWsnPrintLabel from '../../../ReceivingWsnPrintLabel/ReceivingWsnPrintLabel';
import CheckMark from '../../../../icons/checkmark-no-circle.svg';

let inputChangeTimeout;
class RecieveTable extends Component {

  constructor(props) {
    super(props);
    const ordersWithFormState = this.generateFormStateForOrders(props.orders);

    this.state = {
      orders: ordersWithFormState,
      inputChanged: false,
      wsnForPartsReceived: '',
      modal: false,
      completedReceivingPartsList: []
    };
    this.updateInventory = this.updateInventory.bind(this);
    this.processInput = this.processInput.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.props &&
      this.props.orders.length > 0 &&
      JSON.stringify(this.props.orders) !== JSON.stringify(prevProps.orders) &&
      JSON.stringify(this.state.orders) !== JSON.stringify(this.props.orders)
    ) {
      const ordersWithFormState = this.generateFormStateForOrders(this.props.orders);

      this.setState({
        orders: ordersWithFormState
      });
    } else if (this.props &&
      this.props.orders.length === 0 &&
      JSON.stringify(this.props.orders) !== JSON.stringify(prevProps.orders) &&
      JSON.stringify(this.state.orders) !== JSON.stringify(this.props.orders)
    ) {
      this.setState({
        orders: [],
        inputChanged: false
      });
    }

    if (this.props &&
      this.props.completedReceivedParts.length > 0 &&
      JSON.stringify(this.props.completedReceivedParts) !== JSON.stringify(prevProps.completedReceivedParts) &&
      JSON.stringify(this.state.completedReceivingPartsList) !== JSON.stringify(this.props.completedReceivedParts)
    ) {
      this.setState({
        completedReceivingPartsList: this.props.completedReceivedParts
      });
    } else if (this.props &&
      this.props.completedReceivedParts.length  === 0 &&
      JSON.stringify(this.props.completedReceivedParts) !== JSON.stringify(prevProps.completedReceivedParts) &&
      JSON.stringify(this.state.completedReceivingPartsList) !== JSON.stringify(this.props.completedReceivedParts)
    ) {
      this.setState({
        completedReceivingPartsList: []
      });
    }
  }

  generateFormStateForOrders = (orders) => {
    let ordersToReturn = [];
    // adding form state for each order
    if (orders.length > 0) {
      const copyOrders = JSON.parse(JSON.stringify(orders));
      ordersToReturn = copyOrders.map(o => {
        return {
          ...o,
          needsUserInputReceived: false,
          needsUserInputAsurionBarcode: false
        }
      });

      // determine which order still needs input from user
      for (let o of ordersToReturn) {
        if (o.Received === "") {
          o.needsUserInputReceived = true;
        }
        if (o.AsurionBarcode === "") {
          o.needsUserInputAsurionBarcode = true;
        }
      }
    }

    return ordersToReturn;
  }

  updateInventory = () => {
    console.log("Update Inventory !!")
  }

  printWsnLabels = () => {
    const printAfterStateChangeFn = () => {
      window.print();
      this.openModal('ReceivingComplete')
      this.setState({
        wsnForPartsReceived: ''
      });
    }

    if (this.props.wsnListForPartsReceived.length === 1) {
      this.setState({
        wsnForPartsReceived: this.props.wsnListForPartsReceived[0]
      }, printAfterStateChangeFn);
    } else if(this.props.wsnListForPartsReceived.length > 1) {
      this.setState({
        wsnForPartsReceived: this.props.wsnListForPartsReceived[0]
      }, printAfterStateChangeFn);
    } else {
      // no WSN to print, how did we get here? We shouldn't be able to.
      console.log('WSN print error')
    }
  }

  processInput = (value, name, columnType) => {
    const copyOrders = JSON.parse(JSON.stringify(this.state.orders));

    switch (columnType) {
      case 'receivedColumn':
        for (let oi of copyOrders) {
          if (oi.PartLineNumber === name) {
            oi.Received = value;

            if (!this.state.inputChanged &&
              oi.AsurionBarcode !== "") {
              this.setState({
                inputChanged: true
              });
            }
          }
        };
        break;
      case 'abcColumn':
        for (let oi of copyOrders) {
          if (oi.PartLineNumber === name) {
            oi.AsurionBarcode = value;

            if (!this.state.inputChanged &&
              oi.AsurionBarcode !== "") {
              this.setState({
                inputChanged: true
              });
            }
          }
        };
        break;
      default:
        break;
    }

    this.setState({
      orders: copyOrders
    });
  }

  handleChange = (evt, columnType) => {
    const { value, name } = evt.target;

    clearTimeout(inputChangeTimeout);
    inputChangeTimeout = setTimeout(
      () => this.processInput(value, name, columnType),
      500
    );
  }

  checkAllowReceiving = (orders) => {
    let allowReceiving = false;

    for (let idx = 0; idx < orders.length; idx++) {
      if (!allowReceiving &&
          orders[idx].AsurionBarcode !== "" &&
          orders[idx].needsUserInputAsurionBarcode) {
        console.log(orders[idx])
        allowReceiving = true;
      }
    }

    return allowReceiving;
  }

  openModal = newModal => {
    this.setState({
      modal: newModal
    });
  };

  closeModal = () => {
    this.setState({ modal: false });
  };

  resetForNextShipment = () => {
    this.props.resetSearch();
    this.closeModal();
  };

  render() {
    const isModalActive = this.state.modal;
    let columns = [
      {
        Header: 'Part Number',
        accessor: 'PartNumber',
        Cell: props => (
          <div className="tablecell-wrapper">
            <span className={`tablecell-value ${this.state.completedReceivingPartsList.includes(props.original.PartNumber) ? 'tablecell-value-gray' : null}`}>
              {props.value}
            </span>
          </div>
        ),
        width: 250
      },
      {
        Header: 'Description',
        accessor: 'Description',
        Cell: props => (
          <div className="tablecell-wrapper">
            <span className={`tablecell-value ${this.state.completedReceivingPartsList.includes(props.original.PartNumber) ? 'tablecell-value-gray' : null}`}>
              {props.value}
            </span>
          </div>
        ),
        width: 350
      },
      {
        Header: 'Ordered',
        accessor: 'Ordered',
        Cell: props => (
          <div className="tablecell-wrapper">
            <span
              title={props.value}
              className={`tablecell-value ${this.state.completedReceivingPartsList.includes(props.original.PartNumber) ? 'tablecell-value-gray' : null}`}
            >
              {props.value}
            </span>
          </div>
        ),
        width: 150
      },
      {
        Header: 'Received',
        accessor: 'Received',
        Cell: props => {
          const [value, setValue] = useState(props.value ? props.value : 1);

          return (
            (props.original.needsUserInputReceived &&
              !this.state.completedReceivingPartsList.includes(props.original.PartNumber)) ?
              (
                <input
                  className="received-column-input"
                  name={props.original.PartLineNumber}
                  value={value}
                  onChange={(evt) => {
                    setValue(evt.target.value);

                    if (evt.target.value !== '') this.handleChange(evt, 'receivedColumn');
                  }}
                ></input>
              ) :
              (
                <div className="tablecell-wrapper">
                  <span
                    title={value}
                    className={`tablecell-value ${this.state.completedReceivingPartsList.includes(props.original.PartNumber) ? 'tablecell-value-gray' : null}`}
                  >
                    {value}
                  </span>
                </div>
              )
          )
        },
        width: 150
      },
      {
        Header: 'Asurion Barcode',
        accessor: 'AsurionBarcode',
        Cell: props => {
          const [value, setValue] = useState(props.value);

          return (
            (props.original.needsUserInputAsurionBarcode &&
              !this.state.completedReceivingPartsList.includes(props.original.PartNumber)) ?
              (
                <input
                  className="asurionBarcode-column-input"
                  name={props.original.PartLineNumber}
                  value={value}
                  onChange={(evt) => {
                    setValue(evt.target.value);
                    if (evt.target.value !== '') this.handleChange(evt, 'abcColumn');
                  }}
                ></input>
              ) :
              (this.state.completedReceivingPartsList.includes(props.original.PartNumber)) ?
              (
                <>
                    <input
                      disabled={true}
                      className="asurionBarcode-column-input"
                      name={props.original.PartLineNumber}
                      value={value}
                    ></input>
                    <img className="asurionBarcode-column-checkmark" src={CheckMark} alt="Checkmark icon" />
                </>
              ) :
              (
                <div className="tablecell-wrapper">
                  <span
                    title={value}
                  >
                    {value}
                  </span>
                </div>
              )
          );
        },
        width: 220
      },
      {
        Header: 'Received Date',
        accessor: 'ReceiveDate',
        Cell: props => {
          const convertDate = new Date(props.value);

          return (
            <div className="tablecell-wrapper">
              <span className={`tablecell-value ${this.state.completedReceivingPartsList.includes(props.original.PartNumber) ? 'tablecell-value-gray' : null}`}>
                {
                  (convertDate instanceof Date && !isNaN(convertDate)) &&
                  convertDate.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
                }
              </span>
            </div>
          );
        },
        width: 200
      }
    ];
    const disableReceiveBtn = !this.checkAllowReceiving(this.state.orders);
    const disableReceiveBtnNoInput = !this.state.inputChanged;
    const allowPrintWsn = this.props.wsnListForPartsReceived.length > 0;

    return (
      <>
        <div className="receive-table_container">
          <div className="receive-table_label">
            <label className="label-heading">Inventory</label>
          </div>
          <ReactTable
            data={this.state.orders}
            columns={columns}
            sortable={false}
            getTrProps={(state, rowInfo, column) => {
              return {
                onClick: e => {
                  // do nothing
                }
              };
            }}
            minRows={0}
            noDataText="No Information Available."
            showPageSizeOptions={true}
            showPagination={isEmpty(this.state.orders) ? false : this.state.orders.length > 10}
            className="receive-table_detail"
          />
          {isEmpty(this.state.orders) ? <></> :
            (<div className="receive-table-button_container">
              <div className="receive-table-button_1">
                <Button
                  disableFlag={disableReceiveBtn || disableReceiveBtnNoInput}
                  text="Receive scanned parts"
                  className={disableReceiveBtn || disableReceiveBtnNoInput ? "receive-table-button_updateInv-disabled" : "receive-table-button_updateInv"}
                  onClick={() => {
                    this.setState({
                      inputChanged: false
                    });
                    this.props.clearReceivingError();
                    this.props.receivedParts(this.state.orders, this.state.completedReceivingPartsList);
                  }}
                ></Button>
              </div>
              <div className="receive-table-button_1" >
                {/* <Button
              text="Incorrect parts?"
              className="receive-table-button_incorrectPart"
              onClick={this.updateInventory}
            ></Button>
          </div> */}
                <Button
                  disableFlag={!allowPrintWsn}
                  text="Print WSN Labels"
                  className={allowPrintWsn ? "receive-table-button_printWsn" : "receive-table-button_printWsn-disabled"}
                  onClick={this.printWsnLabels}
                ></Button>
              </div>
            </div>)
          }
          <span className={`receiving-error-msg ${this.props.orders.length === 0 ? 'error-msg-extra-padding' : ''}`}>{this.props.errorDetails.message ? `*${this.props.errorDetails.message}` : ''}</span>          
          {isModalActive ? (
            <ModalRouter
              closeModal={this.closeModal}
              modal={this.state.modal}
              nextShipment={this.resetForNextShipment}
            />
          ) : null}
        </div>
        {
          this.state.wsnForPartsReceived !== "" &&
          <ReceivingWsnPrintLabel
            class="wsn-receiving-label--print"
            wsnForReceivedPart={{ serviceKitNumber: this.state.wsnForPartsReceived }}
          />
        }
      </>
    );
  }

};

RecieveTable.propTypes = {
  orders: PropTypes.array,
  receivedParts: PropTypes.func,
  errorDetails: PropTypes.object,
  wsnForPartsReceived: PropTypes.string,
  wsnListForPartsReceived: PropTypes.array,
  completedReceivedParts: PropTypes.array,
  clearReceivingError: PropTypes.func
};

RecieveTable.defaultProps = {
  errorDetails: {
    message: ''
  },
  wsnForPartsReceived: '',
  wsnListForPartsReceived: [],
  completedReceivedParts: []
};

const mapStateToProps = ({ receivingParts }) => ({
  errorDetails: receivingParts.receivingError,
  wsnForPartsReceived: receivingParts.wsnForPartsReceived,
  wsnListForPartsReceived: receivingParts.wsnListForPartsReceived,
  completedReceivedParts: receivingParts.completedReceivedParts
});

const mapDispatchToProps = dispatch => bindActionCreators({
  receivedParts,
  clearReceivingError
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RecieveTable);
