import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    restagingAffixAndScan
} from '../../../../actions/orderActions';
import Col from '../../../Common/Column';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import example from './restaging-barcode-example.png';
import './RestagingAffixAndScan.scss';
import printIcon from './print-icon.png';

class RestagingAffixAndScan extends Component {
    constructor(props) {
        super(props);
    }

    goToNextStep = async () => {
        // do nothing
    };

    onSubmit = async (inputVal) => {
        // API call to restaging affix and scan
        await this.props.restagingAffixAndScan(inputVal);
        if (this.props.restagingAffixAndScanComplete) {
            this.props.nextStep(this.props.step);
        }
    };

    render() {
        const { isprintwsnclicked,kitInfo } = this.props;
        const linkName = isprintwsnclicked ? "REPRINT WSN" : "PRINT WSN";
        return (
            <WorkflowStep
                {...this.props}
                title="Affix WSN label &amp; scan it"
                footer={
                    <PrimaryInput
                        nextStep={this.goToNextStep.bind(this)}
                        onSubmit={this.onSubmit.bind(this)}
                        placeholder="Scan or type in barcode"
                        rules={'required|wsn'}
                        title="WSN barcode"
                        type="barcode"
                    />
                }
                 after={
                    <div className="print-wsn-link" onClick={this.props.handlePrintWsn.bind(this,kitInfo.serviceKitNumber)}>
                        <img src={printIcon} className="restagingPrintIcon" alt="" />
                        <span>{linkName}</span>
                    </div>
                }
            >
                <Row gutter>
                    <Col oneofone>
                        <img src={example} className="step-2-graphic-restaging" alt="" />
                    </Col>
                </Row>
            </WorkflowStep>
        );
    }
}

RestagingAffixAndScan.defaultProps = {
    restagingAffixAndScanComplete: false
};

RestagingAffixAndScan.propTypes = {
    restagingAffixAndScan: PropTypes.func,
    restagingAffixAndScanComplete: PropTypes.bool
};

const mapStateToProps = ({ serviceKit }) => {
    return {
        restagingAffixAndScanComplete: serviceKit.restagingAffixAndScanStepComplete
    };
};

const mapDispatchToProps = dispatch =>
bindActionCreators(
    {
        restagingAffixAndScan
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(RestagingAffixAndScan);
