import React, { memo } from 'react';
import classNames from 'classnames';
import Button from '../../Common/Button/Button';

const ClientSelection = ({ clientName, orderCount, onSelect }) => {
  const classes = classNames(
    'flex-column',
    'client-selection--container',
    { 'sprint-border--top': clientName === 'Sprint' },
    { 'verizon-border--top': clientName === 'Verizon' },
    { 'att-border--top': clientName === 'AT&T' }
  );

  return (
    <div className={classes}>
      <label className="client-selection--clientname">{clientName}</label>
      <label className="client-selection--ordercount">{orderCount}</label>
      <p className="client-selection--text">Orders to pack</p>
      <Button text="Start" onClick={onSelect} />
    </div>
  );
};

export default memo(ClientSelection);
