import { combineReducers } from 'redux';
import appInteractions from './reducers/appInteractionReducers';
import configuration from './reducers/configurationReducer';
import error from './reducers/errorReducers';
import order from './reducers/orderReducer';
import serviceKit from './reducers/serviceKitOrderReducer';
import websocket from './reducers/wsReducer';
import scannedParts from './reducers/scannedPartsReducer';
import scanWsn from './reducers/scanWsnReducer';
import receivingParts from './reducers/receivingPartsReducer';
import rma from './reducers/rmaReducer';

const rootReducer = {
  appInteractions,
  configuration,
  error,
  order,
  serviceKit,
  websocket,
  scannedParts,
  scanWsn,
  receivingParts,
  rma
};

export default combineReducers({
  ...rootReducer
});
