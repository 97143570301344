import { GET_RECEIVING_PARTS } from '../actions/orderActions';
import {
    SET_RECEIVING_ERROR,
    SET_RECEIVING_SEARCH_STRING,
    SET_RECEIVING_SEARCH_BY_OPTION,
    SET_RECEIVING_WSN_FOR_PARTS,
    SET_RECEIVING_WSN_LIST_FOR_PARTS,
    SET_COMPLETED_RECEIVED_PARTS
} from '../actions/receiveActions';

const initialState = {
    receivingPartsList: {},
    receivingError: {},
    receivingSearchString: '',
    receivingSearchByOption: '',
    wsnForPartsReceived: '',
    wsnListForPartsReceived: [],
    completedReceivedParts: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_RECEIVING_PARTS:
            return { ...state, receivingPartsList: action.payload };
        case SET_RECEIVING_ERROR:
            return { ...state, receivingError: action.payload };
        case SET_RECEIVING_SEARCH_STRING:
            return { ...state, receivingSearchString: action.payload };
        case SET_RECEIVING_SEARCH_BY_OPTION:
            return { ...state, receivingSearchByOption: action.payload };
        case SET_RECEIVING_WSN_FOR_PARTS:
            return { ...state, wsnForPartsReceived: action.payload };
        case SET_RECEIVING_WSN_LIST_FOR_PARTS:
            return { ...state, wsnListForPartsReceived: action.payload };
        case SET_COMPLETED_RECEIVED_PARTS:
            let currentList = [];
            if (action.payload.length > 0) {
                currentList = state.completedReceivedParts.concat(action.payload);
            } else if (action.payload.length === 0) {
                currentList = [];
            }
            return { ...state, completedReceivedParts: currentList };
        default:
            return state;
    }
}