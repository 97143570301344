import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import { resetReturnABCSuccessful, setRmaScanPartStatus } from '../../../../actions/orderActions';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import stopSign from './DamagedExtReturn.svg';
import './DamagedExtReturnABC.scss';

const partStatusMessages = {
    PDRETURN_EXT: {
        warning:'Stop! ',
        title1: 'this part has been classified as',
        title2:' damaged and needs further action:',
        buttonValue: 'SCAN ANOTHER PART',
        notice: [
            <span>Please process this part through the damage intake process.</span>,
            <span>If you have any questions reach out to your FSL manager.</span>
        ],
        image: stopSign
      }
};

class DamagedExtReturnABC extends PureComponent {
  goToNextStep() {
    this.props.nextStep(this.props.step);
  }

  goToPrevStep() {
    // reset returnAbcCompleted value
    this.props.resetReturnABCSuccessful();
    // reset setRmaScanPartStatus
    this.props.setRmaScanPartStatus('');

    this.props.prevStep();
  }

  render() {
    const { returnABCStatus } = this.props;
    let displayMessages = {
      title: 'Unable to retrieve title',
      buttonValue: 'SCAN ANOTHER PART',
      notice: []
    };
    if (!isEmpty(returnABCStatus) && !isEmpty(partStatusMessages[returnABCStatus]))
      displayMessages = partStatusMessages[returnABCStatus];
    return (
      <WorkflowStep
        {...this.props}
        title={
            <span className="returnsTitle">
              <span className='warningTitle'>{displayMessages.warning}</span>
              <span >{displayMessages.title1}</span><br/>
              <span >{displayMessages.title2}</span>
            </span>
        }
        footer={<PrimaryButton text={displayMessages.buttonValue} onClick={this.goToPrevStep.bind(this)} />}
        after={
            <div className="done-scanning-link" onClick={this.goToNextStep.bind(this)}>
                <span>DONE SCANNING</span>
            </div>
        }
      >
            <Row >
                <ul>
                    {displayMessages.notice.map((note, i) => (
                        <li key={i}>{note}</li>
                    ))}
                </ul>
            </Row>

            <div className='stopSignDiv'>
                <img src={displayMessages.image} className="step-2-graphic-return" alt="" />
            </div>
                        
        
      </WorkflowStep>
    );
  }
}

DamagedExtReturnABC.propTypes = {
    returnABCStatus: PropTypes.string,
    resetReturnABCSuccessful: PropTypes.func,
    setRmaScanPartStatus: PropTypes.func
};

DamagedExtReturnABC.defaultProps = {
    returnABCStatus: ''
};

const mapStateToProps = ({ serviceKit }) => {
  const { returnABCStatus } = serviceKit;
  return {
    returnABCStatus
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetReturnABCSuccessful,
      setRmaScanPartStatus
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DamagedExtReturnABC);