import React from 'react'
import WorkflowStep from '../../WorkflowStep/WorkflowStep'
import styled from 'styled-components';
import { BodyText, List, BottomBox, AfterCardText } from '../commonStyledComponent';
import Image from './image.png'
import ReturnIcon from './return-icon.png'


export const AffixShippingLabel = ({ nextStep, step, prevStep }) => {
    const goToNextStep = () => {
        nextStep(step)
    }
    return <WorkflowStep
        title="Affix the shipping label to the box"
        step={step}
        footer={
            <BottomBox>
                <span onClick={() => goToNextStep()}>
                    CONTINUE
                </span>
            </BottomBox>
        }
        after={
            <AfterCardText onClick={() => prevStep(step)}>
                RE-PRINT SHIPPING LABEL
                <img src={ReturnIcon} alt="reprint" />
            </AfterCardText>
        }
    >
        <div>
            <List>
                <BodyText>Place shipping label on the box</BodyText>
                <BodyText>Seal the box closed</BodyText>
            </List>
        </div>
        <RightSideImage src={Image} alt="frame" />
    </WorkflowStep>
}


const RightSideImage = styled.img`
    margin-left: 100px;
    width: 430px;
    height: 284px;
`;