import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import { resetScannedCountAction } from '../../../../actions/scannedPartsAction';
import { resetReturnABCSuccessful, setRmaScanPartStatus } from '../../../../actions/orderActions';
import Button from '../../../Common/Button/Button';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import SuccessIcon from '../../../../icons/done-checkmark.svg';
import './CompleteReturnABC.scss';

class CompleteReturnABC extends Component {
  handleClick = () => {
    // reset count of packed items
    this.props.resetScannedCountAction();
    // reset returnAbcCompleted value
    this.props.resetReturnABCSuccessful();
    // reset setRmaScanPartStatus
    this.props.setRmaScanPartStatus('');
    // close workflow
    this.props.closeWorkflow();
  };

  render() {
    const { scannedPartsCount } = this.props;
    return (
      <div className="workflow-main">
        <CSSTransitionGroup
          transitionName="workflow-step"
          transitionAppear={true}
          transitionAppearTimeout={5000}
          transitionEnterTimeout={5000}
          transitionLeaveTimeout={3000}
        >
          <div className="workflow-step">
            <div className="workflow-step__main">
              <div className="return-completed">
                <div className="return-completed-header">
                  <img src={SuccessIcon} alt="" />
                  <span className="return-completed-text">Return completed</span>
                </div>
                <div className="return-completed-main">
                  <span className="scanned-text">You have scanned {scannedPartsCount} part{scannedPartsCount > 1 && 's'}.</span>
                </div>
                <div>
                  <span className="place-parts-text">You can place the parts in the correct areas.</span>
                </div>
              </div>
            </div>
            <div className="workflow-step__footer">
              <PrimaryButton text={'Complete'} onClick={this.handleClick.bind(this)} />
            </div>
          </div>
          <div className="flex-row done-page--end">
            <Button className="go-back-link" text="Go back" onClick={this.props.prevStep} type="link" />
          </div>
        </CSSTransitionGroup>
      </div>
    );
  }
}

CompleteReturnABC.defaultProps = {
};

CompleteReturnABC.propTypes = {
  resetScannedCountAction: PropTypes.func,
  resetReturnABCSuccessful: PropTypes.func,
  setRmaScanPartStatus: PropTypes.func
};

const mapStateToProps = ({ scannedParts }) => {
  const { scannedPartsCount } = scannedParts;
  return {
    scannedPartsCount
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    resetScannedCountAction,
    resetReturnABCSuccessful,
    setRmaScanPartStatus
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CompleteReturnABC);
