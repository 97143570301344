import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  clearClientSelection,
  clearProcessingKit,
  getOrders,
  nextServiceKitOrder,
  resumeOrder,
  restageOrder,
  processReschedule,
  selectClientForWorkflow,
  printWsnPackagingLabel,
  changeMultiWorkflowList
} from '../../actions/orderActions';
import { showLoading } from '../../actions/appInteractionActions';
// import { getHotNews } from '../../actions/configurationActions';
import { getRefreshRate, updateCurrentSession } from '../../utilities/sessionUtility';
import { FlowTypes, WorkflowTaskMapping } from '../../utilities/enums';
import { wsConnect, wsDisconnect, wsMessage } from '../../actions/wsConnectActions';
import AppLoader from '../Common/AppLoader/AppLoader';
import Dashboard from '../Dashboard/Dashboard';
import DashTimer from '../Dashboard/DashTimer/DashTimer';
import ModalRouter from '../Modal/ModalRouter/ModalRouter';
import Workflow from '../Workflow/Workflow';
import './AppDashboard.scss';
import { generateCorrelationID } from '../../utilities/genericUtility';

let refreshTimeout = null;

class AppDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOrderCancelled: false,
      modal: false,
      resumeStep: 0,
      returnWorkflow: false,
      restagingWorkflow: false,
      sessionExpiry: false,
      workflow: false,
      multipleWorkflow: false,
      isMissingPartsWorkflow: false,
      isUnableToLocateShipment: false,
      unableToLocateStep: 0,
      isprintwsnclicked: false,
      isPrintingABC: false,
      selectedAbc: '',
      isRescheduleWorkflow: false,
      isScanWsnPackingLabel: false
    };
    updateCurrentSession(this.sessionExpired.bind(this));
    window.onbeforeunload = e => {
      this.props.wsDisconnect();
    };
    this.closeModal = this.closeModal.bind(this);
    this.closeWorkflow = this.closeWorkflow.bind(this);
    this.displayNextOrder = this.displayNextOrder.bind(this);
    this.openModal = this.openModal.bind(this);
    this.openSelectClient = this.openSelectClient.bind(this);
    this.resumeWorkflow = this.resumeWorkflow.bind(this);
    this.restagingWorkflow = this.restagingWorkflow.bind(this);
    this.selectClient = this.selectClient.bind(this);
    this.setAsPacked = this.setAsPacked.bind(this);
    this.startReturnProcess = this.startReturnProcess.bind(this);
    this.unableToLocate = this.unableToLocate.bind(this);
    this.goBackScanWSN = this.goBackScanWSN.bind(this);
    this.closeUnableToLocateShipment = this.closeUnableToLocateShipment.bind(this);
    this.handlePrintWsn = this.handlePrintWsn.bind(this);
    this.handleReschedulePrintWsn = this.handleReschedulePrintWsn.bind(this);
    this.rescheduleOpen = this.rescheduleOpen.bind(this);
  }
  unableToLocate = () => {
    this.setState({
      isUnableToLocateShipment: true,
      unableToLocateStep: this.state.unableToLocateStep + 1
    });
  };

  rescheduleOpen = async data => {
    this.setState({
      workflow: true,
      isRescheduleWorkflow: true
    });
    await this.props.processReschedule(data);
  };

  goBackScanWSN = () => {
    this.setState({
      isUnableToLocateShipment: false,
      unableToLocateStep: this.state.unableToLocateStep - 1
    });
  };

  sessionExpired() {
    this.setState({
      sessionExpiry: true
    });
    this.props.wsDisconnect(true);
    this.openModal('TokenExpired');
  }

  closeWindow() {
    // ==UserScript==
    // @grant       GM_addStyle
    // ==/UserScript==
    window.open(window.location, '_self').close();
  }

  openSelectClient = jobNumber => {
    let nonZeroClients = 0,
      externalClientId = 0;
    this.props.clientCounts.forEach(client => {
      if (client.count !== 0) {
        nonZeroClients++;
        if (externalClientId === 0) externalClientId = client.externalClientId;
      }
    });
    generateCorrelationID(jobNumber, FlowTypes.ORDER_TO_KIT);
    // TODO: once external clients expand to more than 1 we can reuse this code
    // if (this.props.clientCounts.length > 1 && nonZeroClients > 1) this.openModal('ClientSelection');
    // else this.selectClient(externalClientId);
    this.selectClient(externalClientId, jobNumber);
  };

  selectClient = (id, jobNumber) => {
    this.props.selectClientForWorkflow(id);
    this.openWorkflow(jobNumber);
    this.closeModal();
  };

  openWorkflow = async jobNumber => {
    await this.props.nextServiceKitOrder(jobNumber);
    if (this.props.modalPresent) this.setState({ workflow: true });
  };

  resumeWorkflow = async selected => await this.props.resumeOrder(selected, this.resumeToStep);

  restagingWorkflow = async rowSelected =>
    await this.props.restageOrder(rowSelected, this.startRestageStep);

  resumeToStep = stepName => {
    this.openModal('ResumeWorkflow');
    const index = this.props.workflowOrder.indexOf(WorkflowTaskMapping[stepName]);
    setTimeout(() => {
      this.setState({ modal: false, resumeStep: index + 2, workflow: true });
    }, 1000);
  };

  handlePrintAbc = async trackingNum => {
    const printAfterStateChangeFn = () => {
      // print
      window.print();
    };

    // set the abc printing state
    this.setState(
      {
        isPrintingABC: true,
        selectedAbc: trackingNum,
        isScanWsnPackingLabel: false
      },
      printAfterStateChangeFn
    );
  };

  handleAfterPrintAbc = async () => {
    // Clear the selected abc that was being printed
    this.setState({
      isPrintingABC: false,
      selectedAbc: '',
      isScanWsnPackingLabel: false
    });
  };

  handlePrintWsn = async serviceKitNumber => {
    // Call API for restaging print workflow
    await this.props.printWsnPackagingLabel(serviceKitNumber);
    if (this.props.printWsnComplete) {
      // present print window
      this.setState({ isprintwsnclicked: true, isScanWsnPackingLabel: false });
      window.print();

      // NOTE: We can't detect from browser whether user hits cancel vs print to act on that action
      // That is to move to next step if they printed OR stay on current step if cancelled
      this.props.changeMultiWorkflowList('printWSN');
    }
  };

  handleReschedulePrintWsn = async serviceKitNumber => {
    const printAfterStateChangeFn = () => {
      // print
      window.print();
    };
    this.setState(
      {
        isScanWsnPackingLabel: true,
        isprintwsnclicked: false
      },
      printAfterStateChangeFn
    );
  };

  startRestageStep = step => {
    // if step is not the first step in the flow don't show the ResumeWorkflow modal
    if (step > 1) {
      this.openModal('ResumeWorkflow');
      setTimeout(() => {
        this.setState({
          multipleWorkflow: true,
          restagingWorkflow: true,
          workflow: true,
          resumeStep: step
        });
      }, 1000);
    } else {
      this.setState({
        multipleWorkflow: true,
        restagingWorkflow: true,
        workflow: true,
        resumeStep: step
      });
    }
  };

  setIsMissingPartsWorkflow = missingPartsFlag => {
    this.setState({
      isMissingPartsWorkflow: missingPartsFlag
    });
  };

  closeWorkflow = async () => {
    this.closeModal();
    this.setState({
      currentOrderCancelled: false,
      resumeStep: 0,
      returnWorkflow: false,
      restagingWorkflow: false,
      workflow: false,
      multipleWorkflow: false,
      isUnableToLocateShipment: false,
      unableToLocateStep: 0,
      isprintwsnclicked: false,
      isRescheduleWorkflow: false
    });
    this.props.clearProcessingKit();
    this.props.clearClientSelection();
  };

  openModal = newModal => {
    this.setState({
      currentOrderCancelled: newModal === 'MidwayCancel',
      modal: newModal
    });
  };

  closeModal = () => {
    if (!this.state.currentOrderCancelled) this.setState({ modal: false });
  };

  setAsPacked = async (bucketType = '', nextorder = '') => {
    if (bucketType === 'Unfinished') {
      await generateCorrelationID(nextorder.kitReferenceNumber, FlowTypes.UNFINISHED);
      await this.props.nextServiceKitOrder(nextorder.kitReferenceNumber);
    } else {
      await generateCorrelationID(nextorder.kitReferenceNumber, FlowTypes.ORDER_TO_KIT);
      await this.props.nextServiceKitOrder();
    }
  };

  startReturnProcess = () => {
    this.setState({
      returnWorkflow: true,
      workflow: true
    });
  };

  closeUnableToLocateShipment = () => {
    this.setState({ isUnableToLocateShipment: false, unableToLocateStep: 0 });
  };

  componentDidMount = async () => {
    this.props.showLoading();
    this.props.getOrders(false);
    this.scheduleGetOrders();
    await this.props.wsConnect();
    this.sendMessage(this.props.wsconnected);
    // this.props.getHotNews();
  };

  componentDidUpdate = async (props, state) => {
    if (this.props.wserrored) {
      if (!refreshTimeout) {
        clearTimeout(refreshTimeout);
        this.scheduleGetOrders(true);
      }
    }
  };

  scheduleGetOrders = async notFirstCall => {
    const refreshRate = getRefreshRate();
    notFirstCall &&
      !this.props.modalPresent &&
      !this.state.sessionExpiry &&
      !this.state.returnWorkflow &&
      (await this.props.getOrders(true));
    if (!this.props.wsconnected)
      refreshTimeout = setTimeout(() => this.scheduleGetOrders(true), refreshRate);
  };

  sendMessage(flag) {
    if (!flag)
      setTimeout(() => {
        this.props.wsconnected ? this.props.wsMessage() : this.sendMessage(this.props.wsconnected);
      }, 1000);
  }

  displayNextOrder = async () => {
    if (this.props.orderToPack > 0) {
      const orderToPack = this.props.serviceKitOrders.filter(
        order => order.serviceKitOrderStatusType === 'OrdersToPack'
      )[0];
      const clientData = orderToPack.serviceKitOrderClientCounts.filter(
        order => order.externalClientId === this.props.selectedClient.externalClientId
      )[0];
      if (clientData.count === 0) {
        const otherCarrierData = orderToPack.serviceKitOrderClientCounts.filter(
          order =>
            order.externalClientId !== this.props.selectedClient.externalClientId && order.count > 0
        );
        this.props.selectClientForWorkflow(otherCarrierData[0].externalClientId);
      }
      await this.setAsPacked();
      this.setState({
        currentOrderCancelled: false,
        modal: false,
        resumeStep: 0,
        returnWorkflow: false
      });
    }
  };

  render() {
    const isModalActive = this.state.modal;
    const isWorkflowActive = this.state.workflow;
    const isMultipleWorkflowActive = this.state.multipleWorkflow;
    const dashTimer = <DashTimer />;
    return (
      <div className="AppDashboard">
        <AppLoader />
        <Dashboard
          isHelpDrawerActive={this.state.helpDrawer}
          openSelectClient={this.openSelectClient}
          resumeWorkflow={this.resumeWorkflow}
          openRestagingWorkflow={this.restagingWorkflow}
          startReturnProcess={this.startReturnProcess}
          timer={dashTimer}
          backToFSLDashboard={this.props.backToFSLDashboard}
          openRescheduleWorkflow={this.rescheduleOpen}
        />
        {isWorkflowActive ? (
          <Workflow
            closeModal={this.closeModal}
            closeWorkflow={this.closeWorkflow}
            isHelpDrawerActive={this.state.helpDrawer}
            modal={this.state.modal}
            openHelpDrawer={this.openHelpDrawer}
            openModal={this.openModal}
            reshipFlow={this.props.reshipFlow}
            resumeToStep={this.state.resumeStep}
            returnWorkflow={this.state.returnWorkflow}
            restagingWorkflow={this.state.restagingWorkflow}
            selectedClient={this.props.selectedClient}
            setAsPacked={this.setAsPacked}
            timer={dashTimer}
            workflowOrder={
              isMultipleWorkflowActive
                ? this.props.multipleWorkflowOrder
                : this.state.isRescheduleWorkflow
                ? ['reschedulescan']
                : this.props.workflowOrder
            }
            missingPartsWorkflow={this.state.isMissingPartsWorkflow}
            handleIsMissingPartsWorkflow={this.setIsMissingPartsWorkflow}
            goBackScanWSN={this.goBackScanWSN}
            unableToLocate={this.unableToLocate}
            isUnableToLocateShipment={this.state.isUnableToLocateShipment}
            unableToLocateStep={this.state.unableToLocateStep}
            closeUnableToLocateShipment={this.closeUnableToLocateShipment}
            isprintwsnclicked={this.state.isprintwsnclicked}
            handlePrintWsn={this.handlePrintWsn}
            handleReschedulePrintWsn={this.handleReschedulePrintWsn}
            handlePrintAbc={this.handlePrintAbc}
            handleAfterPrintAbc={this.handleAfterPrintAbc}
            isPrintingABC={this.state.isPrintingABC}
            selectedAbc={this.state.selectedAbc}
            isRescheduleWorkflow={this.state.isRescheduleWorkflow}
            isScanWsnPackingLabel={this.state.isScanWsnPackingLabel}
          />
        ) : null}
        {isModalActive ? (
          <ModalRouter
            closeModal={this.closeModal}
            closeWindow={this.closeWindow}
            closeWorkflow={this.closeWorkflow}
            displayNextOrder={this.displayNextOrder}
            modal={this.state.modal}
            orderToPack={this.props.orderToPack}
            selectClient={this.selectClient}
          />
        ) : null}
      </div>
    );
  }
}

AppDashboard.defaultProps = {
  modalPresent: false
};

AppDashboard.propTypes = {
  clearProcessingKit: PropTypes.func,
  clientCounts: PropTypes.array,
  getOrders: PropTypes.func,
  modalPresent: PropTypes.bool,
  nextServiceKitOrder: PropTypes.func,
  resumeOrder: PropTypes.func,
  restageOrder: PropTypes.func,
  processReschedule: PropTypes.func,
  wsConnect: PropTypes.func,
  wsconnected: PropTypes.bool,
  wsDisconnect: PropTypes.func,
  wsMessage: PropTypes.func,
  showLoading: PropTypes.func
};

const mapStateToProps = ({ order, serviceKit, websocket }) => {
  const { clientCounts, orderToPack, selectedClient, serviceKitDisplayRows } = order;
  const { processingServiceKit, processingStarted, selectedMultiWorkflow } = serviceKit;
  return {
    clientCounts,
    modalPresent: processingStarted,
    orderToPack,
    reshipFlow: processingServiceKit && processingServiceKit.serviceType === 'RESHIP',
    selectedClient,
    serviceKitOrders: serviceKitDisplayRows,
    workflowOrder:
      processingServiceKit &&
      processingServiceKit.workflow &&
      processingServiceKit.workflow.serviceKitTasks,
    multipleWorkflowOrder: selectedMultiWorkflow,
    wsconnected: websocket.connected,
    wserrored: websocket.errored,
    printWsnComplete: serviceKit.printWsnStepComplete
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearClientSelection,
      clearProcessingKit,
      // getHotNews,
      getOrders,
      nextServiceKitOrder,
      resumeOrder,
      restageOrder,
      processReschedule,
      selectClientForWorkflow,
      wsConnect,
      wsDisconnect,
      wsMessage,
      printWsnPackagingLabel,
      changeMultiWorkflowList,
      showLoading
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AppDashboard);
