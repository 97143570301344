export const SET_PROCESSING_SCAN_WSN_LIST = 'SET_PROCESSING_SCAN_WSN_LIST';

export const setProcessingScanWsnListAction = (payload) => (dispatch) => {
    dispatch(setProcessingScanWsnList(payload));
}

const setProcessingScanWsnList = (payload) => ({
    type: SET_PROCESSING_SCAN_WSN_LIST,
    payload
});
