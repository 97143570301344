import {
  CLEAR_CLIENT_SELECTION,
  GET_ORDERS,
  SELECT_CLIENT_FOR_WORKFLOW,
  UPDATE_TOTAL_COUNT,
  DECREMENT_TOTAL_COUNT
} from '../actions/orderActions';

const initialState = {
  clientCounts: [],
  orderToPack: -1,
  selectedClient: {},
  serviceKitDisplayRows: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_CLIENT_SELECTION:
      return {
        ...state,
        selectedClient: {}
      };
    case GET_ORDERS:
      return {
        ...state,
        clientCounts: action.payload.clientCounts,
        orderToPack: action.payload.orderToPack,
        serviceKitDisplayRows: action.payload.serviceKitOrders
      };
    case SELECT_CLIENT_FOR_WORKFLOW:
      return {
        ...state,
        selectedClient: action.payload
      };
    case UPDATE_TOTAL_COUNT:
      return {
        ...state,
        orderToPack: action.count
      };
    case DECREMENT_TOTAL_COUNT:
      return {
        ...state,
        orderToPack: --state.orderToPack
      };
    default:
      return state;
  }
};
