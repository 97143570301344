import React, { Component } from 'react';
import { isEmpty } from '../../../../utilities/genericUtility';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getServiceKitOrderDetails } from './../../../../actions/orderActions';
import {
    setReceivingSearchString,
    setReceivingSearchByOption,
    clearReceivingError
} from '../../../../actions/receiveActions';
import Button from '../../../Common/Button/Button';
import ModalRouter from '../../../Modal/ModalRouter/ModalRouter';
import './ReceivingParts.scss';

class ReceivingPartSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchString: '',
            searchByOption: 'Tracking/Shipment Number',
            searchSubmitted: false,
            toggle: false,
            modal: false,
            inputLengthValidationError: false
        };
        this.searchSubmit = this.searchSubmit.bind(this);
        this.toggleState = this.toggleState.bind(this);
    }

    toggleState() {
		this.setState({
			toggle: !this.state.toggle
		});
	}

    searchSubmit = async e => {
        e && e.preventDefault();

        this.setState({
          inputLengthValidationError: false
        });

        if (this.state.searchString.length > 64) {
          this.setState({
            inputLengthValidationError: true
          });
        } else {
          this.setState({
            searchSubmitted: true
          });
          if (this.state.searchByOption && this.state.searchString) {
            this.props.setReceivingSearchString(this.state.searchString);
            this.props.setReceivingSearchByOption(this.state.searchByOption);
            this.props.clearReceivingError();
            await this.props.getServiceKitOrderDetails(this.state);
          } else {
            // both options are not selected show error message
          }
        }
    };

    processUnknownShipment = e => {
        e && e.preventDefault();
        this.openModal('UnknownShipmentForm')
    }

    closeModal = () => {
        this.setState({ modal: false });
    };

    openModal = newModal => {
        this.setState({
            modal: newModal
        });
    };

    completeUnknownShipmentForm = () => {
        // complete unknown shipment form
        this.closeModal();
      };

    componentDidUpdate() {
        if (this.state.searchByOption && this.state.searchString && this.state.searchSubmitted) {
            this.props.onSearch(this.state);
        }
    }

    render() {
        const isModalActive = this.state.modal;

        return (
          <form onSubmit={this.searchSubmit}>
            <div className="receive-item_search">
              <div style={{ marginTop: '1rem' }}>
                <label className="receive-item_header"> Find Shipment Information </label>
              </div>
              <div className="receive-item_selection">
                <div>
                  <input
                    type="radio"
                    id="Tracking/Shipment Number"
                    name="radAnswer"
                    value="Tracking/Shipment Number"
                    className="receive-item_radio"
                    onClick={() => {
                      this.setState({
                        searchByOption: 'Tracking/Shipment Number'
                      });
                    }}
                    onChange={this.toggleState}
                    checked={!this.state.toggle}
                  />
                  <label className="receive-item_options" htmlFor="Tracking/Shipment Number">
                    Tracking/Shipment Number
                  </label>
                  <br />
                </div>
                <div>
                  <input
                    type="radio"
                    id="Order/Reference Number"
                    name="radAnswer"
                    value="Order/Reference Number"
                    className="receive-item_radio"
                    onClick={() => {
                      this.setState({
                        searchByOption: 'Order/Reference Number'
                      });
                    }}
                    onChange={this.toggleState}
                    checked={this.state.toggle}
                  />
                  <label className="receive-item_options" htmlFor="Order/Reference Number">
                    Order/Reference Number
                  </label>
                  <br />
                </div>
              </div>
              <div className="receive-search">
                <input
                  type="text"
                  id="search"
                  className="receive-search__input"
                  placeholder="Scan or type"
                  onChange={e => {
                    e.preventDefault();
                    this.setState({
                      searchString: e.target.value
                    });
                    if (isEmpty(e.target.value)) this.props.onSearch('');
                  }}
                  value={this.state.searchString}
                  autoFocus
                  // maxlength="64"
                />
                <button className="dash-search__button" type="submit" />
              </div>
              <div style={{ margin: '0px 25px 28px 25px' }}>
                { this.props.errorDetails.message && <div className="error-container">
                <div className="error-container-icon"></div>
                  <div className="error-container-text">
                      Order number is not found. Send parts back to the manufacturer and fill out the support form.
                    </div>
                    <div className="error-container-link" onClick={this.processUnknownShipment}>Unknown shipment form</div>
                </div> }
                {this.state.inputLengthValidationError && <div className="error-container">
                <div className="error-container-icon"></div>
                  <div className="error-container-text">
                  Tracking/order is incorrect, please check the tracking/order number and retry your search. 
                    </div>
                </div> }
                <Button
                  text="Submit"
                  className="receive-addon_button receive-border-active"
                  onClick={this.searchSubmit}
                ></Button>
              </div>
            </div>
            {isModalActive ? (
              <ModalRouter
                closeModal={this.closeModal}
                modal={this.state.modal}
                complete={this.completeUnknownShipmentForm}
              />
            ) : null}
          </form>
        );
    }
};

const mapStateToProps = ({ receivingParts }) => ({
    errorDetails: receivingParts.receivingError
  });

const mapDispatchToProps = dispatch => bindActionCreators({
    getServiceKitOrderDetails,
    setReceivingSearchString,
    setReceivingSearchByOption,
    clearReceivingError
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ReceivingPartSearch);
