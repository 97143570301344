export const WorkflowNames = {
  affixandscan: 'affixandscan',
  affixandscanreshiplabel: 'affixandscanreshiplabel',
  done: 'done',
  packcollateral: 'packcollateral',
  packcollateraldual: 'packcollateraldual',
  packcollateralfirstnet: 'packcollateralfirstnet',
  packcollateralinbox: 'packcollateralinbox',
  printpackinglabel: 'printpackinglabel',
  scanfirstnetsim: 'scanfirstnetsim',
  scanhomeplusguide: 'scanhomeplusguide',
  scanreturnenvelope: 'scanreturnenvelope',
  scanrma: 'scanrma',
  scanskuesn: 'scanskuesn',
  scanvphguide: 'scanvphguide',
  scanvphtosbooklet: 'scanvphtosbooklet',
  scanzip: 'scanzip',
  scanwsn: 'scanwsn',
  prepareshipmentscanwsn: 'prepareshipment-scanwsn',
  scanparts: 'scanparts',
  prepareshipmentaffixandscanwsn: 'prepareshipment-affixandscanwsn',
  closebox: 'closebox',
  unabletolocateshipment:'unabletolocateshipment',
  // RMA workflows
  scanningreturns: 'scanningreturns',
  // RMA workflows - PDRETURN
  returntopdprint: 'returntopdprint',
  returntopdaffix: 'returntopdaffix',
  returntopdshelf: 'returntopdshelf',
   // RMA workflows - PDRETURN_EXT
  returntopdext: 'returntopdext',
  // RMA workflows - RESTAGE/RETURN
  returnorrestock: 'returnorrestock',
  // RMA workflows - SCRAP
  returnscrap: 'returnscrap',
  // Reschedule workflow
  reschedulescan: 'reschedulescan'
};

export const WorkflowTaskMapping = {
  'SCAN FIRSTNETSIM': WorkflowNames.scanfirstnetsim,
  AFFIX: WorkflowNames.affixandscan,
  AFFIXANDSCANRESHIPLABEL: WorkflowNames.affixandscanreshiplabel,
  COLLATERAL: WorkflowNames.packcollateral,
  COLLATERALDUAL: WorkflowNames.packcollateraldual,
  COLLATERALFIRSTNET: WorkflowNames.packcollateralfirstnet,
  COLLATERALINBOX: WorkflowNames.packcollateralinbox,
  ENVELOPE: WorkflowNames.scanreturnenvelope,
  PRINT: WorkflowNames.printpackinglabel,
  SCANFIRSTNETSIM: WorkflowNames.scanfirstnetsim,
  SCANHOMEPLUSGUIDE: WorkflowNames.scanhomeplusguide,
  SCANRMA: WorkflowNames.scanrma,
  SCANVPHGUIDE: WorkflowNames.scanvphguide,
  SCANVPHTOSBOOKLET: WorkflowNames.scanvphtosbooklet,
  SCANZIP: WorkflowNames.scanzip,
  SCANWSN: WorkflowNames.scanwsn,
  PREPARESHIPMENTSCANWSN: WorkflowNames.prepareshipmentscanwsn,
  SCANPARTS: WorkflowNames.scanparts,
  PREPARESHIPMENTAFFIXANDSCANWSN: WorkflowNames.prepareshipmentaffixandscanwsn,
  CLOSEBOX: WorkflowNames.closebox
};

export const WorkflowLabel = {
  // affixandscan: 'Affix & Scan',
  // affixandscanreshiplabel: 'Reship label',
  // packcollateral: 'Setup guide',
  // packcollateraldual: 'Setup guide',
  // packcollateralfirstnet: 'Setup guide',
  // packcollateralinbox: 'Setup guide',
  // printpackinglabel: 'Packing label',
  // scanfirstnetsim: 'FirstNet SIM',
  // scanreturnenvelope: 'Scan tracking number',
  // scanrma: 'Scan RMA',
  // scanskuesn: 'SKU',
  // scanvphguide: 'VHDP Brochure',
  // scanvphtosbooklet: 'VHDP Booklet',
  // scanhomeplusguide: 'AHP brochure',
  // scanzip: 'Scan ZIP'
  scanwsn: 'Pulling Order',
  'prepareshipment-scanwsn': 'Prepare shipment',
  'prepareshipment-print': 'Prepare shipment',
  'prepareshipment-affixandscanwsn': 'Prepare shipment',
  scanparts: 'Scan Parts',
  closebox: 'Close box & Place on Rack',
  // RMA workflows
  scanningreturns: 'Scanning returns',
  returntopdprint: 'Print',
  returntopdaffix: 'Affix',
  returntopdshelf: 'Place on shelf',
  returnorrestock: 'Place on shelf',
  returnscrap: 'Scrap part',
  reschedulescan: 'Scan WSN',
  returntopdext: 'Damaged Part'
};

export const CollateralType = {
  packcollateral: '',
  packcollateraldual: 'Dual SIM',
  packcollateralfirstnet: 'FirstNet',
  packcollateralinbox: 'SIM'
};

export const AlertType = {
  ExceptionalFlow: 'ExceptionalFlow',
  FirstNetSimAvailable: 'FirstNetSimAvailable',
  OutOfCollateral: 'OutOfCollateral',
  OutOfEnvelope: 'OutOfEnvelope',
  OutOfHomePlusGuide: 'OutOfHomePlusGuide',
  OutOfVPHGuide: 'OutOfVPHGuide',
  OutOfVPHTOSBooklet: 'OutOfVPHTOSBooklet',
  RetryPrinting: 'RetryPrinting',
  SKUAvailable: 'SKUAvailable'
};

export const MenuContentType = {
  ExpertList: 'ExpertList',
  HotNews: 'HotNews',
  NeedHelp: 'NeedHelp',
  ReceivingParts: 'ReceivingParts',
  SJDetails: 'SJDetails'
};

export const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const MONTH = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December'
};

export const CURRENT_TIME = 'currentTime';

export const HelpTabs = {
  CONTACTS: 'Contacts',
  ROOMS: 'Help rooms'
};

export const ContactKeys = {
  WEEKENDCONTACT: 'managers-weekend',
  HELPROOM: 'managers-helproom'
};


export const FlowTypes = {
  DASHBOARD:'DSH',
  RETURN_SHIPMENT:'RSH',
  FSL_DASHBOARD:'FDSH',
  ORDER_TO_KIT:'OTK',
  UNFINISHED:'UNF',
  EXCEPTION:'EXC',
  RESTAGING:'RST',
}