import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { resetReturnABCSuccessful } from '../../../actions/orderActions';
import HelpButton from '../WorkflowStep/HelpButton/HelpButton';
import OrderInfo from './OrderInfo/OrderInfo';
import './WorkflowHeader.scss';
import iconClose from './icon-close.svg';

class WorkflowHeader extends PureComponent {
  handleClick = () => {
    if (
      this.props.step === 1 ||
      (this.props.workflowOrder && this.props.step === this.props.workflowOrder.length + 1) ||
      this.props.returnWorkflow
    ) {
      this.props.closeWorkflow();
    } else {
      this.props.openModal('LeaveWorkflow');
    }

    // ensure we clear out restaging workflow state
    this.props.handleIsMissingPartsWorkflow(false);

    // reset returnAbcCompleted value
    this.props.resetReturnABCSuccessful();
  };

  render() {
    return (
      <div className="workflow-header-container">
        <header className="workflow-header">
          <nav className="workflow-header__nav">
            <button
              onClick={this.handleClick.bind(this)}
              className="workflow-header-btn workflow-close-btn"
            >
              <img src={iconClose} className="workflow-header-btn__icon" alt="close" />
            </button>
            {this.props.isRescheduleWorkflow ? (
              <HelpButton {...this.props} />
            ) : (
              ((!this.props.returnWorkflow &&
                this.props.workflowOrder &&
                this.props.step !== this.props.workflowOrder.length + 1) ||
                (this.props.returnWorkflow && this.props.step !== 4)) && (
                <HelpButton {...this.props} />
              )
            )}
          </nav>
          {!this.props.returnWorkflow &&
            !this.props.restagingWorkflow &&
            !this.props.rmaWorkflow &&
            !this.props.isRescheduleWorkflow && <OrderInfo {...this.props} />}
          {this.props.timer}
        </header>
        {this.props.children}
      </div>
    );
  }
}

WorkflowHeader.propTypes = {
  resetReturnABCSuccessful: PropTypes.func
};

WorkflowHeader.defaultProps = {};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetReturnABCSuccessful
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(WorkflowHeader);
