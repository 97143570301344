export const INCREMENT_SCANNED_COUNT = 'INCREMENT_SCANNED_COUNT';
export const RESET_SCANNED_COUNT = 'RESET_SCANNED_COUNT';

export const incrementScannedCountAction = () => (dispatch) => {
    dispatch(incrementScannedCount());
}

export const resetScannedCountAction = () => (dispatch) => {
    dispatch(resetScannedCount());
}

const incrementScannedCount = () => ({
    type: INCREMENT_SCANNED_COUNT
});

const resetScannedCount = () => ({
    type: RESET_SCANNED_COUNT
});