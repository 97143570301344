import React, { memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './OrderInfo.scss';

const OrderInfo = ({ orderToPack }) => {
  const totalCount = orderToPack;
  return (
    <div className="workflow-header__content">
      <dl className="workflow-header-dl">
        <dt>Orders to kit:</dt>
        <dd>{totalCount}</dd>
      </dl>
    </div>
  );
};

OrderInfo.defaultProps = {
  kitInfo: {},
  orderToPack: 0
};

OrderInfo.propTypes = {
  kitInfo: PropTypes.object,
  orderToPack: PropTypes.number,
  step: PropTypes.number.isRequired
};

const mapStateToProps = ({ order, serviceKit }) => ({
  kitInfo: serviceKit.processingServiceKit,
  orderToPack: order.orderToPack
});

export default connect(mapStateToProps, null)(memo(OrderInfo));
