import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import { getClientId, getClientName } from '../../../../utilities/clientUtility';
import { splitWsnInfo, removeLeadingZeros } from '../../../../utilities/genericUtility';
import {
  dashboardApiCall,
  getOrders,
  selectClientForWorkflow,
  updateTotalCount
} from '../../../../actions/orderActions';
import Button from '../../../Common/Button/Button';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import SuccessIcon from '../../../../icons/done-checkmark.svg';
import './WsnScanConfirmation.scss';

const DoneInfo = ({ kitReferenceNumber }) => (
  <div className="wsn-scan-done-info">
    <img src={SuccessIcon} alt="" />
    <h1 className="wsn-scan-done-info-h1">
        Job{' '}
        {kitReferenceNumber}
        <br/>
        is ready for pickup
    </h1>
  </div>
);

const WsnList = ({wsnList, kitReferenceNumber}) => {
  const localWsnList = (wsnList && wsnList.length >= 1) ? wsnList : [];
  return (
    <div className="wsn-scan-done-wsn-list">
        <p className="wsn-scan-done-wsn-list-p">This job contains WSNs:</p>
        <p className="wsn-scan-done-wsn-list-p-bold">
            {localWsnList.map((wsnItem, idx) =>
                splitWsnInfo(wsnItem.wsn, kitReferenceNumber).wsnMonth +
                removeLeadingZeros(splitWsnInfo(wsnItem.wsn, kitReferenceNumber).wsnSerialNum) +
                splitWsnInfo(wsnItem.wsn, kitReferenceNumber).wsnSerialAlpha +
                (idx === localWsnList.length - 1 ? '' : ', '))}
        </p>
    </div>
  );
};

class WsnScanConfirmation extends Component {
  state = {
    currentCarrierCount: -1,
    otherCarrierName: ''
  };

  componentDidMount = async () => {
    await this.getDataOnLoad();
  };

  componentDidUpdate = async(prevProps, prevState) => {
    if (prevProps.serviceKitOrders !== this.props.serviceKitOrders) {
    const remainOrder = this.props.serviceKitOrders.filter(order => order.serviceKitOrderStatusType === this.props.bucketType)[0];
    this.setState({remainOrder : remainOrder});
    }
    
  }

  getDataOnLoad = async () => {
    let sko = this.props.serviceKitOrders;
    if (!this.props.wsconnected) {
      const res = await dashboardApiCall();
      sko = res.serviceKitOrders;
    }
   
    const orderToPack = sko.filter(order => order.serviceKitOrderStatusType === 'OrdersToPack')[0];
    const clientData = orderToPack.serviceKitOrderClientCounts.filter(
      order => order.externalClientId === this.props.selectedClient.externalClientId
    )[0];
    const otherCarrierData = orderToPack.serviceKitOrderClientCounts.filter(
      order =>
        order.externalClientId !== this.props.selectedClient.externalClientId && order.count > 0
    );
    this.setState({
      currentCarrierCount: clientData ? clientData.count : 0,
      otherCarrierName: otherCarrierData[0]
        ? getClientName(otherCarrierData[0].externalClientId)
        : null
      });
    if (!this.props.wsconnected)
      await this.props.updateTotalCount(parseInt(orderToPack.serviceKitDisplayRows.length));
  };

  handleClick = async () => {
    const {remainOrder} = this.state;
    if(this.props.bucketType === 'Unfinished'){
      const nextorder = remainOrder.serviceKitDisplayRows.filter((order)=> order.kitReferenceNumber !== this.props.kitInfo.kitReferenceNumber)[0];
      if(nextorder){
      await this.props.setAsPacked(this.props.bucketType,nextorder);
      if (this.props.processingStarted) this.props.resetCurrentFlow();
      }
      else await this.props.closeWorkflow();
    }
    else if(this.props.bucketType === 'OrdersToPack'){
     if (this.props.orderToPack > 0) {
      if (!this.props.wsconnected) await this.props.getOrders(false);
      if (this.state.currentCarrierCount === 0)
        await this.props.selectClientForWorkflow(getClientId(this.state.otherCarrierName));
      await this.props.setAsPacked();
      if (this.props.processingStarted) this.props.resetCurrentFlow();
    }
    else await this.props.closeWorkflow();
    }
  };

  goToDashboard = () => {
    this.props.closeWorkflow();
  }

  render() {
    const {remainOrder} = this.state;
    const jobCount = this.props.bucketType === 'Unfinished' && remainOrder && remainOrder.serviceKitDisplayRows ? remainOrder && remainOrder.serviceKitDisplayRows.length -1: remainOrder && remainOrder.serviceKitDisplayRows.length;
    const infoContent = (
          <div className="info-content-wsn">
            <p className="info-place-shipment-text">You can place the shipments in the Ready to Pickup rack.</p>
            <p className="info-jobs-left-text">You have <span className="info-jobs-left-text-bold">{jobCount} more jobs</span> to pull.</p>
          </div>
        );
    const buttonLabel =
      (this.props.bucketType === 'Unfinished' && remainOrder && remainOrder.serviceKitDisplayRows.length > 1) || (this.props.bucketType === 'OrdersToPack' && remainOrder && remainOrder.serviceKitDisplayRows.length > 0)
        ? 'Next Order'
        : 'Complete';
    return (
      <div className="workflow-main">
        {this.props.showError ? <div className="wsn-confirmation-error">{this.props.errorMessage}</div> : <></>}
        <CSSTransitionGroup
          transitionName="workflow-step"
          transitionAppear={true}
          transitionAppearTimeout={5000}
          transitionEnterTimeout={5000}
          transitionLeaveTimeout={3000}
        >
          {this.state.currentCarrierCount !== -1 && (
            <div className="workflow-step">
              <div className="workflow-step__main">
                <div className="workflow-step__messaging wsn-scan-confirmation">
                  <DoneInfo kitReferenceNumber={this.props.kitInfo.kitReferenceNumber} />
                  <WsnList wsnList={this.props.kitInfo.serviceKitList} kitReferenceNumber={this.props.kitInfo.kitReferenceNumber}/>
                  {infoContent}
                </div>
              </div>
              <div className="workflow-step__footer">
                <PrimaryButton text={buttonLabel} onClick={this.handleClick.bind(this)} />
              </div>
            </div>
          )}
        </CSSTransitionGroup>
        <div className="dashboard-link" onClick={this.goToDashboard}>
            <span>GO TO DASHBOARD</span>
        </div>
      </div>
    );
  }
}

WsnScanConfirmation.defaultProps = {
  kitInfo: {},
  orderToPack: -1
};

WsnScanConfirmation.propTypes = {
  getOrders: PropTypes.func,
  kitInfo: PropTypes.object,
  orderToPack: PropTypes.number,
  processingStarted: PropTypes.bool
};

const mapStateToProps = ({ order, serviceKit, websocket, error }) => {
  const { orderToPack, selectedClient, serviceKitDisplayRows } = order;
  const { processingServiceKit, processingStarted } = serviceKit;
  return {
    kitInfo: processingServiceKit,
    orderToPack,
    processingStarted,
    selectedClient,
    serviceKitOrders: serviceKitDisplayRows,
    wsconnected: websocket.connected,
    showError: error.showError,
    errorMessage: error.errorMessage
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrders,
      selectClientForWorkflow,
      updateTotalCount
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(WsnScanConfirmation);