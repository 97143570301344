import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { scanWsn, decrementTotalCount } from '../../../../actions/orderActions';
import { setProcessingScanWsnListAction } from '../../../../actions/scanWsnAction';
import { splitWsnInfo, removeLeadingZeros } from '../../../../utilities/genericUtility';
import Col from '../../../Common/Column';
import PrimaryInput from '../../WorkflowStep/PrimaryInput/PrimaryInput';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import BarcodeExampleImage from './barcode-example.svg';
import CheckMarkImage from './checkmark.svg';
import './ScanWsn.scss';
import styled from 'styled-components';

const ScanWsnTitle = ({ sj, totalCount, currentCount }) => {
    return (
        <span className="wsn-title">
            <span>Pull </span>
            <span className="wsn-count">{`(${currentCount} of ${totalCount}) `}</span>
            <span>shipments of </span>
            <span>{sj}</span>
        </span>
    );
};

const displayListOfWsns = (wsnList, krn, refs) => {
    const wsns = wsnList.map(wsnObj => Object.assign(splitWsnInfo(wsnObj.wsn, krn), { status: wsnObj.status }));

    return (
        <div className="wsnListContainer">
            {wsns.map(
                wsnSplitObj =>
                    <div
                        className="wsnContainer"
                        key={`${wsnSplitObj.wsnSerialNum}${wsnSplitObj.wsnSerialAlpha}`}
                        ref={refs[`${wsnSplitObj.wsnMonth}${wsnSplitObj.wsnSerialNum}${wsnSplitObj.wsnSerialAlpha}${wsnSplitObj.wsnSjNum}`]}
                    >
                        {wsnSplitObj.status === 'KITTED' && <img src={CheckMarkImage} className="checkmark-icon" alt="" />}
                        <div className={`wsnLabel ${wsnSplitObj.status === 'KITTED' ? 'kittedStatus' : ''}`}>
                            <span className="wsnMonth">{wsnSplitObj.wsnMonth}</span>
                            <div className="wsnSerial">
                                <span>{removeLeadingZeros(wsnSplitObj.wsnSerialNum)}</span>
                                <span>{wsnSplitObj.wsnSerialAlpha}</span>
                            </div>
                        </div>
                    </div>
            )}
        </div>
    );
};

class ScanWsn extends Component {
    refs = null;

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
        // set processingScanWsnList
        this.props.setProcessingScanWsnListAction(this.props.kitInfo.serviceKitList ? this.props.kitInfo.serviceKitList : []);
    }

    getScannedWsnCount = (wsnList) => {
        let rtnVal = 1;

        for (var idx = 0; idx < wsnList.length; idx++) {
            if (wsnList[idx].status === 'KITTED') {
                rtnVal++;
            }
        }

        return rtnVal;
    }

    filterUnscannedBarcodes = (wsnList) => {
        let unscannedBarcodes = '';

        for (let idx = 0; idx < wsnList.length; idx++) {
            unscannedBarcodes = unscannedBarcodes.concat('', wsnList[idx].wsn);
            
            if (idx < wsnList.length - 1) {
                unscannedBarcodes = unscannedBarcodes.concat('', ',');
            }
        }

        return unscannedBarcodes;
    }

    goToNextStep = async () => {
        // do nothing
    };

    onSubmit = async (inputVal) => {
        await this.props.scanWsn(inputVal);

        const newWsnList = JSON.parse(JSON.stringify(this.props.scanWsnList));

        // decrement orderstopack count
        if (!this.props.wsconnected) {
            this.props.decrementTotalCount();
        }

        let kittedCount = 0;

        newWsnList.forEach(wsnObj => {
            if (wsnObj.wsn === inputVal) {
                wsnObj.status = 'KITTED';
            }

            if (wsnObj.status === 'KITTED') {
                kittedCount++;
            }
        });

        this.props.setProcessingScanWsnListAction(newWsnList);

        if (kittedCount === newWsnList.length) {
            this.props.nextStep(this.props.step);
        } else {
            // scroll to the next unscanned wsn
            let nextUnscannedWsn = '';

            // look for next unscanned wsn in list
            newWsnList.forEach(wsnObj => {
                if (wsnObj.status === 'OPEN' && nextUnscannedWsn === '') {
                    nextUnscannedWsn = wsnObj.wsn;
                }
            });

            // scroll into view the next unscanned wsn
            this.refs[nextUnscannedWsn].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    shipmentNotLocate = () => {
        this.props.unableToLocate();
    }
    render() {
        const { kitInfo, scanWsnList } = this.props;
        const wsnCount = scanWsnList.length;
        const wsnScanned = this.getScannedWsnCount(scanWsnList);
        const unscannedBarcodesList = this.filterUnscannedBarcodes(scanWsnList);

        // generate refs for WSN list
        this.refs = scanWsnList.reduce((acc, value) => {
            acc[value.wsn] = React.createRef();
            return acc;
        }, {});

        return (
            <WorkflowStep
                {...this.props}
                title={<ScanWsnTitle sj={kitInfo.kitReferenceNumber} totalCount={wsnCount} currentCount={wsnScanned} />}
                footer={
                    <PrimaryInput
                        nextStep={this.goToNextStep.bind(this)}
                        onSubmit={this.onSubmit.bind(this)}
                        placeholder="Scan or type in barcode"
                        rules={'required|in:' + unscannedBarcodesList}
                        title="WSN barcode"
                        type="barcode"
                    />
                }
                after={
                    <div className="locate-shipment-link" onClick={this.shipmentNotLocate.bind(this)}>
                        <span>UNABLE TO LOCATE SHIPMENT?</span>
                    </div>
                }
            >
                <Row gutter>
                    <Title><span>Technician Assigned: </span>{this.props?.orders?.find(a => a.serviceKitOrderStatusType === this.props?.bucketType)?.serviceKitDisplayRows?.find(a => a.kitReferenceNumber === kitInfo.kitReferenceNumber)?.techAssignedName}</Title>
                    <Col oneoftwo>
                        <ul>
                            <li>Find Package with matching labels.</li>
                            <li>Scan labels.</li>
                        </ul>
                    </Col>
                    <div className="barcode-example">
                        <p className="barcode-example-text">Example barcode</p>
                        <img src={BarcodeExampleImage} className="workflow-primary-btn__icon" alt="" />
                    </div>
                </Row>
                <Row gutter>
                    {displayListOfWsns(scanWsnList, kitInfo.kitReferenceNumber, this.refs)}
                </Row>
            </WorkflowStep>
        );
    }
}

ScanWsn.defaultProps = {
    // no default props
};

ScanWsn.propTypes = {
    scanWsn: PropTypes.func,
    decrementTotalCount: PropTypes.func,
    setProcessingScanWsnListAction: PropTypes.func
};

const mapStateToProps = ({ websocket, scanWsn }) => {
    return {
        wsconnected: websocket.connected,
        scanWsnList: scanWsn.processingScanWsnList
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            scanWsn,
            decrementTotalCount,
            setProcessingScanWsnListAction
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ScanWsn);


const Title = styled.div`
    margin-bottom:10px ;
    color: #8223D2;
    font-size: 26px;
    font-weight: 600;
    span{
        color: black;
    }
`;