import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../utilities/genericUtility';
import { MenuContentType } from '../../../utilities/enums';
import Button from '../../Common/Button/Button';
import DashSearch from '../DashSearch/DashSearch';
import Menu from '../MenuContent/Menu/Menu';
import hotNewsIcon from '../../../icons/hotnews-icon.svg';
import logo from './asurion_logo_black_rgb.svg';
import './DashHeader.scss';

const DashHeader = ({
  dataCount,
  goToFSLDashboard,
  goToDashboard,
  hotNewsCount,
  menu,
  menuDisplayed,
  onMenuClick,
  onSearch,
  openMenu,
  searchString,
  timer
}) => (
  <header className="dash-header" role="banner">
    <div className="dash-header__top">
      <div className="dash-header__left">
        <img className="dash-app-logo" src={logo} alt="Asurion Logo" onClick={goToFSLDashboard}/>
      </div>
      <div className="dash-header__right">
        {!menuDisplayed ? (
          <DashSearch onSearch={onSearch} />
        ) : (
          <div className="dash-header__blank-space" />
        )}
        <Button
          count={hotNewsCount}
          onClick={() => onMenuClick(MenuContentType.HotNews)}
          src={hotNewsIcon}
          text="Hot News"
          type="img-btn"
        />
        {timer}
        <Menu openMenu={openMenu} show={menu} showContent={onMenuClick} goBack={goToDashboard} />
      </div>
    </div>
    {!isEmpty(searchString) && (
      <div className="dash-header__bottom">
        <div className="dash-header__bottom-title">
          <div>Total orders found</div>
          <div className="dash-header__bottom-count"> {dataCount}</div>
        </div>
      </div>
    )}
  </header>
);

DashHeader.propTypes = {
  dataCount: PropTypes.number,
  onSearch: PropTypes.func,
  searchString: PropTypes.string
};

export default memo(DashHeader);
