import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { convertTimestamp, isEmpty, objectContainsValue } from '../../utilities/genericUtility';
import { searchOrder } from '../../actions/orderActions';
import DashHeader from './DashHeader/DashHeader';
import DashMain from './DashMain/DashMain';
import DashSide from './DashSide/DashSide';
import DashWidget from './DashWidget/DashWidget';
import DashWidgetButton from './DashWidgetButton/DashWidgetButton';
import MenuContent from './MenuContent/MenuContent';
import './Dashboard.scss';

const legends = {
  CANCELLED: 'CANCELLED',
  OPENORDERS: 'OPENORDERS',
  OUTFORDELIVERY: 'OUTFORDELIVERY',
  READYFORPICKUP: 'READYFORPICKUP',
  UNFINISHED: 'UNFINISHED',
  RESTAGING: 'RESTAGING',
  RESCHEDULE: 'RESCHEDULE'
};

const kitStatus = {
  EXCP: 'Exceptions',
  ORDR: 'OrdersToPack',
  OUTD: 'OutForDelivery',
  PACK: 'PackedAndReadyForPickup',
  UNFN: 'Unfinished',
  RSTG: 'Restaging',
  RSDL: 'Reschedule'
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentOrder: null,
      currentView: legends.OPENORDERS,
      dashboardClient: 'NONE',
      displayRowInfo: false,
      menuContent: '',
      resumeable: false,
      restaging: false,
      reschedule: false,
      openOrders: false,
      searchString: '',
      showMenu: false,
      showMenuContent: false,
      tableView: false,
      backToFSLDashboard: false
    };
    this.goToDashboard = this.goToDashboard.bind(this);
    this.goToFSLDashboard = this.goToFSLDashboard.bind(this);
    this.showCustomerInfo = this.showCustomerInfo.bind(this);
    this.displayMenuContent = this.displayMenuContent.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  showData(dataType) {
    const {
      CANCELLED,
      OPENORDERS,
      OUTFORDELIVERY,
      READYFORPICKUP,
      UNFINISHED,
      RESTAGING,
      RESCHEDULE
    } = legends;
    switch (dataType) {
      case CANCELLED:
      case OUTFORDELIVERY:
      case READYFORPICKUP:
        this.setState({
          currentView: dataType,
          displayRowInfo: false,
          resumeable: false,
          restaging: false,
          reschedule: false,
          tableView: true,
          openOrders: false
        });
        break;
      case UNFINISHED:
        this.setState({
          currentView: dataType,
          displayRowInfo: false,
          resumeable: true,
          restaging: false,
          reschedule: false,
          tableView: true,
          openOrders: false
        });
        break;
      case RESTAGING:
        this.setState({
          currentView: dataType,
          displayRowInfo: false,
          resumeable: false,
          restaging: true,
          reschedule: false,
          tableView: true,
          openOrders: false
        });
        break;
      case RESCHEDULE:
        this.setState({
          currentView: dataType,
          displayRowInfo: false,
          resumeable: false,
          restaging: false,
          reschedule: true,
          tableView: true,
          openOrders: false
        });
        break;
      case OPENORDERS:
      default:
        this.setState({
          currentView: dataType,
          displayRowInfo: false,
          resumeable: false,
          restaging: false,
          reschedule: false,
          tableView: true,
          openOrders: true
        });
    }
  }

  getTableContent() {
    const {
      CANCELLED,
      OUTFORDELIVERY,
      READYFORPICKUP,
      UNFINISHED,
      RESTAGING,
      RESCHEDULE,
      OPENORDERS
    } = legends;
    const { EXCP, ORDR, OUTD, PACK, UNFN, RSTG, RSDL } = kitStatus;
    let orders = this.props.orders.filter(order => {
      switch (this.state.currentView) {
        case CANCELLED:
          return order.serviceKitOrderStatusType === EXCP;
        case OUTFORDELIVERY:
          return order.serviceKitOrderStatusType === OUTD;
        case READYFORPICKUP:
          return order.serviceKitOrderStatusType === PACK;
        case UNFINISHED:
          return order.serviceKitOrderStatusType === UNFN;
        case RESTAGING:
          return order.serviceKitOrderStatusType === RSTG;
        case RESCHEDULE:
          return order.serviceKitOrderStatusType === RSDL;
        case OPENORDERS:
          return order.serviceKitOrderStatusType === ORDR;
        default:
          return null;
      }
    });
    orders = !isEmpty(orders) ? orders[0].serviceKitDisplayRows : [];
    orders = !isEmpty(orders) ? orders.filter(order => this.filterData(order)) : [];
    return orders;
  }

  getOrderByStatus(status) {
    const ordersByType =
      !isEmpty(this.props.orders) &&
      this.props.orders.filter(order => order.serviceKitOrderStatusType === status);
    const orders = !isEmpty(ordersByType) ? ordersByType[0].serviceKitDisplayRows : [];

    const filteredOnes = !isEmpty(orders) ? orders.filter(order => this.filterData(order)) : [];
    return filteredOnes;
  }

  filterData = order =>
    (this.state.dashboardClient === 'NONE' ||
      this.state.dashboardClient === order.externalClientId) &&
    (this.state.searchString === '' ||
      (this.state.searchString !== '' && objectContainsValue(order, this.state.searchString)));

  showCustomerInfo = async data => {
    if (data) {
      const res = await this.props.searchOrder(data.serviceKitNumber);
      if (res !== null) {
        data.createdDateTime = convertTimestamp(res.createdDateTime);
        res.serviceKitItems.forEach(item => {
          if (item.envelopeTrackingNumber)
            data.envelopeTrackingNumber = item.envelopeTrackingNumber;
          if (item.partSerialNumber && item.itemType === 'PHONE') {
            data.partSerialNumber = item.partSerialNumber;
            if (item.itemStatus === 'KITTED')
              data.completedDateTime = convertTimestamp(item.createdDateTime);
          }
        });
      }
    }
    this.setState({
      currentOrder: data,
      displayRowInfo: !this.state.displayRowInfo
    });
  };

  clientFilterSelect = clientId => {
    this.setState({
      dashboardClient: clientId
    });
  };

  onSearchType = value => {
    this.setState({
      searchString: value.trim()
    });
  };

  filterClientCounts = orders => {
    if (isEmpty(this.state.searchString)) return orders.serviceKitOrderClientCounts;
    else {
      const filteredData = orders.serviceKitDisplayRows.filter(order => this.filterData(order));
      const filteredClientCounts = [];
      for (let i = 0; i < orders.serviceKitOrderClientCounts.length; i++) {
        const ordr = orders.serviceKitOrderClientCounts[i];
        const clientFiltered = filteredData.filter(
          order => order.externalClientId === ordr.externalClientId
        );
        filteredClientCounts.push({
          externalClientId: ordr.externalClientId,
          count: clientFiltered.length
        });
      }
      return filteredClientCounts;
    }
  };

  toggleMenu(flag) {
    this.setState({
      showMenu: flag
    });
  }

  displayMenuContent(contentType) {
    this.setState({
      showMenu: false,
      showMenuContent: true,
      menuContent: contentType
    });
  }

  goToFSLDashboard() {
    this.setState({
      backToFSLDashboard: true
    });
  }

  goToDashboard() {
    this.setState({
      showMenu: false,
      showMenuContent: false,
      menuContent: ''
    });
  }
  componentDidMount() {
    this.setState({
      tableView: true,
      openOrders: true
    });
  }
  componentDidUpdate() {
    if (this.state.backToFSLDashboard) {
      this.props.backToFSLDashboard();
    }
  }

  render() {
    const {
      CANCELLED,
      OPENORDERS,
      OUTFORDELIVERY,
      READYFORPICKUP,
      UNFINISHED,
      RESTAGING,
      RESCHEDULE
    } = legends;
    const { EXCP, ORDR, OUTD, PACK, UNFN, RSTG, RSDL } = kitStatus;
    const {
      isHelpDrawerActive,
      orders,
      resumeWorkflow,
      startReturnProcess,
      openRestagingWorkflow,
      openRescheduleWorkflow
    } = this.props;
    const {
      currentOrder,
      currentView,
      displayRowInfo,
      resumeable,
      restaging,
      reschedule,
      tableView,
      openOrders
    } = this.state;

    const sortedOrders = !isEmpty(orders)
      ? orders.filter(order => order.serviceKitOrderStatusType === ORDR)[0]
      : [];
    const filterCount = sortedOrders?.serviceKitOrderClientCounts?.map(val => val.count);
    const sortedCount = filterCount?.reduce((partialSum, a) => partialSum + a, 0);
    const exceptionOrders = this.getOrderByStatus(EXCP);
    const pickupOrders = this.getOrderByStatus(OUTD);
    const readyToPickupOrders = this.getOrderByStatus(PACK);
    const unfinishedOrders = this.getOrderByStatus(UNFN);
    const restagingOrders = this.getOrderByStatus(RSTG);
    const rescheduleOrders = this.getOrderByStatus(RSDL);
    const filteredDataCount =
      sortedCount +
      exceptionOrders.length +
      pickupOrders.length +
      readyToPickupOrders.length +
      unfinishedOrders.length +
      restagingOrders.length +
      rescheduleOrders.length;
    const tableContent = this.getTableContent();
    const menuContent = <MenuContent contentType={this.state.menuContent} />;
    const dashboardContent = (
      <>
        <DashSide {...this.props}>
          <DashWidget
            title="Orders to kit"
            count={sortedCount}
            onClick={() => this.showData(OPENORDERS)}
            selected={currentView === OPENORDERS}
          />
          <DashWidget
            title="Unfinished"
            count={unfinishedOrders.length}
            onClick={() => this.showData(UNFINISHED)}
            selected={currentView === UNFINISHED}
          />
          <DashWidget
            title="Ready for pickup"
            count={readyToPickupOrders.length}
            onClick={() => this.showData(READYFORPICKUP)}
            selected={currentView === READYFORPICKUP}
          />
          <DashWidget
            title="Out for service appointment"
            count={pickupOrders.length}
            onClick={() => this.showData(OUTFORDELIVERY)}
            selected={currentView === OUTFORDELIVERY}
          />
          <DashWidget
            title="Exceptions"
            count={exceptionOrders.length}
            onClick={() => this.showData(CANCELLED)}
            selected={currentView === CANCELLED}
          />
          <DashWidget
            title="Restaging"
            count={restagingOrders.length}
            onClick={() => this.showData(RESTAGING)}
            selected={currentView === RESTAGING}
          />
          <DashWidget
            title="Reschedule"
            count={rescheduleOrders.length}
            onClick={() => this.showData(RESCHEDULE)}
            selected={currentView === RESCHEDULE}
          />
          {/* {isEmpty(this.state.searchString) && (
            <DashWidgetButton title="SCAN RETURN" onClick={startReturnProcess} />
          )} */}
        </DashSide>
        <DashMain
          clientFilterSelect={id => this.clientFilterSelect(id)}
          currentOrder={currentOrder}
          displayRowInfo={displayRowInfo}
          orderToKit={!isEmpty(sortedOrders) ? this.filterClientCounts(sortedOrders) : []}
          resumeable={resumeable}
          restaging={restaging}
          reschedule={reschedule}
          openOrders={openOrders}
          showRescheduleWorkflow={openRescheduleWorkflow}
          rowSelected={resumeWorkflow}
          showRestagingWorkflow={openRestagingWorkflow}
          searchString={this.state.searchString}
          showAlert={currentView !== UNFINISHED}
          showCustomerInfo={data => this.showCustomerInfo(data)}
          showExceptions={currentView === CANCELLED}
          showTable={tableView}
          showUnfinished={() => this.showData(UNFINISHED)}
          tableContent={tableContent}
          unfinishedOnes={unfinishedOrders.length}
          {...this.props}
        />
      </>
    );
    return (
      <div className={classNames('dashboard', { 'dashboard--squeezed': isHelpDrawerActive })}>
        <DashHeader
          dataCount={filteredDataCount}
          goToFSLDashboard={this.goToFSLDashboard}
          goToDashboard={this.goToDashboard}
          hotNewsCount={this.props.hotNewsCount}
          menu={this.state.showMenu}
          menuDisplayed={this.state.showMenuContent}
          onMenuClick={this.displayMenuContent}
          onSearch={this.onSearchType.bind(this)}
          openMenu={this.toggleMenu}
          searchString={this.state.searchString}
          timer={this.props.timer}
        />
        {this.state.showMenuContent ? menuContent : dashboardContent}
      </div>
    );
  }
}

Dashboard.defaultProps = {
  orders: [],
  orderToPack: 0
};

Dashboard.propTypes = {
  orders: PropTypes.array,
  orderToPack: PropTypes.number
};

const mapStateToProps = ({ order, configuration }) => ({
  hotNewsCount: configuration.hotNewsCount,
  latestHotnews: configuration.updatedHotNews,
  orders: order.serviceKitDisplayRows,
  orderToPack: order.orderToPack
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchOrder
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
