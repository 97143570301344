import React, { memo } from 'react';
import './SmallModal.scss';
import ErrorIcon from './error-icon.svg';
import WarningIcon from './warning-icon.svg';

const SmallModal = ({ buttons, closeModal, icon, isLoader, message, title, customStyles }) => {
  let iconDetails = null;
  let styleOverrides = {
    smallModalOverride: customStyles && customStyles.smallModalOverride ? customStyles.smallModalOverride : '',
    btnOverride: customStyles && customStyles.btnOverride ? customStyles.btnOverride : ''
  };
  switch (icon) {
    case 'error':
      iconDetails = ErrorIcon;
      break;
    case 'warning':
      iconDetails = WarningIcon;
      break;
    default:
      iconDetails = null;
      break;
  }
  return (
    <div className="small-modal">
      <div className={`small-modal-in ${styleOverrides.smallModalOverride}`}>
        {!isLoader ? (
          <>
            {typeof closeModal === 'function' && <></>}
            <div className="modal-main">
              {iconDetails !== null && (
                <img src={iconDetails} alt="" className="workflow-step__messaging__icon" />
              )}
              <h1 className="modal-title">{title}</h1>
              <div className="modal-message">{message}</div>
              <div className={`modal-buttons ${styleOverrides.btnOverride}`}>{buttons}</div>
            </div>
          </>
        ) : (
          <div className="modal-main">
            <h1 className="modal-title">{title}</h1>
            <div className="modal-loader">
              <div />
              <div />
              <div />
              <div />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

SmallModal.defaultProps = {
  isLoader: false
};

export default memo(SmallModal);
