import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CSSTransitionGroup } from 'react-transition-group';
import PropTypes from 'prop-types';
import {
  getOrders,
  selectClientForWorkflow,
  updateTotalCount,
  rescheduleComplete
} from '../../../../actions/orderActions';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import SuccessIcon from '../../../../icons/done-checkmark.svg';
import './RescheduleComplete.scss';

const DoneInfo = ({ jobNumber }) => (
  <div className="wsn-scan-done-info">
    <img src={SuccessIcon} alt="" />
    <h4 className="wsn-scan-done-info-h1">
    </h4>
  </div>
);

class RescheduleComplete extends Component {
  state = {};

   handleClick = async () => {
   // await this.props.rescheduleComplete(this.props.kitInfo.jobNumber);
    await this.props.closeWorkflow();
  };

  goToDashboard = () => {
    this.props.closeWorkflow();
  }

  render() {
    const {remainOrder} = this.state;
    const infoContent = (
          <div className="info-content-wsn">
            <p className="info-place-shipment-text">
            Place the box on the active inventory rack.
            </p>
          </div>
        );
    const buttonLabel =
      (this.props.bucketType === 'Unfinished' && remainOrder && remainOrder.serviceKitDisplayRows.length > 1) || (this.props.bucketType === 'OrdersToPack' && remainOrder && remainOrder.serviceKitDisplayRows.length > 0)
        ? 'Next Order'
        : 'Complete';
    return (
      <div className="workflow-main">
        {this.props.showError ? <div className="reschedule-complete-error">{this.props.errorMessage}</div> : <></>}
        <CSSTransitionGroup
          transitionName="workflow-step"
          transitionAppear={true}
          transitionAppearTimeout={5000}
          transitionEnterTimeout={5000}
          transitionLeaveTimeout={3000}
        >
          {this.state.currentCarrierCount !== -1 && (
            <div className="workflow-step">
              <div className="workflow-step__main">
                <div className="workflow-step__messaging reschedule-complete">
                  <DoneInfo jobNumber={this.props.kitInfo.jobNumber} />
                  {infoContent}
                </div>
              </div>
              <div className="workflow-step__footer">
                <PrimaryButton text={buttonLabel} onClick={this.handleClick.bind(this)} />
              </div>
            </div>
          )}
        </CSSTransitionGroup>
        <div className="dashboard-link" onClick={this.goToDashboard}>
            <span>GO TO DASHBOARD</span>
        </div>
      </div>
    );
  }
}

RescheduleComplete.defaultProps = {
  kitInfo: {},
  orderToPack: -1
};

RescheduleComplete.propTypes = {
  kitInfo: PropTypes.object,
};

const mapStateToProps = ({ serviceKit, error }) => {
  const { processingServiceKit } = serviceKit;
  return {
    kitInfo: processingServiceKit,
    showError: error.showError,
    errorMessage: error.errorMessage
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrders,
      selectClientForWorkflow,
      updateTotalCount,
      rescheduleComplete
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleComplete);