import { isEmpty } from '../utilities/genericUtility';
import { HIDE_LOADING, SHOW_LOADING } from '../actions/appInteractionActions';

const initialState = {
  hasText: 'Loading...',
  showLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HIDE_LOADING:
      return { ...state, showLoading: false, hasText: initialState.hasText };
    case SHOW_LOADING:
      return {
        ...state,
        showLoading: true,
        hasText: !isEmpty(action.message) ? action.message : 'Loading...'
      };
    default:
      return state;
  }
};
