import {
    INCREMENT_SCANNED_COUNT,
    RESET_SCANNED_COUNT
} from '../actions/scannedPartsAction';

const initialState = {
    scannedPartsCount: 0
}

export default (state = initialState, action) => {
    switch (action.type) {
        case INCREMENT_SCANNED_COUNT:
            return {
                ...state,
                scannedPartsCount: ++state.scannedPartsCount
            }
        case RESET_SCANNED_COUNT:
            return {
                ...state,
                scannedPartsCount: 0
            }
        default:
            return state;
    }
}
