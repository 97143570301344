import React from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { resetReturnABCSuccessful, setRmaScanPartStatus } from '../../../../actions/orderActions';
import WorkflowStep from '../../WorkflowStep/WorkflowStep'
import styled from 'styled-components';
import { BodyText, List, BottomBox, AfterCardText } from '../commonStyledComponent';
import Image from './Illustration.png'


export const PlaceboxAndReturn = ({
    nextStep,
    step,
    goToStep,
    resetReturnABCSuccessful,
    setRmaScanPartStatus
}) => {
    const goToNextStep = () => {
        nextStep(step)
    }
    const goToPreviousStep = () => {
        resetReturnABCSuccessful()
        setRmaScanPartStatus('')
        goToStep(1)
    }
    return <WorkflowStep
        title="Prepare box for Shipper & complete return"
        step={step}
        footer={
            <BottomBox onClick={()=> goToPreviousStep()}>
                <span>
                    SCAN ANOTHER PART
                </span>
            </BottomBox>
        }
        after={
            <AfterCardText onClick={() => goToNextStep()}>
                DONE SCANNING
            </AfterCardText>
        }
    >
        <div>
            <List>
                <BodyText>Seal the box closed</BodyText>
                <BodyText>Place part in UPS return area</BodyText>
            </List>
        </div>
        <RightSideImage src={Image} alt="frame" />
    </WorkflowStep>
}

PlaceboxAndReturn.propTypes = {
    resetReturnABCSuccessful: PropTypes.func,
    setRmaScanPartStatus: PropTypes.func
};

PlaceboxAndReturn.defaultProps = {
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        resetReturnABCSuccessful,
        setRmaScanPartStatus
    }, dispatch);

export const ConnectedPlaceboxAndReturn = connect(null, mapDispatchToProps)(PlaceboxAndReturn);

const RightSideImage = styled.img`
    margin-left: 100px;
    width: 430px;
    height: 284px;
`;