import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { restagingCloseBox, restagingUnableToLocatePart } from '../../../../actions/orderActions';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import Row from '../../../Common/Row';
import Col from '../../../Common/Column';
import closeBox from './CloseBox.png';
import missingPartCloseBox from './MissingPartCloseBox.png';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import './CloseBox.scss';

const ScanABCTitle = () => {
  return (
    <span className="abc-title">
      <span> Close box and place on Active Job Rack </span>
    </span>
  );
};

class CloseBox extends Component {

  componentDidMount() {
    this.props.closeModal();
  }

  goToNextStep = async () => {
    await this.props.restagingCloseBox();
    if (this.props.restagingCloseBoxComplete) {
      this.props.nextStep(this.props.step);
    }
  }

  missingPartGoToNextStep = async () => {
    // call API for missing part
    await this.props.restagingUnableToLocatePart();
    // next step
    this.props.nextStep(this.props.step);
  }

  closeBoxRender = () => (
    <WorkflowStep
      {...this.props}
      title={<ScanABCTitle />}
      footer={<PrimaryButton text="COMPLETE" onClick={this.goToNextStep.bind(this)} />}
    >
      <Row gutter>
        <Col twoofthree>
          <ul>
            <li>Close the box.</li>
            <li>Place the shipment on the Active Job Rack.</li>
          </ul>
        </Col>
        <Col oneoftwo>
          <img src={closeBox} className="step-2-graphic-return-abc" alt="" />
        </Col>
      </Row>
    </WorkflowStep>
  );

  closeBoxMissingPartRender = () => (
    <WorkflowStep
      {...this.props}
      title="Place shipment on Restaging Shelf"
      footer={<PrimaryButton text="COMPLETE" onClick={this.missingPartGoToNextStep.bind(this)} />}
    >
      <Row gutter>
        <Col twoofthree>
          <ul>
            <li>Put the unfinished shipment box on the Restaging shelf to be finished later.</li>
            <li>Move on to the next shipment.</li>
          </ul>
        </Col>
        <Col oneoftwo>
          <img src={missingPartCloseBox} className="step-2-graphic-return-abc" alt="" />
        </Col>
      </Row>
    </WorkflowStep>
  );

  render() {
    return (
      this.props.missingPartsWorkflow ?
      this.closeBoxMissingPartRender() :
      this.closeBoxRender()
    );
  }
}

CloseBox.defaultProps = {
  restagingCloseBoxComplete: false
};

CloseBox.propTypes = {
  restagingCloseBox: PropTypes.func,
  restagingUnableToLocatePart: PropTypes.func,
  restagingCloseBoxComplete: PropTypes.bool
};

const mapStateToProps = ({ serviceKit }) => {
  return {
    restagingCloseBoxComplete: serviceKit.restagingCloseBoxComplete
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      restagingCloseBox,
      restagingUnableToLocatePart
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CloseBox);
