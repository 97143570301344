import { updateOrderData } from './actions/orderActions';
import {
  WS_CONNECT,
  WS_DISCONNECT,
  WS_NEW_MESSAGE,
  wsConnect,
  wsConnected,
  wsDisconnected,
  wsErrorScenario
} from './actions/wsConnectActions';

const socketMiddleware = () => {
  let socket = null;
  let sessionExpired = false;
  let errorClosure = 0;

  const onOpen = store => event => {
    errorClosure = 0;
    store.dispatch(wsConnected(event.target.url));
  };

  const onClose = store => async event => {
    await store.dispatch(wsDisconnected());
    if (event.code !== 1000 && event.code !== 1001) errorClosure++;
    if (!sessionExpired && errorClosure < 3) store.dispatch(wsConnect());
    else if (errorClosure >= 3) store.dispatch(wsErrorScenario());
    if (event.code !== 1000)
      console.warn(`WS Disconnected with code : [${event.type}]${event.code} - ${event.reason}`);
  };

  const onMessage = store => event => {
    const payload = JSON.parse(event.data);
    store.dispatch(updateOrderData(payload));
  };

  return store => next => action => {
    switch (action.type) {
      case WS_CONNECT:
        if (socket !== null) socket.close();
        socket = new WebSocket(action.host);
        socket.onclose = onClose(store);
        socket.onmessage = onMessage(store);
        socket.onopen = onOpen(store);
        break;
      case WS_DISCONNECT:
        if (socket !== null) socket.close();
        sessionExpired = action.sessionExpired;
        socket = null;
        break;
      case WS_NEW_MESSAGE:
        if (socket !== null)
          socket.send(JSON.stringify({ action: 'onmessage', message: action.msg }));
        break;
      default:
        return next(action);
    }
  };
};

export default socketMiddleware();
