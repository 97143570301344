import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import Button from '../../../Common/Button/Button';
import './ReceivingParts.scss';

const ReceiveByTrackingNumOrOrderNum = ({ goBack, shipmentList, searchByOption }) => {
    return (
        <div className="tracking-info">
            <div style={{ marginTop: '1rem' }}>
                <label className="tracking-info_header"> { searchByOption === "Order/Reference Number" ? "Order Information": "Shipment Information"} </label>
            </div>
            <div className="tracking-info_detail">
                {Object.entries(shipmentList).map(([k, i]) => {
                    let trackingNumberSplit = [];
                    let carrierSplit = [];

                    if (k === "Tracking/shipment #") {
                        trackingNumberSplit = shipmentList[k].split(',');
                    }

                    if (k === "Carrier") {
                        carrierSplit = shipmentList[k].split(',');
                    }

                    return (
                        <div key={`${i}-tracking-info`} className="tracking-info_element">
                            <div>
                                <span className="tracking-info_key">{k}</span>
                            </div>
                            <div style={{ textAlign: 'end' }}>
                                {
                                    k === "Tracking/shipment #" ?
                                    (
                                        trackingNumberSplit.map((num) => {
                                            return (
                                                <span key={`tracking-${num}`} className="tracking-info_value tracking-num">{num}</span>
                                            )
                                        })
                                    ) :
                                    k === "Carrier" ?
                                    (
                                        carrierSplit.map((carrier) => {
                                            return (
                                                <span key={`tracking-${carrier}`} className="tracking-info_value tracking-carrier">{carrier}</span>
                                            )
                                        })
                                    ) :
                                    (
                                        <span className="tracking-info_value">{shipmentList[k]}</span>
                                    )
                                }
                            </div>
                        </div>
                    )
                })}
            </div>
            <div style={{ paddingLeft: '1.5rem', marginBottom: '1rem' }}>
                <Button
                    text="Exit Shipment"
                    className="tracking-info_button tracking-info-active"
                    onClick={goBack}
                ></Button>
            </div>
        </div>
    );
};

ReceiveByTrackingNumOrOrderNum.propTypes = {
  goBack: PropTypes.func.isRequired,
  shipmentList: PropTypes.object.isRequired,
  searchByOption: PropTypes.string.isRequired
};

export default memo(ReceiveByTrackingNumOrOrderNum);
