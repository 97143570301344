import styled from "styled-components";
import { removeLeadingZeros, splitWsnInfo } from "../../../../utilities/genericUtility";
import BarcodeIcon from '../../../Workflow/WorkflowStep/PrimaryInput/icon-barcode.svg';


export const displayShipment = (skn) => {
    const splitWsn = splitWsnInfo(skn);
    return splitWsn.wsnMonth + removeLeadingZeros(splitWsn.wsnSerialNum) + splitWsn.wsnSerialAlpha;
}



export const Input = styled.input`
:focus {
  box-shadow: 0 0 2rem 0 #8223d2;
  border: solid 1px transparent;
  outline: none;
}
    border-radius: 4px; 
    border: 1px solid;
    width: 400px;
    height: 60px;
    margin: 10px;
    text-align: center;
    background: url(${BarcodeIcon}) no-repeat scroll 7px 7px;
    padding-left:30px;
`;

export const Checkbox = styled.input`
    border-radius: 4px; 
    border: 1px solid;
    width: 30px;
    height: 30px;
    margin: 10px auto;
    text-align: center;
`;


export const Select = styled.select`
    border-radius: 4px; 
    border: 1px solid;
    width: 250px;
    height: 30px;
    text-align: center;
`;

export const CrossIcon = styled.div`
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  :hover {
  opacity: 1;
}
:before, :after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
:before {
  transform: rotate(45deg);
}
:after {
  transform: rotate(-45deg);
}

`;


export const WSNCard = styled.div`
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.333333px;
    padding: 6px 0px;
    margin: 0 12px;
    color: #ffffff;
    background: #000000;
    border-radius: 2px;
    display: inline-block;
    height: 32px !important;
    padding: 6px;
`;