import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from '../../../../utilities/genericUtility';
import { resetReturnABCSuccessful, setRmaScanPartStatus } from '../../../../actions/orderActions';
import Col from '../../../Common/Column';
import PrimaryButton from '../../WorkflowStep/PrimaryButton/PrimaryButton';
import Row from '../../../Common/Row';
import WorkflowStep from '../../WorkflowStep/WorkflowStep';
import returnToSporExample from './ReturnToSPOR.png';
import restageExample from './Restage.png';
import './ReturnOrRestockABC.scss';

const partStatusMessages = {
  RETURN: {
    title: 'Return to SPOR',
    titleCss: 'returnSpor',
    buttonValue: 'SCAN ANOTHER PART',
    notice: [
      'Part no longer required.',
      <span>
        Place in <b>Returns to Asurion Area.</b>
      </span>
    ],
    image: returnToSporExample
  },
  RESTAGE: {
    title: 'Place on Restaging Rack',
    titleCss: 'restaging',
    buttonValue: 'SCAN ANOTHER PART',
    notice: [
      'Parts are on an active job.',
      <span>
        Place on <b>Restaging Rack</b> to be restaged.
      </span>
    ],
    image: restageExample
  }
};

class ReturnOrRestockABC extends PureComponent {
  goToNextStep() {
    this.props.nextStep(this.props.step);
  }

  goToPrevStep() {
    // reset setRmaScanPartStatus
    this.props.setRmaScanPartStatus('');

    this.props.prevStep();
  }

  render() {
    const { returnABCStatus } = this.props;
    let displayMessages = {
      title: 'Unable to retrieve title',
      titleCss: '',
      buttonValue: 'SCAN ANOTHER PART',
      notice: []
    };
    if (!isEmpty(returnABCStatus) && !isEmpty(partStatusMessages[returnABCStatus]))
      displayMessages = partStatusMessages[returnABCStatus];
    return (
      <WorkflowStep
        {...this.props}
        title={
          <span className="returnsTitle">
            <span className={displayMessages.titleCss}>{displayMessages.title}</span> &amp; complete
            return
          </span>
        }
        footer={
          <PrimaryButton
            text={displayMessages.buttonValue}
            onClick={this.goToPrevStep.bind(this)}
          />
        }
        after={
          <div className="done-scanning-link" onClick={this.goToNextStep.bind(this)}>
            <span>DONE SCANNING</span>
          </div>
        }
      >
        <Row gutter>
          <Col twoofthree>
            <ul>
              {displayMessages.notice.map((note, i) => (
                <li key={i}>{note}</li>
              ))}
            </ul>
          </Col>
          <Col oneoftwo>
            <img src={displayMessages.image} className="step-2-graphic-return-abc" alt="" />
          </Col>
        </Row>
      </WorkflowStep>
    );
  }
}

ReturnOrRestockABC.propTypes = {
  returnABCStatus: PropTypes.string,
  resetReturnABCSuccessful: PropTypes.func,
  setRmaScanPartStatus: PropTypes.func
};

ReturnOrRestockABC.defaultProps = {
  returnABCStatus: ''
};

const mapStateToProps = ({ serviceKit }) => {
  const { returnABCStatus } = serviceKit;
  return {
    returnABCStatus
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetReturnABCSuccessful,
      setRmaScanPartStatus
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReturnOrRestockABC);
